import { createSlice } from "@reduxjs/toolkit";
import { SettingsState } from "./interfaces";
import {
  getNotificationSettingsThunkAction,
  saveNotificationSettingsThunkAction,
} from "./thunkActions";

const initialState: SettingsState = {
  notifications: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNotificationSettingsThunkAction.fulfilled, (state, action) => {
        if (action.payload) {
          state.notifications = [];
          state.notifications = [...state.notifications, ...(action.payload.data || [])];
        }
      })
      .addCase(saveNotificationSettingsThunkAction.fulfilled, (state, action) => {});
  },
});

const settingsReducer = settingsSlice.reducer;

export { settingsReducer };
