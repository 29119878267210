import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const saveProfile = (data: FormData) =>
  KY.post(EndPoints.PROFILE_FIELDS, {
    body: data,
    headers: {
      "Frdo-Sert-Mode": "false",
      "Content-Type": undefined,
    },
  });
