import { SVGProps } from "react";

export const LeafWithoutShadow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={42}
    viewBox="0 0 18 42"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.067 6.929S17.794 6.663 17.41.11c0 0-5.824 1.062-5.343 6.819Z"
    />
    <path
      fill="currentColor"
      d="M12.067 6.929 17.409.11c-.37.042-2.966.433-4.45 2.823-1.07 1.718-.947 3.45-.892 3.996Z"
      opacity={0.8}
    />
    <path fill="currentColor" d="M10.157 10.8s3.887-4.288-.934-8.676c0 0-3.406 4.932.934 8.677Z" />
    <path
      fill="currentColor"
      d="m10.158 10.8-.934-8.676c-.234.308-1.813 2.431-1.21 5.198a6.175 6.175 0 0 0 2.144 3.479Z"
      opacity={0.8}
    />
    <path fill="currentColor" d="M9.553 10.438S12.52 5.436 18 8.928c0 0-3.695 4.71-8.447 1.51Z" />
    <path
      fill="currentColor"
      d="M9.553 10.438 18 8.928c-.22.308-1.786 2.446-4.574 2.627-1.978.14-3.42-.796-3.873-1.117Z"
      opacity={0.8}
    />
    <path
      fill="currentColor"
      d="M7.976 17.353s2.376-5.31-3.558-7.936c.014 0-1.717 5.756 3.558 7.936Z"
    />
    <path
      fill="currentColor"
      d="M7.975 17.353 4.432 9.417c-.124.363-.975 2.892.453 5.323 1.016 1.733 2.582 2.417 3.09 2.613Z"
      opacity={0.8}
    />
    <path fill="currentColor" d="M7.302 17.2s1.278-5.688 7.555-4.123c0 0-2.06 5.645-7.555 4.122Z" />
    <path
      fill="currentColor"
      d="m7.302 17.2 7.555-4.123c-.11.364-.948 2.893-3.53 3.955-1.84.754-3.503.32-4.025.167Z"
      opacity={0.8}
    />
    <path
      fill="currentColor"
      d="M7.89 25.317s-.646-5.799-7.06-4.96c0 .014 1.428 5.854 7.06 4.96Z"
    />
    <path
      fill="currentColor"
      d="M7.89 25.317.83 20.371c.068.377.632 2.99 3.076 4.331 1.745.95 3.434.713 3.983.615Z"
      opacity={0.8}
    />
    <path
      fill="currentColor"
      d="M7.368 25.261s-1.772-5.547 4.408-7.489c-.013 0 1.072 5.924-4.408 7.489Z"
    />
    <path
      fill="currentColor"
      d="m7.368 25.261 4.408-7.489c.083.377.646 2.976-1.043 5.24-1.195 1.62-2.844 2.11-3.365 2.249Z"
      opacity={0.8}
    />
    <path
      fill="currentColor"
      d="M10.83 33.322s-1.785-5.547-7.897-3.41c.014 0 2.568 5.436 7.898 3.41Z"
    />
    <path
      fill="currentColor"
      d="m10.83 33.322-7.897-3.41c.151.35 1.209 2.795 3.873 3.62 1.91.573 3.516 0 4.025-.21Z"
      opacity={0.8}
    />
    <path
      fill="currentColor"
      d="M10.309 33.364s-2.844-5.072 2.829-8.23c0 0 2.225 5.59-2.83 8.23Z"
    />
    <path
      fill="currentColor"
      d="m10.309 33.364 2.829-8.23c.165.35 1.222 2.78.014 5.352-.838 1.816-2.35 2.64-2.843 2.878Z"
      opacity={0.8}
    />
    <path fill="currentColor" d="M15.72 39.164s-3.68-4.485-8.57-.195c0 0 4.353 4.08 8.57.195Z" />
    <path
      fill="currentColor"
      d="m15.72 39.164-8.57-.196c.26.28 2.128 2.138 4.917 1.9 1.977-.18 3.255-1.327 3.653-1.704Z"
      opacity={0.8}
    />
    <path fill="currentColor" d="M15.253 39.402s-4.478-3.647-.37-8.719c0 0 4.106 4.345.37 8.719Z" />
    <path
      fill="currentColor"
      d="m15.253 39.402-.37-8.719c.274.266 2.156 2.124 1.963 4.96-.123 2.012-1.222 3.353-1.593 3.759Z"
      opacity={0.8}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.274}
      d="M16.771 40.435C10.984 36.208 7.216 29.31 7.216 21.52a23.407 23.407 0 0 1 5.217-14.787"
    />
  </svg>
);
