import { Capacitor, CapacitorCookies } from "@capacitor/core";

export const getCookieValue = async (name: string): Promise<string | undefined> => {
  const match =
    Capacitor.getPlatform() === "ios"
      ? await CapacitorCookies.getCookies().then((cookies) => {
          return cookies?.[name];
        })
      : document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));

  return Capacitor.getPlatform() === "ios"
    ? (match as string) || undefined
    : match
    ? decodeURIComponent(match[2])
    : undefined;
};
