import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const getQuizQuestionsAnswersByAttemptId = ({
  quizeId,
  attemptId,
}: {
  quizeId: number | string;
  attemptId: number | string;
}) => KY.get(EndPoints.QUIZE_ANSWERS(quizeId, attemptId)).json();
