import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../hooks/redux";
import { useVideoView } from "../../../../../hooks/useVideoView/useVideoView";
import {
  ViewedVideoInfo,
  getMeetingViewedInfo,
  postMeetingViewed,
  postMeetingViewedInfo,
} from "../redux";

export const useVideoViewingTracking = (
  lessonId?: number,
  status?: string,
  isConference: boolean = false,
  disableTracking?: boolean
) => {
  const dispatch = useAppDispatch();

  const [isLoadData, setIsLoadData] = useState<boolean>(false);
  const [videoIsCompleted, setVideoIsCompleted] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timer>();
  const counterRef = useRef<number>(0);

  const videosInfoRef = useRef<{
    finished: Set<string>;
    state: ViewedVideoInfo[];
    completed: boolean;
  }>({
    finished: new Set(),
    state: [],
    completed: false,
  });

  /**
   * Проверяет все условия просмотра и фиксирует просмотр, если условия выполнены
   */
  const processViewConditions = useCallback(() => {
    const videosAreCompleted =
      videosInfoRef.current.state && videosInfoRef.current.state.length > 0
        ? _.isEmpty(
            _.difference(
              videosInfoRef.current.state.map((x) => x.name),
              Array.from(videosInfoRef.current.finished)
            )
          )
        : false;

    if (!disableTracking && videosAreCompleted && lessonId && !videosInfoRef.current.completed) {
      console.log("[SMART VIEW] Посмотрел все видео 60% времени, вебинар считается просмотренным");
      videoIsCompleted &&
        dispatch(postMeetingViewed({ meetingId: lessonId, isConference: !!isConference }))
          .unwrap()
          .then(() => {
            videosInfoRef.current.completed = true;
          });
      setVideoIsCompleted(true);
    }
  }, [dispatch, disableTracking, lessonId, videoIsCompleted, isConference]);

  /**
   * Хук для отслеживания времени видео
   */
  const { initializeVideos } = useVideoView(
    videosInfoRef,
    status !== "completed",
    processViewConditions
  );

  /**
   * Состояния прохождения с бэка
   */
  useEffect(() => {
    if (!lessonId || disableTracking) return;

    const pageViewedInfoResponse = dispatch(
      getMeetingViewedInfo({ id: lessonId, isConference: !!isConference })
    );
    pageViewedInfoResponse
      .unwrap()
      .then((info) => {
        videosInfoRef.current.state = info.data.videos
          ? JSON.parse(info.data.videos)
          : videosInfoRef.current.state;
        console.log(info);
        setIsLoadData(true);
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      pageViewedInfoResponse.abort();
      setIsLoadData(false);
    };
  }, [dispatch, disableTracking, setIsLoadData, lessonId, isConference]);

  /**
   * Логика учёта времени
   */
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (!isLoadData || disableTracking) return;

    timerRef.current = setInterval(() => {
      counterRef.current = counterRef.current + 1;

      // Проверяем условие прохождения
      if (status !== "completed") {
        processViewConditions();
      }

      if (counterRef.current < 15 || !lessonId) return;
      counterRef.current = 0;

      console.log(videosInfoRef.current);
      console.log("[SMART VIEW] Фиксируем время");
      // Фиксируем время

      dispatch(
        postMeetingViewedInfo({
          videos:
            videosInfoRef.current.state && videosInfoRef.current.state.length > 0
              ? JSON.stringify(videosInfoRef.current.state)
              : undefined,
          meetingId: lessonId,
        })
      );
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [dispatch, disableTracking, lessonId, processViewConditions, status, isLoadData]);

  return { isLoadData, initializeVideos };
};
