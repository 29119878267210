export interface MeetingState {
  isLoadingLesson: boolean;
  lesson: any | null;
  isEnabledNotification: boolean;
}

export enum MeetingType {
  educationStudio = "education_studio",
  otherSystem = "other_system",
}
