import { SVGProps } from "react";

export const TaskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.222 2H5.778C4.796 2 4 2.995 4 4.222v15.556C4 21.005 4.796 22 5.778 22h12.444c.982 0 1.778-.995 1.778-2.222V4.222C20 2.995 19.204 2 18.222 2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h8v6H8zM16 8H8"
    />
  </svg>
);
