import { Option } from "../../components/Select/types";

const searchForParents = (option: Option, selectedOptions: Option[], result: Option[]) => {
  const parent = selectedOptions.find((obj) => obj.value === option.parent_id);

  if (!parent) {
    if (!result.find((obj) => obj.value === option.value)) {
      // Добавляем родителя
      result.push(option);

      // Удаляем детей, которые могли уже попасть в result
      for (const el of result.filter((obj) => obj.parent_id === option.value)) {
        const index = result.findIndex((obj) => obj.value === el.value);
        if (index !== -1) result.splice(index, 1);
      }
    }
    return;
  }

  searchForParents(parent, selectedOptions, result);
};

export const getUpperLevelOptions = (selectedOptions: Option[]): Option[] => {
  if (!selectedOptions) return [];
  const result: Option[] = [];

  for (const option of selectedOptions) {
    searchForParents(option, selectedOptions, result);
  }

  return result;
};
