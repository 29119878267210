import { useClickAway, useEventListener } from "ahooks";
import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./CustomModal.module.scss";

interface CustomModalProps {
  children?: ReactNode;
  isActiveModal: boolean;
  clickOutsideClose?: boolean;
  onClose: () => void;
}

export const CustomModal = ({
  children,
  onClose,
  isActiveModal = false,
  clickOutsideClose = true,
}: CustomModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isRender, setIsRender] = useState<boolean>();

  useClickAway(() => {
    if (isRender && isActiveModal && clickOutsideClose) {
      onClose();
    }
  }, [modalRef]);

  useEventListener("keydown", (event) => {
    if (event.key.toLocaleLowerCase() === "escape") {
      onClose();
    }
  });

  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal} ref={modalRef}>
        {children}
      </div>
    </div>
  );
};
