import { ModalContainerProps } from "./types/interfaces";

export const ModalsContainerReducer = (
  state: ModalContainerProps[],
  action: { type: string; payload: ModalContainerProps }
) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return [...state, action.payload];
    case "CLOSE_MODAL":
      return state.slice(0, -1);
    default:
      return state;
  }
};
