import { ReactNode, useReducer } from "react";
import { ModalsContainerReducer } from "./ModalsContainer.reducer";
import { ModalsRenderContainer } from "./components";
import { ModalsContainerContext } from "./context";

export const ModalsContainer = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(ModalsContainerReducer, []);
  return (
    <ModalsContainerContext.Provider value={dispatch}>
      {children}
      <ModalsRenderContainer modals={state} />
    </ModalsContainerContext.Provider>
  );
};
