import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ConversationDeleteRequest,
  ConversationPostRequest,
  ConversationPutRequest,
} from "../interfaces";
import {
  addConversationMessage,
  deleteConversationMessages,
  getConversationMessages,
  updateConversationMessage,
} from "../../../../../../api";

const name = "conversation";

export const getMessages = createAsyncThunk(
  `${name}/getMessages`,
  async (courseId: number, thunkAPI) => {
    try {
      return await getConversationMessages(courseId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

export const createMessage = createAsyncThunk(
  `${name}/createMessage`,
  async ({ course_id, ...data }: ConversationPostRequest, thunkAPI) => {
    try {
      return await addConversationMessage({ courseId: course_id, data });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

export const updateMessage = createAsyncThunk(
  `${name}/updateMessage`,
  async ({ course_id, message_id, ...data }: ConversationPutRequest, thunkAPI) => {
    try {
      return await updateConversationMessage({ courseId: course_id, messageId: message_id, data });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

export const deleteMessage = createAsyncThunk(
  `${name}/deleteMessage`,
  async ({ course_id, message_id }: ConversationDeleteRequest, thunkAPI) => {
    try {
      await deleteConversationMessages({ courseId: course_id, messageId: message_id });
      return message_id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);
