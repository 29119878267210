import { generateUid } from "app/utils";
import { useContext } from "react";
import { NotificationContext } from "./NotificationProvider";
import { NotificationProps } from "./interfaces";

export const useNotification = () => {
  const dispatch = useContext(NotificationContext);

  const showNotification = (props: NotificationProps) => {
    const id = generateUid();
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        id,
        ...props,
      },
    });
    return id;
  };

  const closeNotification = (id: string) =>
    dispatch({
      type: "REMOVE_NOTIFICATION",
      payload: {
        id,
        message: "",
      },
    });

  return { showNotification, closeNotification };
};
