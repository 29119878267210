interface MimeExtractResult {
  base64: string;
  mimeType: string;
}

export const extractMimeType = (base64String: string): MimeExtractResult => {
  const regex = /^data:(.*?);base64,/;

  const match = base64String.match(regex);

  if (match && match[1]) {
    const [fullMatch, mimeType] = match;
    const base64 = base64String.replace(fullMatch, "");
    return { base64, mimeType };
  } else {
    throw new Error("Invalid base64 string. Cannot extract MIME type.");
  }
};
