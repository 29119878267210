import { useEffect } from "react";
import { CompetenciesCard } from "components/CompetenciesCard";
import { СompetenciesType } from "../../../../components/CompetenciesCard/competancies.types";
import { TextMain } from "components/Typography/Texts";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getLessonCompetence } from "pages/Course/redux/courseSlice";
import { selectCompetence } from "pages/Course/redux/courseSelectors";
import { getCompetences } from "pages/Courses/redux/coursesListSlice";
import styles from "./LessonCompetenciesList.module.scss";

interface LessonCompetenciesListProps {
  id?: number;
}

export const LessonCompetenciesList = ({ id }: LessonCompetenciesListProps): JSX.Element => {
  const lessonCompetences = useAppSelector(selectCompetence);
  const dispatch = useAppDispatch();

  useEffect(() => {
    id && dispatch(getLessonCompetence({ id }));
    dispatch(getCompetences());
  }, [dispatch, id]);

  return (
    <>
      {lessonCompetences && lessonCompetences.length > 0 && (
        <div className={styles["list"]}>
          <TextMain className={styles["title"]}>
            После прохождения урока вам откроются компетенции
          </TextMain>
          {lessonCompetences.map((competence: СompetenciesType) => (
            <CompetenciesCard
              competence={competence}
              key={competence.id}
              className={styles["competence"]}
            />
          ))}
        </div>
      )}
    </>
  );
};
