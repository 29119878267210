import { useClickAway, useEventListener } from "ahooks";
import classNames from "classnames";
import { CloseIcon } from "components/Icons";
import { PointerEvent, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  children?: ReactNode;
  onClose?: () => void;
  isActiveModal: boolean;
  clickOutsideClose?: boolean;
  fullscreen?: boolean;
  closeClassName?: string;
  showCloseButton?: boolean;
  modalContentClassName?: string;
}

export const Modal = ({
  children,
  onClose = () => {},
  isActiveModal = false,
  clickOutsideClose = true,
  fullscreen = false,
  closeClassName,
  showCloseButton = true,
  modalContentClassName = "",
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isRender, setIsRender] = useState<boolean>();

  const handleCloseModal = (event: PointerEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClose();
  };

  useClickAway(() => {
    if (isRender && isActiveModal && clickOutsideClose) {
      onClose();
    }
  }, [modalRef]);

  useEventListener("keydown", (event) => {
    if (event.key.toLocaleLowerCase() === "escape") {
      onClose();
    }
  });

  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <div
      className={classNames(styles.container, {
        [styles["fullscreen"]]: fullscreen,
      })}
    >
      <div className={styles.modal}>
        <div className={classNames(styles.content, modalContentClassName)} ref={modalRef}>
          {children}
          {showCloseButton && (
            <button
              className={classNames(styles["close-button"], closeClassName)}
              onClick={handleCloseModal}
            >
              <CloseIcon className={styles["close-icon"]} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
