import { SearchParamsOption } from "ky";
import { KY } from "../ky-instance";
import { EndPoints } from "../../app/endpoints";
import { Capacitor } from "@capacitor/core";

export const getShopProducts = (params: SearchParamsOption) =>
  KY.get(EndPoints.SHOP_PRODUCTS, {
    prefixUrl: Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE || "/" : "/",
    searchParams: params ? JSON.parse(JSON.stringify(params)) : params,
  }).json();
