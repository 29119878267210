import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { useAsyncEffect, useMemoizedFn } from "ahooks";
import { changeIsHasInternet, isHasInternetSelector } from "app/store";
import { NoInternetConnection } from "components/NoInternetConnection";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { ReactNode } from "react";
import styles from "./InternetAccessTracker.module.scss";

export const InternetAccessTracker = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const isHasInternet = useAppSelector(isHasInternetSelector);

  const trackingNetwork = useMemoizedFn(async () => {
    const { connected, connectionType } = await Network.getStatus();
    const isConnectedToInternet =
      connected && connectionType !== "unknown" && connectionType !== "none";
    dispatch(changeIsHasInternet(isConnectedToInternet));

    Network.addListener("networkStatusChange", ({ connected, connectionType }) => {
      const isConnectedToInternet =
        connected && connectionType !== "unknown" && connectionType !== "none";
      dispatch(changeIsHasInternet(isConnectedToInternet));
    });
  });

  useAsyncEffect(async () => {
    if (Capacitor.isNativePlatform()) {
      trackingNetwork();
    }
  }, []);

  return (
    <div className={styles.container}>
      {Capacitor.isNativePlatform() && !isHasInternet && (
        <div className={styles["no-internet-connection-container"]}>
          <NoInternetConnection className={styles["no-internet-connection"]} />
        </div>
      )}
    </div>
  );
};
