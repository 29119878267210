import { SVGProps } from "react";

export const BackupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="46"
    viewBox="0 0 48 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7188 27.3125L18.0541 29.6478L22.3438 25.3747V45.5312H25.6562V25.3747L29.9459 29.6478L32.2812 27.3125L24 19.0312L15.7188 27.3125Z"
      fill="currentColor"
    />
    <path
      d="M36.4221 33.938H35.5939V30.6255H36.4221C38.3988 30.7046 40.3259 29.9952 41.7795 28.6534C43.2332 27.3116 44.0942 25.4472 44.1733 23.4705C44.2524 21.4939 43.543 19.5667 42.2012 18.1131C40.8593 16.6594 38.995 15.7984 37.0183 15.7193H35.5939L35.4283 14.3612C35.0609 11.5728 33.6923 9.01304 31.5777 7.15877C29.463 5.3045 26.7464 4.28214 23.9339 4.28214C21.1215 4.28214 18.4049 5.3045 16.2902 7.15877C14.1755 9.01304 12.807 11.5728 12.4396 14.3612L12.4064 15.7193H10.9821C9.00538 15.7984 7.14105 16.6594 5.79923 18.1131C4.45741 19.5667 3.748 21.4939 3.82707 23.4705C3.90614 25.4472 4.7672 27.3116 6.22084 28.6534C7.67448 29.9952 9.60163 30.7046 11.5783 30.6255H12.4064V33.938H11.5783C8.92236 33.9212 6.36637 32.9232 4.40176 31.1358C2.43715 29.3485 1.2026 26.8979 0.935472 24.2554C0.668341 21.6129 1.38749 18.9648 2.95464 16.8205C4.5218 14.6761 6.82634 13.1867 9.42519 12.6387C10.1402 9.30384 11.9772 6.31506 14.6296 4.17104C17.2821 2.02701 20.5896 0.857422 24.0002 0.857422C27.4108 0.857422 30.7183 2.02701 33.3707 4.17104C36.0232 6.31506 37.8602 9.30384 38.5752 12.6387C41.174 13.1867 43.4786 14.6761 45.0457 16.8205C46.6129 18.9648 47.332 21.6129 47.0649 24.2554C46.7978 26.8979 45.5632 29.3485 43.5986 31.1358C41.634 32.9232 39.078 33.9212 36.4221 33.938Z"
      fill="currentColor"
    />
  </svg>
);
