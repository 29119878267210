export const EndPoints = {
  CODE_EMAIL_CHECKUP: "code/email/checkup/",
  SEND_EMAIL: "auth/register",
  AUTH_REGISTER_EXISTS: "auth/register/exists/",
  AUTH_REGISTER_VERIFY: "auth/register/verify/",
  AUTH_REGISTER: "auth/register/",
  AUTH_LOGIN: "auth/login",
  AUTH_LOGIN_ANONYMOUS: "auth/login/anonymous",
  GET_CAPABILITIES: "capabilities",
  AUTH_REFRESH: "auth/refresh/",
  PASSWORD_POLICY: "password/policy/",
  MOBILE_SETTINGS: "mobile/settings",
  CODE_SEND: "code/send/",
  POLICY_CHECK: "policycheck",
  LOGOUT: "auth/logout",
  MAIL_CHECK: "mailcheck",
  USER_CLUBS: "profile/clubs",
  REGISTRATION_IS_ALLOWED: "registration",
  COURSE_LIST: "course-list/",
  PROFILE: "profile/",
  PROFILE_IMAGE: "profile/image/",
  PROFILE_IMAGE_PREDEFINED: "profile/image/predefined",
  PROFILE_COINS: "profile/coins",
  POFILE_TRANSACTIONS: "shop/api/transactions",
  SHOP_SETTINGS: "settings",
  SHOP_PARAMETERS: "shop/api/parameters",
  SHOP_COINS: "shop/api/coins",
  SHOP_COINS_DETAILED: "shop/api/coins/detailed",
  SHOP_PRODUCTS: "shop/api/products",
  SHOP_ORDERS: "shop/api/orders",
  SHOP_ADMIN_ORDERS: "shop/api/admin/orders",
  SHOP_FILES: "/shop/api/files",
  PROFILE_FIELDS: "profile/full/",
  PROFILE_FILE: "profile/file/",
  PROFILE_FILES: "profile/files/",
  PROFILE_CLUBS: "profile/clubs",
  PROFILE_ACCEPT_AGREEMENT: "profile/accept/personal-agreement",
  PROFILE_REVOKE_PERSONAL_AGREEMENT: "profile/revoke/personalAgreement",
  PROFILE_RATING: "profile/rating",
  NOTIFICATION_SETTINGS: "notifications/settings",
  REFERENCE: "reference/",
  REFERENCE_CITIZENSHIP: "reference/citizenship",
  REPORTS_SCORES: "reports/scores",
  REFERENCE_WORKPLACE: "reference/workplace",
  COURSES_LIST: "courses",
  COURSES_CATEGORIES: "course-categories",
  COURSES_FORMATS: "courses/formats/created ",
  COURSE_PREVIEW: (id) => {
    return "courses/" + id + "/images/preview";
  },
  USER_SAVE_ACTIVE_TIME: "studyingtime/mobileapp",
  USER_COURSES: "user/courses/search",
  USER_COURSES_COUNT: "user/courses/count",
  COURSE: "courses",
  COURSE_CERTIFICATE_AVALABILITY: (id) => "certificate/" + id + "/availability",
  COURSE_CERTIFICATE_DOWNLOAD_AVALABILITY: (id) => "certificate/" + id + "/download/status",
  COURSE_ENROLL: (id) => "courses/" + id + "/enroll",
  COURSE_CONTENT: (id) => "courses/" + id + "/content",
  COURSE_SECTIONS: (id) => "sections/" + id,
  COURSE_UNITS: (id) => "units/" + id,
  COURSE_LESSONS: (id) => "lessons/" + id,
  COURSE_MEETING_LESSON: (id) => "meeting/" + id,
  COURSE_LESSONS_CONTENT: (id) => "lessons/" + id + "/content",
  COURSE_LESSONS_START: (id) => "lessons/" + id + "/start",
  COURSE_LESSONS_FINISH: (id) => "lessons/" + id + "/finish",
  COURSE_TASKS_FINISH: (id) => "task/" + id + "/answer/end ",
  COURSE_QUESTIONNARE_ANSWER: (id) => "questionnaires/" + id + "/answers",
  COURSE_LESSONS_FINISH_OLD: (id) => "courses/1/sections/1/units/1/lessons/" + id + "/finish",

  COURSE_TRAINERS: (id) => `trainers/${id}`,
  COURSE_TRAINERS_START: (id) => `trainers/${id}/start`,
  COURSE_TRAINERS_END: (id) => `trainers/${id}/end`,
  COURSE_TRAINERS_RESULTS: (id) => `trainers/${id}/results`,
  COURSE_TRAINERS_TRAKED_DATA: (id) => `trainers/${id}/tracked_data`,

  CONVERSATION: (id) => `courses/${id}/conversation`,
  CONVERSATION_MESSAGE: (id, mesId) => `courses/${id}/conversation/message/${mesId}`,

  COURSE_TESTMOMIALS: (id) => "courses/" + id + "/testimonials/top",
  COURSE_RESULT: (id) => "courses/" + id + "/results",
  COURSE_FINISH: (id) => "courses/" + id + "/finish",
  COURSE_FEEDBACK: (id) => "courses/" + id + "/feedback",
  COURSE_SUMMARY: (id) => "courses/" + id + "/summary",
  COURSE_COINS: (id) => "courses/" + id + "/coins",
  COURSE_POLL: (id) => "courses/" + id + "/poll",
  COURSE_SHARE: (id) => "courses/" + id + "/share",
  COURSE_SHARED: (id) => "shared/" + id,
  LESSON_VISITED: (id) => "course/" + id + "/visited",

  QUIZE: (id) => "quizzes/" + id,
  QUIZE_START: (id) => "quizzes/" + id + "/start",
  QUIZE_FINISH: (id) => "quizzes/" + id + "/end",
  QUIZE_STATUS: (id) => "quizzes/" + id + "/status",
  QUIZE_ANSWER: (id, questionId) => "quizzes/" + id + "/questions/" + questionId + "/answer",
  QUIZE_ANSWERS: (id, attemptId) => "quizzes/" + id + "/questions/answers/" + attemptId,
  QUIZE_QUESTIONS: (id) => "quizzes/" + id + "/questions",
  QUIZE_RESULTS: (id) => "quizzes/" + id + "/results",
  QUIZE_SCORE: (id) => "quizzes/" + id + "/score",

  TASK: (id) => "task/" + id,
  TASK_RESULTS: (id) => "task/" + id + "/results",
  TASK_TEXT_ANSWER: (id) => "task/" + id + "/answer",
  TASK_FILE_ANSWER: (id) => "task/" + id + "/answer/upload",

  COURSE_PAGE: (id) => "pages/" + id,
  COURSE_PAGE_CONTENT: (id) => "pages/" + id + "/content",
  COURSE_PAGE_VIEW_INFO: (id) => "pages/" + id + "/viewed_info",
  COURSE_PAGE_VIEWED: (id) => "pages/" + id + "/viewed",

  COURSE_MEETING_VIEW_INFO: (id) => "meeting/" + id + "/viewed_info",
  COURSE_MEETING_VIEWED: (id) => "meeting/" + id + "/viewed",

  COURSE_CONFERENCE_VIEW_INFO: (id) => "conference/" + id + "/viewed_info",
  COURSE_CONFERENCE_VIEWED: (id) => "conference/" + id + "/viewed",

  MULTICOURSE_PAGE_CONTENT: (id) => "multicourse/" + id + "/content",
  MULTICOURSE_PAGE_CONTENT_SECTIONS: (id) => "multicourse/" + id + "/sections",

  СOURSE_COMPETENCE: (id) => "courses/" + id + "/competences",
  СOURSE_FEEDBACKS: (id) => "courses/" + id + "/feedbacks",
  LESSON_COMPETENCE: (id) => "lessons/" + id + "/competences",
  RESULT_COMPETENCE: (id) => "courses/" + id + "/results/competences",
  COURSE_STATUSES: "courses/enrolled_status",

  BADGES: "badges",

  COMPETENCES: "competences",
  PROFILE_COMPETENCES: "profile/competences",

  RATING: "rating",
  RATING_TOP: "rating/top",
  RATING_LEVELS: "rating/levels",

  KEYWORDS_LIST: "keywords",
  KEYWORDS_COURSES_LIST: "courses/keywords",

  CLUB: (id) => "clubs/" + id,
};
