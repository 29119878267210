import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationSetting } from "../interfaces";
import { saveNotificationSettings } from "../../../../api";
import { NotificationProps } from "components/Notification/interfaces";

export const saveNotificationSettingsThunkAction = createAsyncThunk<
  Promise<void>,
  { settings: NotificationSetting[]; action: any }
>(
  "settings/saveNotificationSettings",
  async (params: {
    settings: NotificationSetting[];
    action: (props: NotificationProps) => string;
  }) => {
    try {
      const response = (await saveNotificationSettings(params.settings)) as any;

      params.action({
        message: response.status === 200 ? "Изменения сохранены" : "Ошибка сохранения",
        type: response.status === 200 ? "success" : "error",
        showTime: 5,
      });
    } catch (e) {
      console.log(e);
      params.action({
        message: "Ошибка сохранения",
        type: "error",
        showTime: 5,
      });
    }
  }
);
