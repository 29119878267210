import { NoInternetConnectionSvgImage } from "components/svg-images";
import styles from "./NoInternetConnection.module.scss";
import classNames from "classnames";

export const NoInternetConnection = ({ className = "" }: { className?: string }) => (
  <div className={classNames(styles.container, className)}>
    <NoInternetConnectionSvgImage />
    <p className={styles.text}>
      Проверьте соединение. Чтобы продолжить, необходимо подключение к интернету.
    </p>
  </div>
);
