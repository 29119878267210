import { СompetenciesType } from "components/CompetenciesCard/competancies.types";
import { CourseCompetence } from "pages/Courses/redux/interface";
import { numWord } from "app/utils";
import { useNavigate } from "react-router";
import { WebAppRoutes } from "app/routes";
import { format } from "date-fns";
import { useMediaQuery } from "hooks";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import styles from "./CompetenciesCardInfo.module.scss";

interface CompetenciesCardInfoProps {
  competence: СompetenciesType;
  parents: СompetenciesType[] | CourseCompetence[];
  childrenCompetentces: CourseCompetence[];
  clicklOnCompetence: () => void;
}

export const CompetenciesCardInfo = ({
  competence,
  parents,
  childrenCompetentces,
  clicklOnCompetence = () => {},
}: CompetenciesCardInfoProps): JSX.Element => {
  const navigate = useNavigate();
  const coursesWithCompetence = competence.courses_count;
  const courseResivedTime =
    competence.received_ts && format(new Date(competence.received_ts * 1000), "dd.MM.yyyy");
  const childrenCompetentcesCount = childrenCompetentces.length;

  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <div className={styles["info"]}>
      {!isMobile && <span className={styles["title"]}>{competence.name}</span>}
      <ul className={styles["description"]}>
        {courseResivedTime && <p>{`Получена ${courseResivedTime}`}</p>}
        {!!parents.length && (
          <li>
            Входит в компетенцию{" "}
            <button
              className={classNames(styles["competence-button"], {
                [styles["competence-button-disabled"]]: Capacitor.isNativePlatform() || isMobile,
              })}
              onClick={clicklOnCompetence}
              disabled={Capacitor.isNativePlatform() || isMobile}
            >
              “{parents[0].name}”
            </button>
          </li>
        )}
        {!!childrenCompetentcesCount && (
          <li>{`Включает ${childrenCompetentcesCount} ${numWord(childrenCompetentcesCount, [
            "подкомпетенцию",
            "подкомпетенции",
            "подкомпетенций",
          ])}`}</li>
        )}
        {!!coursesWithCompetence && (
          <li>
            <span
              className={styles["underline"]}
              onClick={() => {
                navigate(WebAppRoutes.COURSES, {
                  state: {
                    filter: {
                      competences: competence.id,
                    },
                  },
                });
              }}
            >
              {`${coursesWithCompetence} ${numWord(coursesWithCompetence, [
                "курс",
                "курса",
                "курсов",
              ])}`}
            </span>{" "}
            с этой компетенцией
          </li>
        )}
      </ul>
    </div>
  );
};
