import { NotificationProps } from "components/Notification/interfaces";
import { Notification } from "../Notification";
import styles from "./NotificationContainer.module.scss";

export const NotificationContainer = ({ notifications }: { notifications: any[] }) => {
  return (
    <div className={styles["notifications-container"]}>
      {notifications.map((props: NotificationProps) => (
        <Notification key={props.id} {...props} />
      ))}
    </div>
  );
};
