import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const deleteConversationMessages = ({
  courseId,
  messageId,
}: {
  courseId: number;
  messageId: number;
}) => KY.delete(EndPoints.CONVERSATION_MESSAGE(courseId, messageId)).json();
