import { CourseCompetence } from "pages/Courses/redux/interface";
import { CompetenciesCardItem } from "../CompetenciesCardItem";
import { СompetenciesType } from "components/CompetenciesCard/competancies.types";
import styles from "../../CompetenciesCard.module.scss";

export const CompetenceCardBar = ({
  competence,
  parentCompetentces,
}: {
  competence: СompetenciesType;
  parentCompetentces: CourseCompetence[];
}): JSX.Element => {
  // массив всех компетенций к отображению в мапе
  const competenciesArr = [...parentCompetentces, competence];

  // массив opacity для каждого элемента списка
  const setOpacity = competenciesArr
    .map((_, index) => (index + 1) / ((parentCompetentces.length || 0) + 1))
    .reverse();

  return (
    <div className={styles["bar"]}>
      {competenciesArr.map((item, index) => (
        <CompetenciesCardItem
          index={index}
          key={item.id}
          allCompetentces={parentCompetentces}
          opacity={setOpacity}
        />
      ))}
    </div>
  );
};
