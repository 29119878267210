import { numWord } from "app/utils";
import { Tooltip } from "components/Tooltip";
import { format } from "date-fns";
import { IAchievement } from "pages/Achievements/redux/interfaces";
import { ReactNode } from "react";
import styles from "./AchievmentsTooltip.module.scss";

const lcFirst = (str: string) => str[0].toLowerCase() + str.slice(1);

const TooltipRow = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={styles["row"]}>{children}</div>
);

interface AchievmentsTooltipProps {
  children: ReactNode;
  achievment: IAchievement;
}

export const AchievmentsTooltip = ({
  children,
  achievment,
}: AchievmentsTooltipProps): JSX.Element => {
  // Массив criteria.name
  const criteriaName: string[] | [] = achievment.criteria
    .filter((item) => item.name)
    .map((item) => item.name);

  // Массив выполненных критериев(если присутствует поле received_dt)
  const receivedCriteria = achievment.criteria.filter((item) => !!item.received_dt);

  // Последняя полученная награда
  const lastReceivedCriteria =
    receivedCriteria.length > 0 &&
    receivedCriteria.sort(
      (a, b) => new Date(a.received_dt!).getTime() - new Date(b.received_dt!).getTime()
    )[0];

  // Дата последней полученной награды
  const lastReceivedData = lastReceivedCriteria && new Date(lastReceivedCriteria.received_dt!);

  // Получено <значение criterions.received_dt в виде ДД.ММ.ГГГГ> <значение criterions.name>.
  // Отображается, если заполнено criterions.received_dt, иначе если не заполнено отображается
  // «Может быть получено <значение criterions.name> (если несколько, то через запятую)»
  const firstRow =
    lastReceivedCriteria && lastReceivedCriteria.received_dt ? (
      <TooltipRow>
        Получено {format(new Date(lastReceivedCriteria.received_dt), "dd.MM.yyyy")}{" "}
        {lcFirst(lastReceivedCriteria.name)}
      </TooltipRow>
    ) : (
      criteriaName.length > 0 && (
        <TooltipRow>
          Может быть получено{" "}
          {criteriaName.map((item, index) =>
            // первая буква criteria.name в нижний регистр для перечисления
            lcFirst(item + (criteriaName.length > index + 1 ? ", " : ""))
          )}
        </TooltipRow>
      )
    );

  // Действует до <значение day_expiration в виде ДД.ММ.ГГГГ>. Если не заполнено,
  // то не отображается. Если заполнено day_expiration и criterions.received_dt,
  // то отображается дата, полученная по формуле criterions.received_dt +  day_expiration.
  // Если  criterions.received_dt несколько, то берется самая поздняя.
  const secondRowContent = achievment.expiration_dt
    ? "Действует до " + format(new Date(achievment.expiration_dt), "dd.MM.yyyy")
    : achievment.day_expiration && lastReceivedData
    ? "Действует до " +
      format(
        new Date(lastReceivedData.setDate(lastReceivedData.getDate() + achievment.day_expiration)),
        "dd.MM.yyyy"
      )
    : "";

  // Действует <значение day_expiration> дней. Отображается, если заполнено
  // day_expiration, но не заполнено criterions.received_dt, иначе не отображается.
  const thirdRowContent =
    achievment.day_expiration && !lastReceivedData
      ? `Действует ${achievment.day_expiration} ${numWord(achievment.day_expiration, [
          "день",
          "дня",
          "дней",
        ])}`
      : "";

  const AchievementTooltipContent = (): JSX.Element => (
    <div className={styles["tooltip"]}>
      {firstRow}
      {secondRowContent && <TooltipRow>{secondRowContent}</TooltipRow>}
      {thirdRowContent && <TooltipRow>{thirdRowContent}</TooltipRow>}
      {/* Значение description */}
      {achievment.criteria?.some((value) => !!value.received_dt) && achievment.description && (
        <TooltipRow>{achievment.description}</TooltipRow>
      )}
    </div>
  );

  return (
    <Tooltip wrapperClassName={styles["tooltip-wrapper"]} content={<AchievementTooltipContent />}>
      {children}
    </Tooltip>
  );
};
