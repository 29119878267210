import { CourseCompetence } from "pages/Courses/redux/interface";

export const getAllParentIds = (nodes: CourseCompetence[], nodeId: number): number[] => {
  const parentIds: number[] = [];

  const findParents = (currentNodeId: number) => {
    const currentNode = nodes.find((node) => node.id === currentNodeId);

    if (currentNode && currentNode.parent_id) {
      parentIds.push(currentNode?.parent_id);

      if (currentNode.parent_id !== 0) {
        findParents(currentNode?.parent_id);
      }
    }
  };

  findParents(nodeId);

  return parentIds;
};
