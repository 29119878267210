import {
  CommentsSvgIcon,
  StarSvgIcon,
  SettingsCheckIcon,
  CloseSearchPopupIcon,
} from "../Svg/Icons";
import { Badge } from "components/Badge";
import { CheckIcon, DoneIcon } from "components/Icons";

const DoneCountChunk = ({ value, ...rest }) => {
  return (
    <div {...rest} className="done-value-chunk">
      <span>{`Пройден: ${value}`}</span>
    </div>
  );
};

const TestNotStartedChunk = ({ value }) => (
  <Badge color="base" type="status">
    <CheckIcon />
    {value}
  </Badge>
);

const RatingChunk = ({ value, ...rest }) => {
  return (
    <div {...rest} className="rating-chunk">
      <StarSvgIcon />
      <p>{value}</p>
    </div>
  );
};

const CommentsCountChunk = ({ value, ...rest }) => {
  return (
    <div {...rest} className="comments-count-chunk">
      <CommentsSvgIcon />
      <p>{value}</p>
    </div>
  );
};

const CommentsForumChunk = ({ value, ...rest }) => {
  return (
    <div {...rest} className="forum-comments-chunk">
      <CommentsSvgIcon />
      <p>{value}</p>
    </div>
  );
};

const CommentsForumChunkEmpty = ({ value, ...rest }) => {
  return (
    <div {...rest} className="forum-comments-chunk forum-comments-chunk-empty">
      <CommentsSvgIcon />
      <p>{value}</p>
    </div>
  );
};

const CheckedTestChunk = ({ value }) => (
  <Badge color="base" type="status">
    <CheckIcon />
    {value}
  </Badge>
);

const CompletedTestChunk = ({ value }) => (
  <Badge color="success" type="status">
    <DoneIcon />
    {value}
  </Badge>
);

const SettingsChunk = ({ value, classes, isValid, isSubmitted }) => (
  <div className={`settings-chunk ${classes}`}>
    {!isSubmitted || (isSubmitted && isValid) ? <SettingsCheckIcon /> : <CloseSearchPopupIcon />}
    <p className="settings-chunk__text">{value}</p>
  </div>
);

export {
  CheckedTestChunk,
  CompletedTestChunk,
  SettingsChunk,
  TestNotStartedChunk,
  DoneCountChunk,
  CommentsCountChunk,
  RatingChunk,
  CommentsForumChunk,
  CommentsForumChunkEmpty,
};
