import { ReactNode } from "react";

export const TextMain = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return <p className={`text-main ${className ?? ""}`}>{children}</p>;
};

export const TextCard = ({
  children,
  className,
  hidden,
}: {
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  return <p className={`text-card ${className ?? ""}`}>{children}</p>;
};

export const TextHelper = ({
  children,
  className,
  hidden,
}: {
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  return <p className={`text-helper ${className ?? ""}`}>{children}</p>;
};

export const TextBadge = ({
  children,
  className,
  hidden,
}: {
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  return <p className={`text-badge ${className ?? ""}`}>{children}</p>;
};

export const Text = ({
  children,
  className,
  hidden,
  size,
}: {
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
  size?: string;
}) => {
  return hidden ? null : (
    <p data-size={size} className={`text ${className ?? ""}`}>
      {children}
    </p>
  );
};
