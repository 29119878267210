import { Button } from "components/Buttons";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { MeetingType } from "../../../../redux";
import styles from "./DescriptionAfterStartMeeting.module.scss";

interface DescriptionAfterStartMeetingProps {
  startDate?: Date | number;
  onClick: () => void;
  showButton: boolean;
  hasEndDate?: boolean;
  isRecordedMeeting: boolean;
  meetingType: MeetingType;
}

export const DescriptionAfterStartMeeting = ({
  startDate = 0,
  hasEndDate = false,
  showButton,
  onClick,
  isRecordedMeeting,
  meetingType,
}: DescriptionAfterStartMeetingProps) => (
  <>
    <div className={styles["description-container"]}>
      {!!startDate && +startDate > 0 && (
        <p className={styles.description}>
          Трансляция вебинара началась{" "}
          <span className={styles["bold-date"]}>{format(startDate, "HH:mm", { locale: ru })}</span>
        </p>
      )}

      {isRecordedMeeting && meetingType !== MeetingType.otherSystem && (
        <p className={styles.description}>
          По окончанию вебинара вам будет доступна запись на весь срок обучения.
        </p>
      )}

      {!+startDate && hasEndDate && (
        <p className={styles.description}>Дата начала вебинара еще не назначена</p>
      )}
    </div>
    {showButton && (
      <Button color="primary" className={styles["button"]} onClick={onClick}>
        Подключиться
      </Button>
    )}
  </>
);
