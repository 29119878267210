import { SVGProps } from "react";

export const TaskMyAcademyIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={581}
    viewBox="0 0 581 490"
    height={490}
    fill="none"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#E7F7FF"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M572.455 457.158c-101.015 11.579-319.826 5.562-535.591 0-2.876-159.26-5.27-314.252 0-401.379 163.82-7.795 344.923-6.433 535.591 0 7.701 154.492 10.889 296.864 0 401.379Z"
    />
    <path
      fill="#00AEFF"
      d="M572.455 55.78c-190.677-6.434-371.781-7.796-535.591 0-.851 14.057-1.495 29.902-1.977 47.233 207.251 3.264 397.417 2.895 539.451-7.72a7843.24 7843.24 0 0 0-1.874-39.514h-.009Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M574.337 95.294h-.009.009ZM34.877 103.013c203.553 1.769 391.676.719 539.451-7.72M547.991 67.7l17.558 18.815M563.487 67.7l-15.496 18.815M534.198 67.7h-18.816v20.064h18.816V67.699ZM503.898 77.103H485.28M118.949 155.033h379.017M118.949 184.822h379.017M118.949 274.191c140.917 2.205 271.854 2.905 379.017 0M118.949 214.612c142.043 1.798 275.543 3.444 379.017 0M118.949 244.402c145.316-7.473 279.176-10.434 379.017 0M118.949 303.99c140.766-6.347 270.983-8.429 379.017 0"
    />
    <path
      fill="#F1472E"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M284.377 375.48H150.044c-6.27 0-11.352 5.083-11.352 11.352v17.974c0 6.27 5.082 11.352 11.352 11.352h134.333c6.27 0 11.352-5.082 11.352-11.352v-17.974c0-6.269-5.082-11.352-11.352-11.352Z"
    />
    <path
      fill="#3DC100"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M483.039 375.48H348.706c-6.27 0-11.352 5.083-11.352 11.352v17.974c0 6.27 5.082 11.352 11.352 11.352h134.333c6.269 0 11.352-5.082 11.352-11.352v-17.974c0-6.269-5.083-11.352-11.352-11.352Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m393.167 388.724 24.597 15.136 46.354-61.49M203.437 382.073l32.277 26.356M232.346 378.318l-27.595 34.056"
    />
  </svg>
);
