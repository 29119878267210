import classNames from "classnames";
import { CourseCoin } from "../../pages/Course/redux/interfaces/course.interface";
import { useAppSelector } from "../../hooks/redux";
import { selectShopSettings } from "../../pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Capacitor } from "@capacitor/core";
import styles from "./CourseCoinAmount.module.scss";

interface CourseCoinAmountProps {
  coin?: CourseCoin;
  hasText?: boolean;
  type?: "white" | "base";
  className?: string;
  allCourses?: boolean;
  small?: boolean;
}

export const CourseCoinAmount = ({
  coin,
  hasText = true,
  type = "white",
  allCourses = false,
  className,
  small,
}: CourseCoinAmountProps) => {
  const shopSettings = useAppSelector(selectShopSettings);

  if (!coin) return null;

  return (
    <>
      {!!coin.amount && (
        <div
          className={classNames(
            styles["container"],
            styles[`container-${type}`],

            {
              [styles["container--padding"]]: hasText,
              [styles["container-small"]]: small,
              [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
            },
            className
          )}
        >
          <div
            className={classNames(styles["coin"], {
              [styles["coin--disabled"]]: !coin.received,
              [styles["coin--bold"]]: !hasText,
            })}
          >
            <p className={styles["coin-amount"]}>{coin?.amount?.toLocaleString()}</p>

            {shopSettings?.coin?.icon?.path && (
              <img
                src={
                  `${
                    Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
                  }/api` + shopSettings?.coin?.icon?.path
                }
                alt="coin-icon"
                className={styles["coin-icon"]}
              />
            )}
          </div>

          {hasText && (
            <div className={styles["text"]}>
              –{" "}
              {coin.received
                ? allCourses
                  ? "за все курсы"
                  : "получено"
                : allCourses
                ? "за все курсы"
                : "за завершение"}
            </div>
          )}
        </div>
      )}
    </>
  );
};
