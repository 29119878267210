import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import Container from "components/Container/Container";
import {
  MeetingMyAcademyIllustration,
  MeetingRostechnadzorIllustration,
} from "components/Illustrations";
import { CourseWebinarImg } from "components/Svg/Pics";
import { isAfter, isBefore } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import CourseLessonStatus from "pages/Course/components/Lesson/CourseLessonStatus";
import { LessonCompetenciesList } from "pages/Course/components/LessonCompetenciesList";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useState } from "react";
import { CourseCoinAmount } from "../../../../../../components/CourseCoinAmount";
import { CourseCoin } from "../../../../redux/interfaces/course.interface";
import { AchievementsListLesson } from "../../../AchievementsListLesson";
import { useVideoViewingTracking } from "../../hooks/useVideoViewingTracking";
import {
  MeetingType,
  enableNotification,
  isEnabledNotificationSelector,
  postMeetingViewed,
} from "../../redux";
import { StatusLessonType } from "../../types";
import {
  DescriptionAfterStartMeeting,
  DescriptionBeforeStartMeeting,
  DownloadNotification,
} from "./components";
import { MeetingContent } from "./components/MeetingContent";
import styles from "./CourseMeetingStart.module.scss";

interface CourseMeetingStartProps {
  title?: string;
  startDate?: Date;
  status: StatusLessonType;
  hasRecords?: boolean;
  endDate?: Date;
  isRecordedMeeting: boolean;
  meetingUrl?: string;
  coins?: CourseCoin;
  lessonId?: number;
  description?: string;
  meetingType: MeetingType;
  isConference: boolean;
}

export const CourseMeetingStart = ({
  title = "",
  lessonId,
  startDate,
  status,
  endDate,
  isRecordedMeeting = false,
  hasRecords = false,
  meetingUrl = "",
  coins,
  description,
  meetingType,
  isConference,
}: CourseMeetingStartProps) => {
  const dispatch = useAppDispatch();
  const isEnabledNotification = useAppSelector(isEnabledNotificationSelector);
  const onEnableNotification = () => dispatch(enableNotification());
  const selectedLmsSettings = useAppSelector(lmsSettings);

  const { isLoadData, initializeVideos } = useVideoViewingTracking(
    lessonId,
    status,
    isConference,
    !!meetingUrl
  );

  const [previewVisible, setPreviewVisible] = useState(true);

  const isWebinarStarted = isAfter(Date.now(), startDate ?? Date.now()) || !startDate;

  const showNotificationButton = !isEnabledNotification && !isWebinarStarted;

  const showDownloadNotification = isEnabledNotification && !isWebinarStarted;

  const isCurDateAfterStartDate = startDate ? isAfter(Date.now(), startDate) : false;
  const isCurDateBeforeEndDate = endDate ? isBefore(Date.now(), endDate) : false;

  const goToMeeting = (meetingUrl: string) => async () => {
    if (lessonId) {
      dispatch(postMeetingViewed({ meetingId: lessonId, isConference }));
    }

    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url: meetingUrl });
      return;
    }

    window.open(meetingUrl);
  };

  const onVideosLoaded = (videos: NodeListOf<HTMLVideoElement> | undefined) => {
    if (videos && videos.length > 0) {
      setPreviewVisible(false);
    }

    initializeVideos(videos);
  };

  const isEnableCompetenciesInEsMobile =
    selectedLmsSettings.competences &&
    Capacitor.isNativePlatform() &&
    process.env.REACT_APP_THEME === "base";

  return (
    <Container>
      <div className="course-test-block">
        <div
          className={classNames(
            "course-test-content course-webinar-content",
            styles["course-content"],
            { [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor" }
          )}
        >
          <h1 className={styles.title}>{title}</h1>

          <div className="course-test-top-info">
            {status && (
              <div className={styles["status-container"]}>
                <CourseLessonStatus status={status} />
              </div>
            )}

            <CourseCoinAmount coin={coins} />
          </div>

          <AchievementsListLesson />
          {lessonId && (selectedLmsSettings.competences || isEnableCompetenciesInEsMobile) && (
            <LessonCompetenciesList id={lessonId} />
          )}

          {description &&
            isLoadData &&
            ((!startDate && !endDate) || isCurDateAfterStartDate || isCurDateBeforeEndDate) && (
              <MeetingContent data={description} onDataLoaded={onVideosLoaded} />
            )}

          {!isWebinarStarted && startDate && (
            <DescriptionBeforeStartMeeting
              startDate={startDate}
              hasEndDate={!!endDate}
              hasRecords={hasRecords}
              showButton={showNotificationButton}
              onClickButton={onEnableNotification}
              isRecordedMeeting={isRecordedMeeting}
              meetingType={meetingType}
            />
          )}

          {isWebinarStarted && (
            <DescriptionAfterStartMeeting
              startDate={startDate}
              hasEndDate={!!+(endDate ?? 0)}
              showButton={!!meetingUrl}
              onClick={goToMeeting(meetingUrl)}
              isRecordedMeeting={isRecordedMeeting}
              meetingType={meetingType}
            />
          )}
          {showDownloadNotification && (
            <div className={styles["download-notification-container"]}>
              <DownloadNotification
                startEventDate={startDate}
                endEventDate={endDate}
                titleEvent={title}
              />
            </div>
          )}
        </div>
        {previewVisible && (
          <div
            className={classNames("course-test-img course-webinar-img", {
              "course-test-img_rostechnadzor": process.env.REACT_APP_THEME === "rostechnadzor",
            })}
          >
            {process.env.REACT_APP_THEME === "rostechnadzor" && (
              <MeetingRostechnadzorIllustration />
            )}
            {process.env.REACT_APP_THEME === "base" && <CourseWebinarImg />}
            {process.env.REACT_APP_THEME === "myAcademy" && <MeetingMyAcademyIllustration />}
          </div>
        )}
      </div>
    </Container>
  );
};
