import styles from "./RecordLinks.module.scss";
import { generateUid } from "app/utils";
import { Button } from "components/Buttons";

interface RecordLinksProps {
  records: { url: string }[];
}

export const RecordLinks = ({ records }: RecordLinksProps) => {
  const openLinkNewTab = (link: string) => () => {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = link;
    a.click();
  };

  return (
    <div className={styles.links}>
      {records.map(({ url }, index) => (
        <Button color="primary" key={generateUid()} onClick={openLinkNewTab(url)}>
          Посмотреть часть {index + 1}
        </Button>
      ))}
    </div>
  );
};
