import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CourseLesson } from "./interfaces";

export const selectLessonsLoaded = (state: RootState) => {
  return state.course.lessons.loaded;
};

export const selectCourseResultStart = (state: RootState) => {
  return state.course.courseResult.start_ts;
};
export const selectCourseResultEnd = (state: RootState) => {
  return state.course.courseResult.end_ts;
};

export const selectCourseResultGrade = (state: RootState) => {
  return state.course.courseResult.grade
    ? (+state.course.courseResult.grade).toFixed(2)
    : state.course.courseResult.grade;
};

export const selectCourseResultDays = (state: RootState) => {
  return state.course.courseResult.days;
};

export const selectCourseResultIsFinish = (state: RootState) => {
  return state.course.courseResult.isFinish;
};

export const selectCourseIsFinish = (state: RootState) => {
  return state.course.courses.entities[state.course.courses.ids[0]]?.finished_ts ? true : false;
};

export const selectScores = (state: RootState) => {
  return state.course.scores;
};

export const selectСourseIsLoaded = (state: RootState) => {
  return state.course.courses.loading;
};

export const selectCourseStats = (state: RootState) => {
  return state.course.courses.entities[state.course.courses.ids[0]]?.stats;
};

//#region Lessons pagination
export const selectCourseNextLesson = (state: RootState) => {
  const activeLessonIndex = state.course.activeLessonId
    ? state.course.lessons.ids.indexOf(state.course.activeLessonId)
    : 0;
  const nextPageId = state.course.lessons.ids[activeLessonIndex + 1];
  return state.course.lessons.entities?.[nextPageId]
    ? state.course.lessons.entities?.[nextPageId]
    : null;
};

export const selectCoursePrevLesson = (state: RootState) => {
  const activeLessonIndex = state.course.activeLessonId
    ? state.course.lessons.ids.indexOf(state.course.activeLessonId)
    : 0;
  const prevPageId = state.course.lessons.ids[activeLessonIndex - 1];
  return state.course.lessons.entities?.[prevPageId]
    ? state.course.lessons.entities?.[prevPageId]
    : null;
};

export const selectCourseLastLesson = (state: RootState) => {
  return state.course.lessons.entities?.[
    state.course.lessons.ids[state.course.lessons.ids.length - 1] || 0
  ];
};

export const selectCourseLessonPagination = createSelector(
  [selectCourseNextLesson, selectCoursePrevLesson, selectCourseLastLesson],
  (next, previous, last) => {
    return { next, previous, last };
  }
);
//#endregion

//#region Multicourse pagination
export const selectCourseNextContent = (state: RootState) => {
  const activeLessonIndex =
    state.course.multicourseContent?.courses?.findIndex(
      (x) => x.id === state.course.activeContentId
    ) ?? 0;
  return state.course.multicourseContent?.courses?.[activeLessonIndex + 1];
};

export const selectCoursePrevContent = (state: RootState) => {
  const activeLessonIndex =
    state.course.multicourseContent?.courses?.findIndex(
      (x) => x.id === state.course.activeContentId
    ) ?? 0;
  return state.course.multicourseContent?.courses?.[activeLessonIndex - 1];
};

export const selectCourseLastContent = (state: RootState) => {
  return state.course.multicourseContent?.courses?.[
    state.course.multicourseContent.courses?.length - 1
  ];
};

export const selectCourseContentPagination = createSelector(
  [selectCourseNextContent, selectCoursePrevContent, selectCourseLastContent],
  (next, previous, last) => {
    return { next, previous, last };
  }
);
//#endregion

export const selectCourseBottomNav = (state: RootState) => {
  return state.course.options.isBottomNav;
};
export const selectCourseAsideNav = (state: RootState) => {
  return state.course.options.isAsideNav;
};

export const selectCourseMenuIsOpen = (state: RootState) => {
  return state.course.options.courseMenuIsOpen;
};

export const selectLessonIsLock = (state: RootState, lesson_id: string) => {
  const lessonsEntities = state.course.lessons.entities;
  const currentLesson = lessonsEntities?.[lesson_id];
  let noCcompletedIds = [];
  if (currentLesson?.preconditions.length) {
    noCcompletedIds = currentLesson?.preconditions.filter(
      (id) => lessonsEntities?.[id]?.status !== "completed"
    );
  }
  return !noCcompletedIds.length;
};

export const selectIsLoadingCourseLesson = (state: RootState) => state.course.isLoadingCourseLesson;

export const selectCourseCurrentLesson = (state: RootState) => {
  return state.course.lessons.entities?.[state.course.activeLessonId]
    ? state.course.lessons.entities?.[state.course.activeLessonId]
    : null;
};

export const selectLessonCoins = (lessonId: number) =>
  createSelector(
    (state: RootState) => state.course.lessons.entities?.[lessonId],
    (lesson: CourseLesson | undefined) => lesson?.coins
  );

export const selectTrainerCmiValue = (state: RootState) =>
  state.courseTrainersSlice.trainerCmiValue;

export const selectCompetence = (state: RootState) => state.course.competence;

export const selectContent = (state: RootState) => state.course.content;

export const selectFeedbacks = (state: RootState) => state.course.feedbacks;

export const selectMulticourseContentCourses = (state: RootState) =>
  state.course.multicourseContent?.courses;

export const selectMulticourseContentAnyOrder = (state: RootState) =>
  state.course.multicourseContent?.any_order;

export const selectMulticourseContentSections = (state: RootState) =>
  state.course.multicourseContentSections;

export const selectMulticourseContentCoursesCount = (state: RootState) =>
  state.course.multicourseContent?.courses_count;

export const feedbacksIsLoading = (state: RootState) => state.course.feedbacksIsLoading;

export const selectAchievements = (state: RootState) => state.course.achievements;

export const selectMulticourseSummary = (state: RootState) => state.course.summary;

export const selectCourseCoins = (state: RootState) => state.course.coins;

export const selectPollUrl = (state: RootState) => state.course.pollUrl;

export const selectCourseIsLoading = (state: RootState) => state.course.isLoading;

export const selectPageBeforeCourse = (state: RootState) => state.course.pageBeforeCourse;

export const keywordsCoursesList = (state: RootState) => state.course.keywordsCoursesList;

export const courseCertificateAvailability = (state: RootState) =>
  state.course.certificateAvailability;

export const courseCertificateDownloadAvailability = (state: RootState) =>
  state.course.certificateDownloadAvailability;
