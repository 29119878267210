import { WebAppRoutes } from "../app/routes";
import { Navigate, useLocation } from "react-router-dom";

const NeedAuthShared = ({ children }) => {
  const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};
  const location = useLocation();

  if (!oidc.idToken) {
    return <Navigate to={WebAppRoutes.AUTH} state={{ from: location }} replace />;
  }

  return children;
};

export default NeedAuthShared;
