import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

export const AppUrlListener = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split("/webapp").pop();
      if (slug) {
        navigate(slug);
      }
    });
  }, [navigate]);

  return null;
};
