import { SVGProps } from "react";

export const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 2a7 7 0 1 0 0 14A7 7 0 0 0 9 2Zm1 15.945A9.001 9.001 0 0 0 9 0a9 9 0 0 0-1 17.945V20H4a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2h-4v-2.055ZM9 7a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM5 9a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
