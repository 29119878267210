import { normalizeFileSize } from "./utils";

export enum TextMessages {
  AUTHORIZE = "Войти в аккаунт",
  ACCOUNT_CREATE = "Создание аккаунта",
  ACCOUNT_TO_CREATE = "Создать аккаунт",
  ACCOUNT_NEW_TO_CREATE = "Создать новый аккаунт",
  ACCOUNT_ENTER = "Войти в аккаунт",
  AUTHORIZE_TO_ANOTHER = "Войти в другой аккаунт",
  CONTINUE_BUTTON = "Продолжить",
  CODE = "Проверочный код",
  ACCESS_RECOVERY_CODE = "Войти по временному коду",
  ENTER_WITH_TEMPORARY_CODE = "Войти по временному коду",
  CODE_SEND_TEMPORARY = "Отправить временный код",
  CODE_SEND_MESSAGE = "Новый код уже отправлен на ваш email.",
  CODE_GET = "Получить код",
  NAME_MINLENGTH_VLIDATE = "Минимум 2 символа",
  ERROR_CODE_ENTER_BAD = "Вы ввели неправильный код",
  ERROR_CODE_CODE_EXPIRE = "Срок действия кода истек. Мы отправили вам новый.",
  ERROR_PASS_ENTER_BAD = "Вы ввели неправильный пароль",
  EMAIL_PASS_HELP = "Для входа используйте личный email и пароль, которые вы использовали при регистрации",
  CODE_ENTER = "Введите полученный код",
  CODE_COUNT_OUT_OF_RANGE = "Количество попыток ввода превышено. Попробуйте позже",
  CODE_ENTER_ERROR = "Ошибка входа в систему. Возможно вы используете неверные данные. Попробуйте еще раз.",
  UNKNOWN_ERROR = "Неизвестная ошибка",
  FORGOT_PASSWORD = "Не помню пароль",
  NAME = "Имя",
  SECONDNAME = "Фамилия",
  POSITION = "Должность",
  DEPARTMENT = "Подразделение",
  EMAIL = "Email",
  EMAIL_OR_LOGIN = "Электронная почта или логин",
  LOGIN = "Логин",
  ERROR_EMAIL_ENTER_BAD = "Вы ввели неверно email или пароль.\nПопробуйте еще раз",
  ERROR_LOGIN_ENTER_BAD = "Вы ввели неверный логин или пароль",
  ERROR_LOGIN_IS_REQUIRED = "Логин обязателен",
  ERROR_EMAIL_OR_LOGIN_IS_REQUIRED = "Адрес электронной или логин почты обязателен",
  ERROR_EMAIL_IS_NOT_VALID = "Некорректный формат email",
  ERROR_EMAIL_NOT_FOUND_SELF = "Возможно, вы не зарегистрированы. Проверьте, верно ли указан email, или создайте новый аккаунт.",
  ERROR_EMAIL_NOT_FOUND = "Возможно, вы не зарегистрированы. Проверьте, верно ли указан email, или напишите в техподдержку.",
  ERROR_INTERNET_NOT_AVAILABLE = "Отсутствует подключение к интернету. Проверьте подключение и попробуйте еще раз",
  ERROR_FIELD_IS_EMPTY = "Поле не может быть пустым",
  ERROR_LOGIN_IS_NOT_VALID = "Введите корректный логин",
  REDISTERED = "Благодарим\nза регистрацию!",
  REGISTERED_MESSAGE = "Ваша заявка отправлена администратору и будет рассмотрена в ближайшее время. При одобрении\nзаявки вы получите письмо.",
  EMAIL_IS_BLOCKED = "Ваша почта заблокирована",
  BANNED_DOMEN = "Почтовый домен запрещен",
  BANNED_DOMEN_SUB = "Домен электронного адреса не найден в списке разрешенных доменов ЛАНИТ. Если вы являетесь сотрудником ЛАНИТ, свяжитесь с администратором.",
  ERROR_EMAIL_IS_BLOCKED = "Ваш email временно заблокирован. Попробуйте позже или свяжитесь с администратором.",
  ERROR_LOGIN_IS_BLOCKED = "Ваш акканут временно заблокирован. Попробуйте позже или свяжитесь с администратором.",
  EMAIL_ENTER = "Если у вас есть аккаунт в корпоративном домене, используйте его логин и пароль.",
  EMAIL_ENTER_ACADEMY = "Если у вас есть аккаунт на mylanit.ru, используйте корпоративную электронную почту и пароль от нее.",
  EMAIL_EXISTS = "Пользователь с такой учетной записью уже существует",
  PASSWORD = "Пароль",
  EMAIL_LABEL = "Email",
  ENTER = "Войти",
  CODE_SENDED = "Мы отправили вам на почту @email код.",
  PASSWORD_RECOVERY = "Восстановление доступа",
  PASSWORD_RECOVERY_SUB = "Если вы хотите поменять пароль, свяжитесь с администратором по электронной почте admin@sdo.ru или по внутреннему телефону 064. Запрос можно оставить с помощью кнопки ниже.",
  CONFIRM = "Желаем успешного обучения",
  SERTIFICAT_HELP = "Для выдачи вам сертификатов об обучении потребуются ваши данные. Вы можете посмотреть их в личном кабинете.",
  SEND_TO_ADMIN = "Написать администратору",
  TIME_BLOCKED = "Установлена временная блокировка электронной почты. Превышено количество попыток в интервал времени.",
  PASSWORD_INVALID_CHARS = "Недопустимые символы в пароле",
  NAME_INVALID_CHARS = "Только буквы, дефис, пробел",
  POSITION_INVALID_CHARS = "Только буквы, цифры, дефис, пробел",
  POLICY_NOT_AGREED = "Согласие с политикой обработки данных обязательно",
  LABEL_FIRSTNAME = "Имя",
  ERROR_FIRSTNAME_REQUIRED = "Имя обязательно",
  ERROR_FIRSTNAME_SHORT = "Минимальная длина 2 символа",
  ERROR_FIRSTNAME_LONG = "Максимальная длина 25 символов",
  ERROR_FIRSTNAME_INCORRECT = "Введены некорректные символы",

  LABEL_MIDDLENAME = "Отчество",
  ERROR_MIDDLENAME_REQUIRED = "",
  ERROR_MIDDLENAME_SHORT = "Минимальная длина 2 символа",
  ERROR_MIDDLENAME_LONG = "Максимальная длина 50 символов",
  ERROR_MIDDLENAME_INCORRECT = "Введены некорректные символы",

  LABEL_LASTNAME = "Фамилия",
  ERROR_LASTNAME_REQUIRED = "",
  ERROR_LASTNAME_SHORT = "Минимальная длина 2 символа",
  ERROR_LASTNAME_LONG = "Максимальная длина 50 символов",
  ERROR_LASTNAME_INCORRECT = "Введены некорректные символы",

  LABEL_PHONE = "Телефон",
  ERROR_MOBILE_PHONE_REQUIRED = "",
  ERROR_MOBILE_PHONE_INCORRECT_FORMAT = "Некорректный формат телефона",

  LABEL_SNILS = "СНИЛС",
  ERROR_SNILS_REQUIRED = "",
  ERROR_SNILS_INCORRECT_FORMAT = "Некорректный формат СНИЛС",
  ERROR_SNILS_INCORRECT = "Неверный СНИЛС",

  LABEL_RESIDENCE_ADDRESS = "Адрес проживания",
  ERROR_RESIDENCE_ADDRESS_REQUIRED = "",
  ERROR_RESIDENCE_ADDRESS_SHORT = "Минимальная длина 10 символов",
  ERROR_RESIDENCE_ADDRESS_LONG = "Максимальная длина 500 символов",

  LABEL_GENDER = "Пол",
  ERROR_GENDER_REQUIRED = "",
  ERROR_GENDER_INCORRECT = "",

  LABEL_WORKPLACE = "Место работы",
  ERROR_WORKPLACE_ID_REQUIRED = "",
  ERROR_WORKPLACE_ID_INCORRECT = "",

  LABEL_CITIZENSHIP = "Гражданство",
  ERROR_CITIZENSHIP_ID_REQUIRED = "",
  ERROR_CITIZENSHIP_ID_INCORRECT = "",

  LABEL_BIRTHDATE = "Дата рождения",
  ERROR_BIRTHDATE_REQUIRED = "",
  ERROR_BIRTHDATE_INCORRECT = "Неверная дата рождения",

  TIME_CODE_ENTER = "Вход по временному коду",
  ENTER_CODE = "Введите код",
}

export enum FileErrorMessages {
  ERROR_BAD_TYPE_FILE = "Неверный тип файла",
}

export enum ErrorPageMessages {
  PAGE_404_TITLE = "Страница не найдена",
  PAGE_404_DETAIL = "Может быть, вы ошиблись в адресе или эта страница была удалена по каким-то причинам. Но рекомендуем не расстраиваться, а попробовать найти интересующий вас курс.",
  PAGE_403_TITLE = "У вас нет прав для просмотра данной страницы",
  PAGE_403_DETAIL = "Возможно, произошла ошибка, рекомендуем не расстраиваться, а попробовать найти интересующий вас курс.",
  PAGE_500_TITLE = "Ошибка сервера",
  PAGE_500_DETAIL = "Что-то пошло не так, обновите страницу или зайдите позже",
  PAGE_503_TITLE = "Сервер временно недоступен",
  PAGE_503_DETAIL = "Что-то пошло не так, обновите страницу или зайдите позже",
}

export const DynamicMessages = {
  nameInvalidMaxCount: (maxCount: number) => {
    return `Максимальная длина ${maxCount} символов`;
  },
  ERROR_BAD_SIZE_FILE: (fileSize = 0) =>
    "Размер файла не должен превышать " + normalizeFileSize(fileSize),
};
