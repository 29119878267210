import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawerOpened: false,
};

const mainMenuSlice = createSlice({
  name: "mainMenu",
  initialState,
  reducers: {
    changeDrawerOpened(state, action) {
      state.drawerOpened = action.payload;
    },
  },
});

export const { changeDrawerOpened } = mainMenuSlice.actions;

export default mainMenuSlice.reducer;
