import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IRatingRequest,
  IRatingResponse,
  IRatingTopRequest,
  IRatingTopResponse,
  PeriodHeaders,
  RatingState,
} from "./interfaces";
import { getProfileRating, getRating, getRatingTop } from "../../../api";

const name = "rating";

const initialState: RatingState = {
  isLoading: false,
  paging: {
    hasMore: false,
    total: 0,
    offset: 0,
    limit: 30,
  },
  profileList: [],

  activeRating: {
    isLoading: true,
    title: "",
    profileList: [],
  },
};

const getRatingThunkAction = createAsyncThunk(
  `${name}/getRating`,
  async (params: IRatingRequest, thunkAPI) => {
    try {
      return getRating(params) as any;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getRatingTopThunkAction = createAsyncThunk(
  `${name}/getRatingTop`,
  async (params: IRatingTopRequest, thunkAPI) => {
    try {
      return getRatingTop(params) as any;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getMyRating = createAsyncThunk(
  `${name}/getMyRating`,
  async (params: IRatingRequest, thunkAPI) => {
    try {
      // Получаем позицию
      const positionResponse = (await getProfileRating({
        my_workplace: params.my_workplace ? 1 : 0,
        period: params.period,
        club_id: params.club_id,
      })) as any;

      const myPosition = positionResponse.data.position;
      const levelName = positionResponse.data.level_name;
      const limit = (params.limit || 30) + myPosition;

      if (params.isFetchAllRatings) {
        const response = (await getRating({ ...params, limit })) as any;
        return { ...response, myPosition, levelName };
      }

      return { myPosition, levelName };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const ratingSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearRating(state) {
      state.profileList = [];
    },
    clearActiveRating(state) {
      state.activeRating.isLoading = false;
      state.activeRating.title = "";
      state.activeRating.profileList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRatingThunkAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRatingThunkAction.fulfilled, (state, action: PayloadAction<IRatingResponse>) => {
        state.isLoading = false;
        const paging = action.payload.paging;

        state.profileList =
          paging.offset > 0 ? [...state.profileList, ...action.payload.data] : action.payload.data;

        state.paging = {
          total: paging.total,
          limit: paging.limit,
          offset: paging.offset + paging.limit,
          hasMore: state.profileList?.length < paging.total,
        };
      })
      .addCase(getRatingThunkAction.rejected, (state) => {
        state.isLoading = false;
        state.profileList = [];
      });
    builder
      .addCase(getRatingTopThunkAction.pending, (state) => {
        state.activeRating.isLoading = true;
      })
      .addCase(
        getRatingTopThunkAction.fulfilled,
        (state, action: PayloadAction<IRatingTopResponse>) => {
          state.activeRating.isLoading = false;
          state.activeRating.title = "";
          state.activeRating.profileList = [];

          if (action.payload?.data)
            for (const key in PeriodHeaders) {
              // @ts-ignore
              const list = action.payload.data[key];
              if (list?.length > 0) {
                state.activeRating.title = PeriodHeaders[key as keyof typeof PeriodHeaders];
                state.activeRating.profileList = list;
                break;
              }
            }
        }
      )
      .addCase(getRatingTopThunkAction.rejected, (state) => {
        state.activeRating.isLoading = false;
        state.activeRating.title = "";
        state.activeRating.profileList = [];
      });
    builder
      .addCase(getMyRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getMyRating.fulfilled,
        (
          state,
          action: PayloadAction<IRatingResponse & { myPosition: number; levelName: string }>
        ) => {
          state.profileList = [];
          state.isLoading = false;
          const paging = action.payload?.paging;

          state.myPosition = action.payload.myPosition;
          state.levelName = action.payload.levelName;
          state.profileList = action.payload.data;

          state.paging = {
            ...state.paging,
            total: paging?.total || 0,
            offset: paging?.limit > paging?.total ? paging?.total || 0 : paging?.limit || 0,
            hasMore: state.profileList?.length < (paging?.total || 0),
          };
        }
      )
      .addCase(getMyRating.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearActiveRating, clearRating } = ratingSlice.actions;

export { getRatingThunkAction, getRatingTopThunkAction, getMyRating };

const ratingReducer = ratingSlice.reducer;
export { ratingReducer };
