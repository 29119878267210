import { SVGProps } from "react";

export const CapybaraWithPhoneSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={217}
    height={273}
    viewBox="0 0 217 273"
    fill="none"
    {...props}
  >
    <path
      fill="#F5F7F8"
      d="M100.5 75.192c-1.826 39.711 8.23 68.463 17.057 75.048 11.337 8.46 21.948 8.39 21.948 8.39.554 0 .98-.036 1.265-.036 0 0 10.874-.495 21.563-7.639 20.448-13.665 20.59-68.457 20.611-75.048.014-4.227-.206-10.4-4.264-15.576-4.727-6.018-12.04-7.342-18.01-8.503-19.879-3.852-59.104-13.97-59.104-13.97-2.26-.94-4.513-1.875-6.773-2.817-1.159-.481-2.445-1.076-2.36-2.683.043-.765.398-1.487.939-2.032 3.958-4.022 9.225-6.613 12.885-10.896 3.411-4 4.975-10.436 1.45-14.33-7.903 4.651-15.8 9.303-23.703 13.955-1.848 1.09-3.738 2.194-5.828 2.676-3.752.863-7.64-.376-11.492-.489a19.632 19.632 0 0 0-9.204 2.01c-1.364.674-2.793 1.537-4.3 1.31-1.28-.19-2.31-1.132-3.191-2.08-3.596-3.86-7.114-7.874-9.8-12.412C36.264 3.443 27.714.264 28.013 2.275c.277 9.09 2.686 18.634 9.061 25.148 1.429 1.466 3.07 2.952 3.433 4.963.348 1.905-.547 3.788-1.386 5.537-5.85 12.163-10.42 25.105-11.592 38.543-.576 6.613-.334 13.275-.774 19.902L15.22 111.307l9.325-3.526c.327.368.334.382.661.75l-6.432 11.987 4.57-2.676c-.384 1.756-.768 3.511-1.151 5.274A1225.412 1225.412 0 0 0 2.442 243.271"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M100.5 75.192c-1.826 39.711 8.23 68.463 17.057 75.048 11.337 8.46 21.948 8.39 21.948 8.39.554 0 .98-.036 1.265-.036 0 0 10.874-.495 21.563-7.639 20.448-13.665 20.59-68.457 20.611-75.048.014-4.227-.206-10.4-4.264-15.576-4.727-6.018-12.04-7.342-18.01-8.503-19.879-3.852-59.104-13.97-59.104-13.97-2.26-.94-4.513-1.875-6.773-2.817-1.159-.481-2.445-1.076-2.36-2.683.043-.765.398-1.487.939-2.032 3.958-4.022 9.225-6.613 12.885-10.896 3.411-4 4.975-10.436 1.45-14.33-7.903 4.651-15.8 9.303-23.703 13.955-1.848 1.09-3.738 2.194-5.828 2.676-3.752.863-7.64-.376-11.492-.489a19.632 19.632 0 0 0-9.204 2.01c-1.364.674-2.793 1.537-4.3 1.31-1.28-.19-2.31-1.132-3.191-2.08-3.596-3.86-7.114-7.874-9.8-12.412C36.264 3.443 27.714.264 28.013 2.275c.277 9.09 2.686 18.634 9.061 25.148 1.429 1.466 3.07 2.952 3.433 4.963.348 1.905-.547 3.788-1.386 5.537-5.85 12.163-10.42 25.105-11.592 38.543-.576 6.613-.334 13.275-.774 19.902L15.22 111.307l9.325-3.526c.327.368.334.382.661.75l-6.432 11.987 4.57-2.676c-.384 1.756-.768 3.511-1.151 5.274A1225.412 1225.412 0 0 0 2.442 243.271"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M148.829 82.98c-.696 14.117-1.734 34.465-2.672 43.549-.782 7.562-1.45 12.61-5.295 15.003-6.148 3.823-15.785-1.99-17.491-3.045M146.221 132.306c-.668 3.809 1.976 7.916 5.728 8.893 3.76.977 8.074-1.31 9.361-4.963M64.253 99.972c2.893 7.88 8.92 21.021 21.322 33.276 18.18 17.969 39.011 23.145 47.618 24.78M183.953 214.93c-7.114-.673-15.586-.694-19.808 5.055-2.672 3.639-2.679 8.765-.874 12.9 1.805 4.135 5.167 7.413 8.827 10.082 9.389 6.839 22.679 10.068 32.658 4.113 6.169-3.681 10.213-10.662 10.334-17.827.156-9.417-5.835-17.693-11.549-25.191-10.604-13.905-30.604-39.202-41.208-53.107"
    />
    <path
      fill="#00AEFF"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M181.316 189.76c.121-.665.242-1.38 0-2.01-.469-1.197-1.976-1.558-3.255-1.742-11.599-1.713-23.191-3.42-34.79-5.133-1.023-.149-2.16-.276-3.006.304-.704.482-1.038 1.339-1.322 2.139a403.468 403.468 0 0 0-18.522 71.989c-.305 1.905-.547 4.05.612 5.6 1.016 1.36 2.8 1.848 4.456 2.252l28.99 7.03c1.557.375 3.199.751 4.727.276 2.686-.842 3.894-3.887 4.726-6.57 5.942-19.045 16.219-67.72 17.398-74.142l-.014.007Z"
    />
    <path
      fill="#191229"
      d="M130.446 73.497c2.798-2.456 3.91-5.754 2.483-7.368-1.428-1.614-4.853-.931-7.651 1.524-2.798 2.456-3.909 5.755-2.482 7.368 1.427 1.614 4.852.932 7.65-1.524ZM169.894 75.054c1.427-1.614.316-4.913-2.482-7.368-2.798-2.456-6.224-3.139-7.651-1.525-1.427 1.614-.316 4.913 2.482 7.368 2.798 2.456 6.224 3.138 7.651 1.525ZM74.559 61.032c2.944 0 5.33-2.06 5.33-4.602 0-2.541-2.386-4.602-5.33-4.602s-5.33 2.06-5.33 4.602c0 2.542 2.386 4.602 5.33 4.602Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M61.915 49.938a6.052 6.052 0 0 1 3.937-2.259c1.528-.205 3.085.184 4.527.722 1.194.44 2.353.984 3.433 1.671 2.097 1.338 6.859 5.254 6.063 7.781-.86 2.733-8.557 4.9-12.779 1.055"
    />
    <path
      fill="#F5F7F8"
      d="M63.422 166.036c3.503 8.163 9.395 18.769 19.31 28.808 4.541 4.602 12.63 12.794 25.586 18.408 14.918 6.464 22.821 3.689 26.574 10.224 3.788 6.591 1.165 18.755-6.39 22.783-8.009 4.269-17.853 2.974-26.524.276-32.33-10.053-59.985-40.589-73.88-57.702"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M63.422 166.036c3.503 8.163 9.395 18.769 19.31 28.808 4.541 4.602 12.63 12.794 25.586 18.408 14.918 6.464 22.821 3.689 26.574 10.224 3.788 6.591 1.165 18.755-6.39 22.783-8.009 4.269-17.853 2.974-26.524.276-32.33-10.053-59.985-40.589-73.88-57.702"
    />
  </svg>
);
