import { LegacyRef, MutableRefObject, RefCallback } from "react";

export const useMergeRefs = <T = HTMLElement>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>
): RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
        return;
      }

      if (ref !== null) {
        (ref as React.MutableRefObject<T | null>).current = value;
        return;
      }
    });
  };
};
