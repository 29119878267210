import { SVGProps } from "react";

export const CapybaraWithMonitorSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={252}
    height={251}
    viewBox="0 0 252 251"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M155.955 221.89a146.13 146.13 0 0 0-3.226 22.508M66.875 178.797c-1.98-1.935-3.89-4.106-4.598-6.771-1.02-3.868.74-7.999 3.364-11.026 6.753-7.8 19.6-9.416 28.094-3.538 2.856 1.978 5.18 4.624 7.41 7.288 1.617 1.929 3.308 4.212 2.926 6.702-.194 1.236-.884 2.34-1.629 3.351a31.3 31.3 0 0 1-13.818 10.627c-4.955 1.934-10.981 2.427-15.284-.693 0 0-3.39-2.926-6.465-5.934v-.006Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M63.63 175.04c3.803-3.738 19.538-17.384 23.803-20.592"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M68.641 158.192c.771.387 6.747 5.229 7.517 5.616M68.641 158.192c-1.653-1.991-4.716-5.217-9.396-7.488-14.782-7.17-27.323 4.112-43.24-3.145-2.236-1.017-7.373-3.85-11.288-9.391-1.772-2.515-3.163-5.435-3.163-8.505 0-5.966 6.114-9.978 8.864-11.862 12.81-8.78 30.086-4.643 35.047-3.289"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M46.229 120.234c.25 1.304.526 2.658 1.353 3.701 1.654 2.09 4.767 2.121 7.435 1.971l163.603-9.303c2.525-36.592 5.055-73.183 7.58-109.775.05-.736.062-1.572-.464-2.09-.538-.53-1.528.019-2.155-.4-7.391-4.985-185.1-.655-192.179 6.771 0 0 8.225 74.537 14.833 109.125h-.006Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M59.44 109.726c.106.892.225 1.841.776 2.552 1.166 1.529 3.502 1.255 5.387.861 48.139-3.001 90.735-5.497 135.247-8.274l4.002-86.555c-19.193-6.614-157.276.069-155.629 3.426 0 0 7.022 60.578 10.21 87.99h.007ZM114.25 123.086c.764 5.416.576 11.307-3.013 14.514-2.512 2.247-5.099 1.766-12.528 3.12-4.742.861-9.973 2.671-13.706 4.125-1.153.449-.84 2.153.4 2.159 5.42.031 13.494.056 17.69-.038 9.328-.218 16.807.506 21.924 0"
    />
    <path
      fill="#F5F7F8"
      d="M166.178 173.779c-5.443 5.598-14.344 6.284-21.968 4.543-2.599-.593-5.218-1.466-7.235-3.201-1.941-1.672-3.163-4.006-4.253-6.321a106.163 106.163 0 0 1-10.104-42.794c-.131-5.997.37-12.38 3.903-17.235 5.099-7.02 15.284-8.73 23.653-6.346 8.362 2.384 30.762 15.332 37.496 20.823"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M166.178 173.779c-5.443 5.598-14.344 6.284-21.968 4.543-2.599-.593-5.218-1.466-7.235-3.201-1.941-1.672-3.163-4.006-4.253-6.321a106.163 106.163 0 0 1-10.104-42.794c-.131-5.997.37-12.38 3.903-17.235 5.099-7.02 15.284-8.73 23.653-6.346 8.362 2.384 30.762 15.332 37.496 20.823"
    />
    <path
      fill="#F5F7F8"
      d="M126.897 126.992c1.177 28.773 4.309 36.885 10.649 38.064 2.029.375 4.328-.661 6.89-3.12"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M126.897 126.992c1.177 28.773 4.309 36.885 10.649 38.064 2.029.375 4.328-.661 6.89-3.12"
    />
    <path
      fill="#191229"
      d="M136.888 120.409c-1.103 1.098-3.383.605-5.093-1.104-1.71-1.704-2.211-3.975-1.109-5.074 1.103-1.098 3.383-.605 5.093 1.105 1.71 1.703 2.211 3.975 1.109 5.073Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M143.809 107.024c2.613 7.357 6.089 20.149 5.012 36.192a90.286 90.286 0 0 1-8.144 31.824"
    />
    <path
      fill="#F5F7F8"
      d="m180.429 126.992-14.069-4.368c1.879-2.409 4.122-4.218 8.143-3.12 3.132 1.248 2.8 4.318 2.506 5.616"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m180.429 126.992-14.069-4.368c1.879-2.409 4.122-4.218 8.143-3.12 3.132 1.248 2.8 4.318 2.506 5.616"
    />
    <path
      fill="#191229"
      d="M171.058 123.872c1.903 0 3.445-1.118 3.445-2.496 0-1.379-1.542-2.496-3.445-2.496-1.903 0-3.445 1.117-3.445 2.496 0 1.378 1.542 2.496 3.445 2.496Z"
    />
    <path
      fill="#F5F7F8"
      d="M167.594 184.83a6.069 6.069 0 0 1-1.002 1.092c-1.529 1.28-3.583 1.698-5.538 2.066-13.517 2.564-28.67 4.823-40.058-2.864-4.61-3.114-8.093-7.576-11.908-11.619-3.815-4.044-8.293-7.85-13.75-8.992-5.449-1.142-10.423 2.178-12.283 7.407l.094.299c4.754 8.2 9.502 16.405 14.257 24.605 3.508 6.052 7.222 12.324 13.085 16.155 2.656 1.735 8.682 3.732 8.682 3.732l10.517 2.14c13.011 1.922 26.378 1.41 39.188-1.548"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M167.594 184.83a6.069 6.069 0 0 1-1.002 1.092c-1.529 1.28-3.583 1.698-5.538 2.066-13.517 2.564-28.67 4.823-40.058-2.864-4.61-3.114-8.093-7.576-11.908-11.619-3.815-4.044-8.293-7.85-13.75-8.992-5.449-1.142-10.423 2.178-12.283 7.407l.094.299c4.754 8.2 9.502 16.405 14.257 24.605 3.508 6.052 7.222 12.324 13.085 16.155 2.656 1.735 8.682 3.732 8.682 3.732l10.517 2.14c13.011 1.922 26.378 1.41 39.188-1.548"
    />
    <path fill="#F5F7F8" d="M167.594 184.83c2.368-3.257 1.071-8.367-.489-12.38l.489 12.38Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M167.594 184.83c2.368-3.257 1.071-8.367-.489-12.38"
    />
    <path
      fill="#F5F7F8"
      d="M254.15 250.338a924.008 924.008 0 0 1-5.569-19.831l-4.441-17.166a775.87 775.87 0 0 1-1.792-7.363c-2.411-10.09-3.056-27.356-3.144-28.099l6.076 1.342-7.692-8.025c-.032-.362-.069-.724-.1-1.092l6.545 3.07-7.103-10.608c-.551-8.873-.852-8.754-1.121-17.796-.194-6.296-.984-11.008-3.859-14.926-3.664-4.986-8.487-4.805-8.588-7.838-.087-2.577 3.395-3.026 5.638-7.488 2.242-4.461 1.961-10.371 0-11.232-2.055-.904-6.214 3.638-8.143 6.24-3.558 4.799-2.963 7.619-5.638 8.736-2.681 1.117-3.946-1.435-9.396-1.872-5.957-.48-7.579 2.322-10.924.73-3.709-1.766-5.143-6.826-5.362-7.594-1.009-3.544-.025-5.541-1.253-6.24-1.604-.911-5.168 1.448-6.891 4.368-1.609 2.733-1.334 5.504-1.252 6.24.689 6.066 6.546 9.229 7.235 9.585"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M254.15 250.338a924.008 924.008 0 0 1-5.569-19.831l-4.441-17.166a775.87 775.87 0 0 1-1.792-7.363c-2.411-10.09-3.056-27.356-3.144-28.099l6.076 1.342-7.692-8.025c-.032-.362-.069-.724-.1-1.092l6.545 3.07-7.103-10.608c-.551-8.873-.852-8.754-1.121-17.796-.194-6.296-.984-11.008-3.859-14.926-3.664-4.986-8.487-4.805-8.588-7.838-.087-2.577 3.395-3.026 5.638-7.488 2.242-4.461 1.961-10.371 0-11.232-2.055-.904-6.214 3.638-8.143 6.24-3.558 4.799-2.963 7.619-5.638 8.736-2.681 1.117-3.946-1.435-9.396-1.872-5.957-.48-7.579 2.322-10.924.73-3.709-1.766-5.143-6.826-5.362-7.594-1.009-3.544-.025-5.541-1.253-6.24-1.604-.911-5.168 1.448-6.891 4.368-1.609 2.733-1.334 5.504-1.252 6.24.689 6.066 6.546 9.229 7.235 9.585"
    />
    <path
      stroke="#00AEFF"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M94.055 62.558c5.068.124 9.265 4.056 11.914 8.355 3.364 5.466 5.068 11.925 4.83 18.327M97.82 55.843c7.454.93 14.457 5.017 18.911 11.039 4.46 6.021 6.308 13.896 4.992 21.26M103.871 46.552c7.623.699 13.699 6.49 18.679 12.286 3.17 3.694 6.208 7.576 8.268 11.981 2.068 4.406 3.928 12.642 2.976 17.416M117.689 39.245c15.992 8.424 27.75 28.866 28.564 46.869"
    />
    <path
      stroke="#00AEFF"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M111.062 87.93c1.603-14.546 7.222-28.636 16.079-40.305 3.402-4.48 7.448-8.736 12.691-10.795M122.6 86.326c.877-10.84 5.7-20.973 11.307-30.295 3.614-6.003 8.074-12.169 14.751-14.334M133.675 86.725c1.578-13.023 9.058-28.292 20.696-34.438M146.197 84.61c1.559-6.509 5.142-15.526 11.012-18.783"
    />
  </svg>
);
