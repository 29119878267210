import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const saveCourseMeetingViewInfo = ({
  id,
  isConference,
  videos,
}: {
  id: number;
  isConference: boolean;
  videos: string;
}) =>
  KY.post(
    isConference
      ? EndPoints.COURSE_CONFERENCE_VIEW_INFO(id)
      : EndPoints.COURSE_MEETING_VIEW_INFO(id),
    {
      body: JSON.stringify({ videos }),
    }
  ).json();
