import { combineReducers } from "@reduxjs/toolkit";
import { achievementReducer } from "../../../pages/Achievements/redux/achievements.slice";
import { courseMeetingReducer } from "../../../pages/Course/components/Meeting";
import { conversationReducer } from "../../../pages/CourseLanding/components/LandingConversation/redux/conversation.slice";
import modalsSlice from "../../../components/Modals/redux/modalsSlice";
import authReducer from "../../../features/auth/authSlice";
import courseCatalogSlice from "../../../features/courseCatalog/slice";
import mainMenuSlice from "../../../features/mainMenu/slice";
import uiprofileSlice from "../../../features/profile/slice";
import themeSlice from "../../../features/theme/slice";
import viewSlice from "../../../features/view/slice";
import courseQuestionarySlice from "../../../pages/Course/components/Questionary/redux/Questionary/courseQuestionarySlice";
import courseQuizeQuestionsSlice from "../../../pages/Course/components/Quiz/redux/Quze/courseQuizeQuestionsSlice";
import courseQuizeSlice from "../../../pages/Course/components/Quiz/redux/Quze/courseQuizeSlice";
import taskSlice from "../../../pages/Course/components/Task/redux/taskSlice";
import courseTrainersSlice from "../../../pages/Course/redux/course-trainer.slice";
import courseSlice from "../../../pages/Course/redux/courseSlice";
import coursesList from "../../../pages/Courses/redux/coursesListSlice";
import myCoursesSlice from "../../../pages/My/redux/myCoursesSlice";
import ordersSlice from "../../../pages/Orders/redux/orders.slice";
import profileSlice from "../../../pages/Profile/redux/profile-slice/profile.slice";
import shopSlice from "../../../pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { ratingReducer } from "../../../pages/Rating/redux/rating.slice";
import { settingsReducer } from "../../../pages/Settings";
import { mobileReducer } from "../slices";
import { avatarsApi } from "../rtk-api";
import mobileSlice from "../../../components/UpdateMobileModal/redux/mobile.slice";

export const appReducer = combineReducers({
  [avatarsApi.reducerPath]: avatarsApi.reducer,
  auth: authReducer,
  theme: themeSlice,
  view: viewSlice,
  modals: modalsSlice,
  uiprofile: uiprofileSlice,
  profile: profileSlice,
  shop: shopSlice,
  settings: settingsReducer,
  mobileSlise: mobileSlice,
  mainMenu: mainMenuSlice,
  courses: coursesList,
  courseCatalog: courseCatalogSlice,
  myCourses: myCoursesSlice,
  course: courseSlice,
  courseTrainersSlice: courseTrainersSlice,
  courseQuize: courseQuizeSlice,
  courseQuizeQuestions: courseQuizeQuestionsSlice,
  courseQuestionary: courseQuestionarySlice,
  courseTask: taskSlice,
  courseMeeting: courseMeetingReducer,
  achievement: achievementReducer,
  rating: ratingReducer,
  conversation: conversationReducer,
  orders: ordersSlice,
  mobile: mobileReducer,
});
