import { SearchParamsOption } from "ky";
import { KY } from "../ky-instance";
import { EndPoints } from "../../app/endpoints";
import { Capacitor } from "@capacitor/core";

export const getTransactions = ({ params }: { params: SearchParamsOption }) =>
  KY.get(EndPoints.POFILE_TRANSACTIONS, {
    searchParams: params ? JSON.parse(JSON.stringify(params)) : params,
    prefixUrl: Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE || "/" : "/",
  });
