import { SVGProps } from "react";

export const CapybaraWithBookSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={220}
    height={243}
    viewBox="0 0 220 243"
    fill="none"
    {...props}
  >
    <path
      fill="#F5F7F8"
      d="M134.492 17.532c7.079 13.993 33.085 69.807 33.085 69.807s19.148 62.735 3.795 72.319c-17.608 10.992-94.73 17.505-118.438-.925-13.497-10.488-1.38-68.634-1.38-68.634S76.663 36.32 90.4 20.499"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M134.492 17.532c7.079 13.993 33.085 69.807 33.085 69.807s19.148 62.735 3.795 72.319c-17.608 10.992-94.73 17.505-118.438-.925-13.497-10.488-1.38-68.634-1.38-68.634S76.663 36.32 90.4 20.499"
    />
    <path
      fill="#F5F7F8"
      d="M88.558 22.044S77.512 4.83 81.825 2.524C86.144.22 92.692 18.111 92.692 18.111l38.164-3.07s3.049-13.841 5.809-12.717c2.76 1.125-.979 17.078-.979 17.078"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M88.558 22.044S77.512 4.83 81.825 2.524C86.144.22 92.692 18.111 92.692 18.111l38.164-3.07s3.049-13.841 5.809-12.717c2.76 1.125-.979 17.078-.979 17.078"
    />
    <path
      fill="#F5F7F8"
      d="M134.492 17.532c7.079 13.993 33.085 69.807 33.085 69.807s19.148 62.735 3.795 72.319c-17.608 10.992-94.73 17.505-118.438-.925-13.497-10.488-1.38-68.634-1.38-68.634S76.663 36.32 90.4 20.499"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M134.492 17.532c7.079 13.993 33.085 69.807 33.085 69.807s19.148 62.735 3.795 72.319c-17.608 10.992-94.73 17.505-118.438-.925-13.497-10.488-1.38-68.634-1.38-68.634S76.663 36.32 90.4 20.499"
    />
    <path
      fill="#F5F7F8"
      d="M88.558 22.044S77.512 4.83 81.825 2.524C86.144.22 92.692 18.111 92.692 18.111l38.164-3.07s3.049-13.841 5.809-12.717c2.76 1.125-.979 17.078-.979 17.078"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M88.558 22.044S77.512 4.83 81.825 2.524C86.144.22 92.692 18.111 92.692 18.111l38.164-3.07s3.049-13.841 5.809-12.717c2.76 1.125-.979 17.078-.979 17.078"
    />
    <path fill="#F5F7F8" d="M51.554 90.623s69.579-26.6 116.03-3.284l-116.03 3.284Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M51.554 90.623s69.579-26.6 116.03-3.284"
    />
    <path fill="#F5F7F8" d="m110.714 83.882 1.615 60.686s-16.229 11.288-29.58-3.457" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m110.714 83.882 1.615 60.686s-16.229 11.288-29.58-3.457"
    />
    <path fill="#F5F7F8" d="M112.329 144.568s14.676 15.663 28.428-4.61l-28.428 4.61Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M112.329 144.568s14.676 15.663 28.428-4.61"
    />
    <path
      fill="#F5F7F8"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M90.152 93.615c.72-3.746-.467-7.123-2.653-7.544-2.185-.42-4.54 2.276-5.261 6.022-.721 3.745.466 7.123 2.652 7.543 2.185.421 4.541-2.275 5.262-6.02ZM136.424 98.581c2.142-.605 3.036-4.072 1.998-7.743-1.038-3.67-3.615-6.155-5.757-5.55-2.141.606-3.036 4.073-1.998 7.743 1.038 3.671 3.616 6.156 5.757 5.55Z"
    />
    <path fill="#F5F7F8" d="M78.105 47.395c5.975-1.856 11.357-2.429 15.87 0h-15.87Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M78.105 47.395c5.975-1.856 11.357-2.429 15.87 0"
    />
    <path
      fill="#191229"
      d="M87.075 53.26c2.096 0 3.795-1.081 3.795-2.415s-1.7-2.415-3.795-2.415c-2.096 0-3.795 1.081-3.795 2.415s1.7 2.415 3.795 2.415Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M82.742 51.038c1.36 1.422 3.25 2.16 5.023 1.877 2.401-.386 4.464-2.629 4.83-5.52"
    />
    <path fill="#F5F7F8" d="M144.345 46.705c-5.292-2.09-10.585-3.229-15.87 0h15.87Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M144.345 46.705c-5.292-2.09-10.585-3.229-15.87 0"
    />
    <path
      fill="#191229"
      d="M135.375 52.57c2.096 0 3.795-1.081 3.795-2.415s-1.699-2.415-3.795-2.415-3.795 1.081-3.795 2.415 1.699 2.415 3.795 2.415Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M139.646 50.417c-1.352 1.38-3.215 2.091-4.961 1.808-2.401-.386-4.464-2.629-4.83-5.52"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M2.467 197.656a404.535 404.535 0 0 1 104.825 20.928c1 .345 2.049.697 3.091.545 1.09-.159 2.022-.828 2.905-1.477a373.563 373.563 0 0 1 90.48-48.762c1.683-.628 3.553-1.408 4.236-3.071.345-.841.269-1.787.297-2.697 1.132-38.82 4.927-70.374 8.846-107.785-9.039 8.604-65.992 21.59-84.677 30.29-1.256.587-5.43 2.726-11.316 3.395-7.838.897-14.076-1.297-16.822-2.29-9.108-3.305-46.748-17.16-98.29-36.998 0 0-5.183 102.21-3.575 147.929v-.007Z"
    />
    <path
      fill="#F5F7F8"
      d="M31.44 225.891a204.97 204.97 0 0 1 3.05-57.636c1.58-8.031 3.905-16.436 9.867-22.045 5.962-5.617 16.636-6.935 21.935-.69 2.526 2.981 3.381 7.003 3.947 10.867 2.953 20.059.58 40.483-1.8 60.617-.774 6.527-1.76 13.531-6.26 18.312-4.326 4.596-11.122 6.121-17.415 5.645-14.738-1.125-23.515-12.496-24.157-13.421-9.066-12.951-4.45-27.448-3.912-29.042"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M31.44 225.891a204.97 204.97 0 0 1 3.05-57.636c1.58-8.031 3.905-16.436 9.867-22.045 5.962-5.617 16.636-6.935 21.935-.69 2.526 2.981 3.381 7.003 3.947 10.867 2.953 20.059.58 40.483-1.8 60.617-.774 6.527-1.76 13.531-6.26 18.312-4.326 4.596-11.122 6.121-17.415 5.645-14.738-1.125-23.515-12.496-24.157-13.421-9.066-12.951-4.45-27.448-3.912-29.042"
    />
    <path
      fill="#F5F7F8"
      d="M160.036 200.616c3.781-28.869 6.348-50.728 9.39-58.705 1.236-3.229 2.636-6.493 5.031-8.984 2.394-2.491 6.009-4.084 9.356-3.208 3.326.869 5.63 3.891 7.224 6.941 7.832 14.973 4.492 33.079.959 49.604-2.318 10.854-4.084 16.25-8.701 20.5-8.983 8.28-26.226 10.93-32.747 2.781-3.629-4.533-2.822-11.026-2.07-14.49"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M160.036 200.616c3.781-28.869 6.348-50.728 9.39-58.705 1.236-3.229 2.636-6.493 5.031-8.984 2.394-2.491 6.009-4.084 9.356-3.208 3.326.869 5.63 3.891 7.224 6.941 7.832 14.973 4.492 33.079.959 49.604-2.318 10.854-4.084 16.25-8.701 20.5-8.983 8.28-26.226 10.93-32.747 2.781-3.629-4.533-2.822-11.026-2.07-14.49"
    />
    <path fill="#00AEFF" d="M119.505 101.905a1042.355 1042.355 0 0 1-8.97 104.88" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M119.505 101.905a1042.355 1042.355 0 0 1-8.97 104.88"
    />
  </svg>
);
