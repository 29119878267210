import { SVGProps } from "react";
export const ArmchairSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={260}
    height={300}
    viewBox="0 0 260 300"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M46.04 155.476c-3.512 7.024-6.932 28.024 4.56 47.561 14.365 24.42 66.08 31.603 87.628 11.971 21.547-19.633 7.182-31.604 23.463-69.432 16.28-37.828 67.086-48.293 81.402-26.815 12.45 18.678-10.055 30.646-21.547 38.784-11.493 8.138-36.392 15.804-47.405 6.227-9.577-8.328-5.897-30.167 8.619-35.436 14.515-5.269 25.835 8.471 21.297 25.378-2.855 10.637-24.759 74.875-32.311 83.799-7.551 8.924-.875 55.067-10.055 55.067-11.014 0-6.225-42.138-10.535-39.265-4.309 2.873-29.688 3.591-46.447 1.915-17.384-1.738-43.446-8.343-57.422-12.293-2.345-.663-4.779.199-5.657 2.472-3.58 9.265-8.275 30.374-16.409 28.973-13.886-2.393 7.183-39.744 7.661-46.926.48-7.183-27.772-83.797-17.716-99.12 10.055-15.323 26.332-8.116 31.603-1.914 8.14 9.579 9.805 29.686-7.912 31.123-17.717 1.436-33.997-33.665 0-43.721 33.998-10.056 42.868-9.909 50.529-3.205 24.9 21.789-25.857 66.56-29.21 58.897-2.31-5.283 22.38-14.11 28.868-15.802 11.089-2.89 39.206-4.769 66.171-.595"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M85.55 100.563c8.142-22.984-15.801-39.744-9.098-61.77 6.704-22.027 26.336-28.252 46.926-29.688 20.59-1.437 75.178-4.789 107.739 16.28 22.256 14.401 5.976 45.343 0 56.983-3.674 7.156-3.601 21.879-3.601 21.879M173.657 252.356c7.182-2.873 25.378-15.322 30.645-12.928 5.268 2.394 3.352 16.279 8.141 23.462 4.788 7.183 7.661-2.873 5.267-15.802-2.394-12.928-5.267-25.857 1.915-45.011 5.746-15.322 14.913-45.318 17.946-55.693"
    />
  </svg>
);
