const Container = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}) => {
  return <div className={`container ${className ?? ""}`}>{children}</div>;
};

export default Container;
