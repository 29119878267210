import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const setCourseLessonFinish = ({
  lessonId,
  signal,
}: {
  lessonId: number;
  signal: AbortSignal;
}) =>
  KY.post(EndPoints.COURSE_LESSONS_FINISH(lessonId), {
    signal,
  }).json();
