import { createEvent, EventAttributes } from "ics";

interface CreateFileWithEventParams {
  event: EventAttributes;
  fileName: string;
}

export const createFileWithEvents = async ({
  event,
  fileName,
}: CreateFileWithEventParams): Promise<File> =>
  new Promise((resolve, reject) => {
    createEvent(event, (error, value) => {
      if (error) {
        reject(error);
      }

      resolve(new File([value], fileName, { type: "plain/text" }));
    });
  });
