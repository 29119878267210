import { useContext } from "react";
import { ModalContainerProps } from "./types";
import { ModalsContainerContext } from "./context";
import { generateUid } from "app/utils";

export const useModal = () => {
  const dispatch = useContext(ModalsContainerContext);

  const openModal = (props: ModalContainerProps) =>
    dispatch({
      type: "OPEN_MODAL",
      payload: {
        ...props,
        id: generateUid(),
      },
    });

  const closeModal = () =>
    dispatch({
      type: "CLOSE_MODAL",
      payload: {},
    });

  return { openModal, closeModal };
};
