import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const saveQuizQuestionAnswer = ({
  quizeId,
  questionId,
  answer,
  attemptId,
}: {
  quizeId: number | string;
  questionId: number | string;
  answer: any;
  attemptId: any;
}) =>
  KY.post(EndPoints.QUIZE_ANSWER(quizeId, questionId), {
    body: JSON.stringify({
      answer: answer,
      attempt_id: attemptId,
    }),
  }).json();
