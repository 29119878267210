import { SVGProps } from "react";

export const LeafIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={74}
      height={189}
      viewBox="0 0 74 189"
      fill="none"
      {...props}
    >
      <g filter="url(#a)">
        <path
          fill="#FFC422"
          d="M48.428 30.259S73.112 29.107 71.454.664c0 0-25.098 4.61-23.026 29.595Z"
        />
        <path
          fill="#E79900"
          d="M48.429 30.259 71.455.664c-1.598.182-12.786 1.88-19.179 12.25-4.617 7.46-4.084 14.98-3.847 17.345Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M40.2 47.063s16.753-18.618-4.024-37.66c0 0-14.68 21.407 4.025 37.66Z"
        />
        <path
          fill="#E79900"
          d="m40.2 47.063-4.024-37.66c-1.007 1.333-7.814 10.552-5.21 22.56 1.836 8.61 7.34 13.584 9.235 15.1Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M37.596 45.49S50.382 23.78 74 38.94c0 0-15.923 20.438-36.404 6.55Z"
        />
        <path
          fill="#E79900"
          d="M37.596 45.491 74 38.941c-.947 1.335-7.695 10.613-19.712 11.402-8.524.606-14.739-3.457-16.692-4.852Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M30.797 75.505s10.24-23.045-15.331-34.446c.059 0-7.4 24.985 15.33 34.446Z"
        />
        <path
          fill="#E79900"
          d="M30.797 75.505 15.525 41.059c-.533 1.576-4.203 12.553 1.954 23.105 4.38 7.52 11.128 10.492 13.318 11.34Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M27.896 74.838s5.505-24.682 32.557-17.89c0 0-8.879 24.5-32.556 17.89Z"
        />
        <path
          fill="#E79900"
          d="m27.896 74.836 32.557-17.89c-.474 1.576-4.084 12.553-15.213 17.162-7.932 3.275-15.094 1.395-17.344.728Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M30.426 110.072S27.643 84.905 0 88.543c0 .06 6.156 25.411 30.426 21.529Z"
        />
        <path
          fill="#E79900"
          d="M30.426 110.07 0 88.602c.296 1.637 2.723 12.978 13.26 18.8 7.517 4.123 14.798 3.092 17.166 2.668Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M28.176 109.826S20.54 85.75 47.177 77.32c-.059 0 4.617 25.714-19 32.506Z"
        />
        <path
          fill="#E79900"
          d="m28.176 109.826 19-32.506c.356 1.638 2.783 12.918-4.498 22.742-5.15 7.035-12.253 9.158-14.502 9.764Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M43.1 144.816s-7.694-24.077-34.036-14.798c.06 0 11.07 23.591 34.037 14.798Z"
        />
        <path
          fill="#E79900"
          d="M43.1 144.817 9.065 130.02c.652 1.516 5.21 12.129 16.693 15.707 8.228 2.486 15.154 0 17.344-.91Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M40.852 145.001s-12.253-22.014 12.194-35.72c0 0 9.59 24.258-12.194 35.72Z"
        />
        <path
          fill="#E79900"
          d="m40.852 145.001 12.194-35.72c.71 1.516 5.268 12.069.059 23.227-3.611 7.884-10.122 11.462-12.253 12.493Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M64.174 170.175s-15.864-19.467-36.937-.849c0 0 18.765 17.708 36.937.849Z"
        />
        <path
          fill="#E79900"
          d="m64.174 170.173-36.937-.849c1.125 1.213 9.175 9.279 21.192 8.248 8.523-.788 14.029-5.761 15.745-7.399Z"
          opacity={0.8}
        />
        <path
          fill="#FFC422"
          d="M62.161 171.206s-19.297-15.829-1.598-37.843c0 0 17.699 18.861 1.598 37.843Z"
        />
        <path
          fill="#E79900"
          d="m62.16 171.206-1.597-37.843c1.183 1.153 9.293 9.218 8.464 21.529-.532 8.733-5.268 14.555-6.866 16.314Z"
          opacity={0.8}
        />
        <path
          stroke="#FFC422"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5.236}
          d="M68.704 175.691c-24.94-18.347-41.18-48.283-41.18-82.098 0-24.371 8.435-46.728 22.484-64.183"
        />
      </g>
      <defs>
        <filter
          id="a"
          width={74}
          height={188.116}
          x={0}
          y={0.664}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10.472} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.106838 0 0 0 0 0.0291099 0 0 0 0 0.143415 0 0 0 0.33 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_19167_23570" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_19167_23570" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
