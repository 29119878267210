import {
  CheckedTestChunk,
  CompletedTestChunk,
  TestNotStartedChunk,
} from "../../../../components/Buttons/InfoChunks";

const CourseLessonStatus = ({ lessonType, status }: { lessonType?: string; status: string }) => {
  if (lessonType === "atb") {
    return <CompletedTestChunk value={"Просмотрено"} />;
  }

  const lessonStatus = (status: string) => {
    switch (status) {
      case "not_started":
        return <TestNotStartedChunk value={"Не начат"} />;
      case "in_progress":
        return <CheckedTestChunk value={"Просмотрено"} />;
      case "completed":
        return <CompletedTestChunk value={"Завершено"} />;
      default:
        return null;
    }
  };
  return <>{status && lessonStatus(status)}</>;
};

export default CourseLessonStatus;
