import { SVGProps } from "react";

export const CapybaraWithPenSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={259}
    height={201}
    viewBox="0 0 259 201"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M140.671 109.9c-5.391 10.96-5.983 15.496-5.983 15.496s4.798-4.863 5.983-4.172c1.711.995-2.992 14.304-2.992 14.304s2.447-.989 6.582-5.96"
    />
    <path
      fill="#F5F7F8"
      d="M257.356 152.061c-.233-10.806-.096-21.677-1.568-32.369-.083-5.012-1.89-11.193-4.463-17.892v.036c-2.644-6.83-6.413-13.154-11.643-18.255-4.385-4.28-9.632-7.564-14.598-11.164-.975-.709-1.932-1.43-2.884-2.163l6.426-.882-11.272-2.992-.012-.012 9.489-1.168-16.914-5.257c-3.177-2.86-6.306-5.775-9.423-8.695-7.909-7.397-15.914-14.912-21.556-24.138l-2.974-3.773c-4.966-5.43-9.925-10.86-14.891-16.289-1.568-1.71-3.201-3.475-5.349-4.369-2.148-.894-4.954-.65-6.372 1.18-1.651 2.134-.754 5.257.652 7.558 1.406 2.3 3.297 4.482 3.56 7.164.102 1.019-.107 2.157-.903 2.807-.736.596-1.759.626-2.71.632a326.195 326.195 0 0 0-92.897 14.09c-4.655 1.418-9.884 3.516-11.386 8.129 0 0 8.514 49.718 22.484 71.096 2.387 3.654 5.791 5.901 5.791 5.901s6.917 5.328 17.07 6.544c18.128 2.175 38.991-15.854 45.47-21.456 7.748-6.693 18.936-17.964 28.718-35.164"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M257.356 152.061c-.233-10.806-.096-21.677-1.568-32.369-.083-5.012-1.89-11.193-4.463-17.892v.036c-2.644-6.83-6.413-13.154-11.643-18.255-4.385-4.28-9.632-7.564-14.598-11.164-.975-.709-1.932-1.43-2.884-2.163l6.426-.882-11.272-2.992-.012-.012 9.489-1.168-16.914-5.257c-3.177-2.86-6.306-5.775-9.423-8.695-7.909-7.397-15.914-14.912-21.556-24.138l-2.974-3.773c-4.966-5.43-9.925-10.86-14.891-16.289-1.568-1.71-3.201-3.475-5.349-4.369-2.148-.894-4.954-.65-6.372 1.18-1.651 2.134-.754 5.257.652 7.558 1.406 2.3 3.297 4.482 3.56 7.164.102 1.019-.107 2.157-.903 2.807-.736.596-1.759.626-2.71.632a326.195 326.195 0 0 0-92.897 14.09c-4.655 1.418-9.884 3.516-11.386 8.129 0 0 8.514 49.718 22.484 71.096 2.387 3.654 5.791 5.901 5.791 5.901s6.917 5.328 17.07 6.544c18.128 2.175 38.991-15.854 45.47-21.456 7.748-6.693 18.936-17.964 28.718-35.164"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M138.876 22.288c-2.656-2.134-7.419-6.443-8.573-11.348-.228-.971-.832-3.278.335-4.106.723-.513 1.705-.43 2.584-.28 9.471 1.573 17.901 9.565 17.901 9.565.904.865 1.801 1.729 2.704 2.593M38.34 85.905c-3.8 4.303-3.92 11.377-.27 15.806 1.143 1.382 2.704 2.557 4.493 2.694 2.609.202 4.876-1.806 6.33-3.982 3.32-4.964.365-8.54 3.087-18.434.371-1.347.766-2.509.736-4.28-.012-.875-.197-5.786-4.188-8.94-2.393-1.894-4.96-2.21-6.043-2.33a12.916 12.916 0 0 0-5.325.543h-.005C23.931 86.09 14.91 101.699 9.524 124.28c-1.753 7.349-2.991 15.383.252 22.213 3.344 7.039 10.847 11.277 18.421 13.214 11.828 3.021 24.41 1.436 36.149-1.926 11.732-3.361 22.89-8.451 34.366-12.593 2.094-.757 4.529-1.46 6.455-.346 1.311.757 2.053 2.188 2.782 3.511 1.64 2.974 6.755 9.363 23.555 22.195"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M40.756 103.94c-.718 4.524-1.825 14.071-1.442 18.637.275 3.272 1.155 6.997 4.093 8.487 2.112 1.073 4.66.626 6.946-.018 7.084-1.985 16.064-6.079 21.514-11.014M82.098 163.832c3.494-.477 7.102-.942 10.506-.024 3.8 1.025 6.922 3.659 9.902 6.216 8.657 7.426 17.321 14.853 25.978 22.285 0 0-55.731 7.098-86.088 6.431-1.825-.042-3.674-.096-5.421-.626-2.178-.668-4.062-2.039-5.9-3.385-9.135-6.705-18.271-13.41-27.4-20.115 0 0 53.008-7.325 78.423-10.782Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M21.88 176.98c4.368 3.874 21.7 14.459 26.654 17.552M32.087 175.287c5.69 2.891 18.996 13.207 24.225 16.861M45.213 174.596c4.368 3.874 21.7 14.459 26.654 17.552M67.35 172.212c4.368 3.874 21.7 14.459 26.654 17.552M55.42 172.903c5.69 2.891 18.996 13.208 24.225 16.861M87.931 181.092a112.182 112.182 0 0 1 13.354 8.893M63.473 63.513c4.787 12.021 9.579 24.06 13.3 36.458.922 3.063 1.777 6.311 1.041 9.428-.735 3.117-3.643 5.954-6.82 5.495M75.54 91.99c1.526 5.895 5.637 11.545 11.56 13.047 5.923 1.502 13.174-2.754 13.204-8.845M97.905 40.263c4.224 7.128 12.815 23.888 12.056 46.387-.503 14.864-4.888 26.462-8.179 33.382M146.654 38.38c-4.14-3.773-9.399-4.941-13.162-2.98-.832.435-2.31 1.4-3.518 3.492-.174 1.43-.09 2.515.281 3.47.197.506.873 2.347 2.638 3.17 2.633 1.228 5.69-.972 5.983-1.192 1.041-.78 1.735-1.764 2.196-2.778"
    />
    <path
      fill="#191229"
      d="M133.791 45.532c2.478 0 4.487-1.734 4.487-3.874s-2.009-3.874-4.487-3.874c-2.479 0-4.488 1.735-4.488 3.874 0 2.14 2.009 3.874 4.488 3.874Z"
    />
    <path
      fill="#00AEFF"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m154.641 125.426-53.726 53.521-9.304 3.665 3.38-9.566 53.727-53.521c1.627-1.621 4.296-1.621 5.923 0a4.175 4.175 0 0 1 0 5.901Z"
    />
    <path
      fill="#F5F7F8"
      d="M193.704 105.418c-.443 4.839-.892 9.673-1.335 14.513-.305 3.313-.652 6.752-2.351 9.619-2.058 3.457-5.779 5.561-9.459 7.206-11.637 5.215-24.35 7.462-36.915 9.667l-33.223 5.829c-.76.131-1.4.631-1.723 1.329l-.682 1.49c-.131.286-.203.59-.215.9-.228 7.426 1.956 14.906 6.15 21.044 1.747 2.557 3.991 4.989 6.952 5.925 3.345 1.049 6.953.018 10.303-1.014 14.353-4.41 28.706-8.826 43.065-13.237a4363.006 4363.006 0 0 0 24.291-7.521c12.959-4.071 27.037-9.185 33.725-20.967"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M193.704 105.418c-.443 4.839-.892 9.673-1.335 14.513-.305 3.313-.652 6.752-2.351 9.619-2.058 3.457-5.779 5.561-9.459 7.206-11.637 5.215-24.35 7.462-36.915 9.667l-33.223 5.829c-.76.131-1.4.631-1.723 1.329l-.682 1.49c-.131.286-.203.59-.215.9-.228 7.426 1.956 14.906 6.15 21.044 1.747 2.557 3.991 4.989 6.952 5.925 3.345 1.049 6.953.018 10.303-1.014 14.353-4.41 28.706-8.826 43.065-13.237a4363.006 4363.006 0 0 0 24.291-7.521c12.959-4.071 27.037-9.185 33.725-20.967"
    />
    <path
      fill="#191229"
      d="M80.08 48.713c.972-1.33-.167-3.807-2.544-5.531-2.377-1.725-5.093-2.044-6.065-.714-.973 1.33.166 3.807 2.543 5.532 2.378 1.724 5.093 2.044 6.066.713ZM53.587 54.13c.184-2.924-1.003-5.378-2.652-5.481-1.648-.103-3.135 2.183-3.32 5.107-.184 2.924 1.003 5.378 2.652 5.48 1.649.104 3.135-2.182 3.32-5.106Z"
    />
  </svg>
);
