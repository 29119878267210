import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { WebAppRoutes } from "../app/routes";
import { selectAuthOIDC } from "../features/auth/selectors";
import { useMediaQuery } from "../hooks";

const NeedAuth = ({ children }) => {
  const authState = useSelector(selectAuthOIDC);
  const location = useLocation();
  const oidc = localStorage?.oidc ? JSON.parse(localStorage?.oidc) : {};
  const mob = useMediaQuery("(max-width: 769px)");

  if (oidc?.idToken && authState?.shared) {
    if (authState?.course_id) {
      return <Navigate to={WebAppRoutes.COURSE + "/" + authState.course_id} replace={true} />;
    } else {
      return <Navigate to={WebAppRoutes.AUTH} state={{ from: location }} replace={true} />;
    }
  }

  if (!oidc?.idToken) {
    return <Navigate to={WebAppRoutes.AUTH} state={{ from: location }} replace={true} />;
  }
  return (
    <>
      {children}
      {!mob && process.env.REACT_APP_THEME === "base" && (
        <div
          id="dynamicMessenger"
          style={{ position: "fixed", top: "90%", right: "20px", zIndex: 9999 }}
        />
      )}
    </>
  );
};

export default NeedAuth;
