import { SVGProps } from "react";

export const DoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="12"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 6.5L10.5 10.5L20 1M1.5 6.5L5.5 10.5M10.5 5.5L15 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
