import { createAsyncThunk } from "@reduxjs/toolkit";
import { ViewedInfoRequest } from "../interfaces";
import { getMeetingViewedInfoById, saveCourseMeetingViewInfo } from "../../../../../../api";

export const getMeetingViewedInfo = createAsyncThunk(
  "course-meeting/getMeetingViewedInfo",
  async ({ id, isConference }: { id: number; isConference?: boolean }) =>
    (await getMeetingViewedInfoById(id, !!isConference)) as any
);

export const postMeetingViewedInfo = createAsyncThunk(
  "course-meeting/postMeetingViewedInfo",
  async (info: ViewedInfoRequest & { meetingId: number; isConference?: boolean }) => {
    const { meetingId, isConference, ...request } = info;
    return saveCourseMeetingViewInfo({
      id: meetingId,
      isConference: !!isConference,
      videos: request.videos || "",
    });
  }
);
