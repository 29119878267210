import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { changeIsAppActiveAction, changeIsHasInternetAction } from "./actions";

const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {
    changeIsHasInternet: changeIsHasInternetAction,
    changeIsAppActive: changeIsAppActiveAction,
  },
});

const mobileReducer = mobileSlice.reducer;

export const { changeIsHasInternet, changeIsAppActive } = mobileSlice.actions;

export { mobileReducer };
