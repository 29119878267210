import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { KY } from "../../api/ky-instance";
import { Options, SearchParamsOption } from "ky";

interface KyBaseQueryArgs {
  url: string;
  method: string;
  body?: unknown;
  params?: SearchParamsOption;
  headers?: { [key: string]: string };
}

export const KyBaseQuery: BaseQueryFn<KyBaseQueryArgs, unknown, unknown> = async ({
  url,
  method,
  body,
  params,
  headers = {},
}) => {
  const kyOptions: Options = {
    method,
    body: body instanceof FormData ? body : JSON.stringify(body),
    searchParams: params,
    headers: body instanceof FormData ? undefined : headers,
  };

  const result = await KY(url, kyOptions);
  return await result.json();
};
