export const iconTheme = {
  base: {
    arrow: {
      color1: "#FFC422",
      color2: "#191229",
    },
    authBg: {
      color1: "#4D3BC2",
      color2: "#7D6BF2",
      color3: "url(#paint0_linear_5841_48641)",
      color4: "#7D6BF2",
      color5: "url(#paint1_linear_5841_48641)",
      color6: "#D9A2F3",
      color7: "paint0_linear_5841_48641",
      color8: "#7D6BF2",
      color9: "#C861F9",
      color10: "paint1_linear_5841_48641",
      color11: "#7D6BF2",
      color12: "#C861F9",
      color13: "#fff",
    },
    catWoman: {
      color1: "#FFCB3D",
      color2: "#43375D",
      color3: "#C861F9",
      color4: "#43375D",
      color5: "#FFCB3D",
      color6: "#43375D",
      color7: "#7D6BF2",
      color8: "#F2A98A",
      color9: "#43375D",
      color10: "#43375D",
      color11: "#43375D",
      color12: "#43375D",
      color13: "#43375D",
      color14: "#FFCB3D",
      color15: "#43375D",
      color16: "#FFCB3D",
      color17: "#43375D",
    },
    womanFirst: {
      color1: "#C861F9",
      color2: "#43375D",
      color3: "#8C56DB",
      color4: "#FFCB3D",
      color5: "#F2A98A",
      color6: "#43375D",
      color7: "#43375D",
      color8: "#43375D",
      color9: "#F2A98A",
      color10: "#43375D",
      color11: "#43375D",
      color12: "#43375D",
    },
    womanSecond: {
      color1: "#C861F9",
      color2: "#43375D",
      color3: "#FFCB3D",
      color4: "#43375D",
      color5: "#F2A98A",
      color6: "#43375D",
      color7: "#43375D",
      color8: "#F2A98A",
      color9: "#43375D",
      color10: "#43375D",
      color11: "#43375D",
    },
    lampPlant: {
      color1: "#fff",
      color2: "#7D6BF2",
      color3: "#43375D",
      color4: "#fff",
      color5: "#7D6BF2",
      color6: "#43375D",
      color7: "#fff",
      color8: "#7D6BF2",
      color9: "#43375D",
      color10: "#fff",
      color11: "#7D6BF2",
      color12: "#43375D",
      color13: "#fff",
      color14: "#7D6BF2",
      color15: "#43375D",
      color16: "#fff",
      color17: "#7D6BF2",
      color18: "#43375D",
      color19: "#fff",
      color20: "#7D6BF2",
      color21: "#43375D",
      color22: "#fff",
      color23: "#7D6BF2",
      color24: "#43375D",
    },
    armChair: {
      color1: "#DAD5F1",
      color2: "#CFCAEC",
      color3: "#E5E2F8",
    },
    questionMark: {
      color1: "#C861F9",
    },
    playSite: {
      color1: "#DAD5F1",
      color2: "#E5E2F8",
      color3: "#CFCAEC",
    },
    sites: {
      color1: "#fff",
      color2: "#7D6BF2",
      color3: "#43375D",
      color4: "#fff",
      color5: "#fff",
      color6: "#fff",
      color7: "#7D6BF2",
      color8: "#43375D",
      color9: "#7D6BF2",
      color10: "#fff",
      color11: "#7D6BF2",
      color12: "#43375D",
      color13: "#fff",
      color14: "#fff",
      color15: "#7D6BF2",
      color16: "#43375D",
      color17: "#fff",
      color18: "#7D6BF2",
      color19: "#43375D",
      color20: "#7D6BF2",
    },
    passwordAuth: {
      color1: "#C861F9",
    },
    heartAuth: {
      color1: "#C861F9",
    },
    alertAuth: {
      color1: "#C861F9",
    },
    lockAuth: {
      color1: "#CFCAEC",
      color2: "#CFCAEC",
      color3: "#F0EEFF",
      color4: "#966D00",
      color5: "#FAB600",
      color6: "#8C56DB",
      color7: "#C861F9",
      color8: "#8C56DB",
      color9: "#8C56DB",
    },
    agreementBoard: {
      color1: "#C861F9",
    },
    achieveProfile: {
      color1: "url(#paint0_linear_8517_103046)",
      color2: "#fff",
      color3: "paint0_linear_8517_103046",
      color4: "#7D6BF2",
      color5: "#C861F9",
    },
    calendarPic: {
      color1: "#43375D",
      color2: "#fff",
      color3: "#7D6BF2",
    },
    courseTestPic: {
      color1: "rgba(67, 55, 93, 0.08)",
      color2: "#CFCAEC",
      color3: "#E5E2F8",
      color4: "#DAD5F1",
      color5: "#C861F9",
    },
    courseWebPic: {
      color1: "rgba(67, 55, 93, 0.08)",
      color2: "#CFCAEC",
      color3: "#E5E2F8",
      color4: "#DAD5F1",
      color5: "#C861F9",
    },
    courseSimulatePic: {
      color1: "rgba(67, 55, 93, 0.08)",
      color2: "#E5E2F8",
      color3: "#CFCAEC",
      color4: "#C861F9",
      color5: "#BAB4DB",
      color6: "#DAD5F1",
    },
    questionIcon: {
      color1: "#7D6BF2",
    },
    settingsSuccessIcon: {
      color1: "#56D6A9",
    },
    bookPrestartPic: {
      color1: "rgba(67, 55, 93, 0.08)",
      color2: "#CFCAEC",
      color3: "#E5E2F8",
      color4: "#DAD5F1",
      color5: "#C861F9",
    },
    chartGreenIcon: {
      color1: "#56D6A9",
    },
    alertIcon: {
      color1: "#7D6BF2",
    },
    quotesIcon: {
      color1: "#F0EEFF",
    },
    courseSheetStartPic: {
      color1: "#E5E2F8",
      color2: "#F5F3FE",
      color3: "#CFCAEC",
      color4: "#F2F0FE",
      color5: "#DAD5F1",
      color6: "#C861F9",
    },
    courseDownloadPic: {
      color1: "#E5E1FC",
      color2: "#D6D0F3",
      color3: "#C4BDE4",
      color4: "#B7AFE4",
      color5: "#F5F3FE",
    },
    plant: {
      color1: "#DAD5F1",
      color2: "#CFCAEC",
      color3: "#E5E2F8",
    },
    lamp: {
      color1: "#CFCAEC",
      color2: "#DAD5F1",
      color3: "#CFCAEC",
      color4: "#E5E2F8",
      color5: "#DAD5F1",
    },
    plant2: {
      color1: "#E5E2F8",
      color2: "#DAD5F1",
    },
    rocket: {
      color1: "url(#paint0_linear_8532_104077)",
      color2: "#8C56DB",
      color3: "#FFCB3D",
      color4: "#EC7260",
      color5: "#C861F9",
      color6: "#4D3BC2",
      color7: "#CFC3F2",
      color8: "#7D6BF2",
      color9: "#fff",
      color10: "#7D6BF2",
      color11: "#AB7CD9",
      color12: "paint0_linear_8532_104077",
    },
    taskPic: {
      color1: "#DEDDE8",
      color2: "#CFCAEC",
      color3: "#F5F3FE",
      color4: "#E5E2F8",
      color5: "#DAD5F1",
      color6: "#C861F9",
    },
    testResultDownloadIcon: {
      color1: "#7D6BF2",
    },
    forumMessagePic: {
      color1: "#F5F3FE",
      color2: "#E5E2F8",
      color3: "#CFCAEC",
    },
    testLeafPic: {
      color1: "url(#filter0_d_7577_135420)",
      color2: "#FFCB3D",
      color3: "#4D3BC2",
      color4: "#FFCB3D",
      color5: "#4D3BC2",
      color6: "#FFCB3D",
      color7: "#4D3BC2",
      color8: "#FFCB3D",
      color9: "#FFCB3D",
    },
    clockTestPic: {
      color1: "#E5E1FC",
      color2: "#C4D1E0",
      color3: "#C4BDE4",
      color4: "#DAD5F1",
    },
    certificatePic: {
      color1: "#F0EEFF",
      color2: "#C2BAFA",
      color3: "#7D6BF2",
      color4: "#C861F9",
    },
    resultChart: {
      color1: "#7D6BF2",
      color2: "#CFD3DE",
      color3: "#56D6A9",
      color4: "##E5E1FF",
    },
    studyPlug: {
      color1: "#f0f0f2",
      color2: "#dbd8df",
      color3: "#ccc9d2",
      color4: "#bebbc6",
    },
    profileWallet: {
      color1: "#fff",
      color2: "#E5B0FF",
      color3: "#FFC422",
    },
  },
  fss: {
    arrow: {
      color1: "#FFC422",
      color2: "#191229",
    },
    authBg: {
      color1: "#29476B",
      color2: "#4377B5",
      color3: "url(#paint0_linear_5841_48641)",
      color4: "#4377B5",
      color5: "url(#paint1_linear_5841_48641)",
      color6: "#D6EBFF",
      color7: "paint0_linear_5841_48641",
      color8: "#375880",
      color9: "#4DACEE",
      color10: "paint1_linear_5841_48641",
      color11: "#375880",
      color12: "#4DACEE",
      color13: "#fff",
    },
    catWoman: {
      color1: "#FAB600",
      color2: "#000",
      color3: "#2EA9FF",
      color4: "#000",
      color5: "#FAB600",
      color6: "#000",
      color7: "#4377B5",
      color8: "#F2A98A",
      color9: "#000",
      color10: "#000",
      color11: "#000",
      color12: "#000",
      color13: "#000",
      color14: "#FAB600",
      color15: "#000",
      color16: "#FAB600",
      color17: "#000",
    },
    womanFirst: {
      color1: "#2EA9FF",
      color2: "#000",
      color3: "#0062A7",
      color4: "#FAB600",
      color5: "#F2A98A",
      color6: "#000",
      color7: "#000",
      color8: "#000",
      color9: "#F2A98A",
      color10: "#000",
      color11: "#000",
      color12: "#000",
    },
    womanSecond: {
      color1: "#2EA9FF",
      color2: "#000",
      color3: "#FAB600",
      color4: "#000",
      color5: "#F2A98A",
      color6: "#000",
      color7: "#000",
      color8: "#F2A98A",
      color9: "#000",
      color10: "#000",
      color11: "#000",
    },
    lampPlant: {
      color1: "#fff",
      color2: "#4377B5",
      color3: "#000",
      color4: "#fff",
      color5: "#4377B5",
      color6: "#000",
      color7: "#fff",
      color8: "#4377B5",
      color9: "#000",
      color10: "#fff",
      color11: "#4377B5",
      color12: "#000",
      color13: "#fff",
      color14: "#4377B5",
      color15: "#000",
      color16: "#fff",
      color17: "#4377B5",
      color18: "#000",
      color19: "#fff",
      color20: "#4377B5",
      color21: "#000",
      color22: "#fff",
      color23: "#4377B5",
      color24: "#000",
    },
    armChair: {
      color1: "#CAD4DF",
      color2: "#BBC7D5",
      color3: "#DAE2EC",
    },
    questionMark: {
      color1: "#2EA9FF",
    },
    playSite: {
      color1: "#CAD4DF",
      color2: "#DAE2EC",
      color3: "#BBC7D5",
    },
    sites: {
      color1: "#fff",
      color2: "#4377B5",
      color3: "#000",
      color4: "#fff",
      color5: "#fff",
      color6: "#fff",
      color7: "#4377B5",
      color8: "#000",
      color9: "#4377B5",
      color10: "#fff",
      color11: "#4377B5",
      color12: "#000",
      color13: "#fff",
      color14: "#fff",
      color15: "#4377B5",
      color16: "#000",
      color17: "#fff",
      color18: "#4377B5",
      color19: "#000",
      color20: "#4377B5",
    },
    passwordAuth: {
      color1: "#2EA9FF",
    },
    heartAuth: {
      color1: "#2EA9FF",
    },
    alertAuth: {
      color1: "#2EA9FF",
    },
    lockAuth: {
      color1: "#BBC7D5",
      color2: "#BBC7D5",
      color3: "#F3F7FA",
      color4: "#966D00",
      color5: "#FAB600",
      color6: "#0062A7",
      color7: "#2EA9FF",
      color8: "#0062A7",
      color9: "#0062A7",
    },
    agreementBoard: {
      color1: "#2EA9FF",
    },
    achieveProfile: {
      color1: "url(#paint0_linear_8517_103040)",
      color2: "#fff",
      color3: "paint0_linear_8517_103040",
      color4: "#375880",
      color5: "#4DACEE",
    },
    calendarPic: {
      color1: "#000",
      color2: "#fff",
      color3: "#4377B5",
    },
    courseTestPic: {
      color1: "#F0F4F9",
      color2: "#BBC7D5",
      color3: "#DAE2EC",
      color4: "#CAD4DF",
      color5: "#2EA9FF",
    },
    courseWebPic: {
      color1: "#F0F4F9",
      color2: "#BBC7D5",
      color3: "#DAE2EC",
      color4: "#CAD4DF",
      color5: "#2EA9FF",
    },
    courseSimulatePic: {
      color1: "#F0F4F9",
      color2: "#DAE2EC",
      color3: "#BBC7D5",
      color4: "#2EA9FF",
      color5: "#9FABBA",
      color6: "#CAD4DF",
    },
    questionIcon: {
      color1: "#4377B5",
    },
    settingsSuccessIcon: {
      color1: "#86C072",
    },
    bookPrestartPic: {
      color1: "#F0F4F9",
      color2: "#BBC7D5",
      color3: "#DAE2EC",
      color4: "#CAD4DF",
      color5: "#2EA9FF",
    },
    chartGreenIcon: {
      color1: "#86C072",
    },
    alertIcon: {
      color1: "#4377B5",
    },
    quotesIcon: {
      color1: "#F3F7FA",
    },
    courseSheetStartPic: {
      color1: "#DAE2EC",
      color2: "#F0F4F9",
      color3: "#BBC7D5",
      color4: "#ECF1F8",
      color5: "#CAD4DF",
      color6: "#2EA9FF",
    },
    courseDownloadPic: {
      color1: "#D9E4F0",
      color2: "#C4D1E0",
      color3: "#ACB8C9",
      color4: "#9FB0C4",
      color5: "#F0F4F9",
    },
    plant: {
      color1: "#CAD4DF",
      color2: "#BBC7D5",
      color3: "#DAE2EC",
    },
    lamp: {
      color1: "#BBC7D5",
      color2: "#CAD4DF",
      color3: "#BBC7D5",
      color4: "#DAE2EC",
      color5: "#CAD4DF",
    },
    plant2: {
      color1: "#DAE2EC",
      color2: "#CAD4DF",
    },
    rocket: {
      color1: "url(#paint0_linear_8534_104196)",
      color2: "#0062A7",
      color3: "#FAB600",
      color4: "#EC7260",
      color5: "#2EA9FF",
      color6: "#29476B",
      color7: "#B5CDEA",
      color8: "#4377B5",
      color9: "#fff",
      color10: "#426A9A",
      color11: "#7AB6FF",
      color12: "paint0_linear_8534_104196",
    },
    taskPic: {
      color1: "#DADEE3",
      color2: "#BBC7D5",
      color3: "#F0F4F9",
      color4: "#DAE2EC",
      color5: "#CAD4DF",
      color6: "#2EA9FF",
    },
    testResultDownloadIcon: {
      color1: "#4377B5",
    },
    forumMessagePic: {
      color1: "#F0F4F9",
      color2: "#DAE2EC",
      color3: "#BBC7D5",
    },
    testLeafPic: {
      color1: "url(#filter0_d_8160_93732)",
      color2: "#B5CDEA",
      color3: "#29476B",
      color4: "#B5CDEA",
      color5: "#29476B",
      color6: "#B5CDEA",
      color7: "#29476B",
      color8: "#B5CDEA",
      color9: "#B5CDEA",
    },
    clockTestPic: {
      color1: "#D9E4F0",
      color2: "#C4D1E0",
      color3: "#ACB8C9",
      color4: "#CAD4DF",
    },
    certificatePic: {
      color1: "#F3F7FA",
      color2: "#ADC4DE",
      color3: "#4377B5",
      color4: "#2EA9FF",
    },
    resultChart: {
      color1: "#4377B5",
      color2: "#C5C9D0",
      color3: "#86C072",
      color4: "#E1F1FF",
    },
    studyPlug: {
      color1: "#F0F4F9",
      color2: "#DAE2EC",
      color3: "#CAD4DF",
      color4: "#BBC7D5",
    },
    profileWallet: {
      color1: "#fff",
      color2: "#E5B0FF",
      color3: "#FFC422",
    },
  },
  myAcademy: {
    arrow: {
      color1: "#00AEFF",
      color2: "#FFFFFF",
    },
    profileWallet: {
      color1: "#FFE796",
      color2: "#FF8B00",
      color3: "#FFFFFF",
    },
  },
  rostechnadzor: {
    arrow: {
      color1: "#176BD9",
      color2: "#FFFFFF",
    },
    profileWallet: {
      color1: "#FFE796",
      color2: "#FF8B00",
      color3: "#FFFFFF",
    },
  },
};
