import { SVGProps } from "react";

export const CertificateSvgBg = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={393}
    height={206}
    viewBox="0 0 393 206"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M349.085 21.985C253.303-39.686-5.057 40.588.773 131.702c5.945 92.907 215.978 88.991 317.314 36.722 110.638-57.067 78.766-115.683 30.998-146.439Z"
      opacity={0.1}
    />
  </svg>
);
