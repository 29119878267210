import "./App.css";

import { ErrorPageLazy } from "./pages/Errors";
import NeedAuth from "./hoc/NeedAuth";
import NeedAuthAnonymos from "./hoc/NeedAuthAnonymos";
import NeedAuthShared from "./hoc/NeedAuthShared";
import UrlContext from "./contexts/urlcontext";
import View from "./components/View/View";
import { AchievementsLazy } from "./pages/Achievements";
import { AuthorizationLazy } from "./pages/Authorization";
import { Capacitor } from "@capacitor/core";
import { CourseHistoryLazy } from "./pages/CourseHistory";
import { CourseLandingLazy } from "./pages/CourseLanding";
import { CourseLazy } from "./pages/Course";
import { CoursesLazy } from "./pages/Courses";
import { ErrorPageMessages } from "./app/messages";
import { MainLazy } from "./pages/Main";
import { MyCoursesLazy } from "./pages/My";
import { Navigate, Route, Routes } from "react-router-dom";
import { OperationHistoryLazy } from "pages/OperationHistory";
import { OrdersLazy } from "pages/Orders";
import { P403Pic, P404Pic, P500Pic, P503Pic } from "./components/Svg/Pics";
import { ProfileLazy } from "./pages/Profile";
import { RewardsShopLazy } from "pages/RewardsShop";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { SettingsLazy } from "./pages/Settings";
import { Suspense, useEffect } from "react";
import { WebAppRoutes } from "./app/routes";
import { authRoutesConfig } from "pages/Authorization/authRoutes.congig";
import { deleteCookie } from "services/cookie";
import { generateUid } from "app/utils";
import { getCapabilitiesThunkAction, clear } from "features/auth/authSlice";
import { getCookieValue } from "services/cookie";
import { getInitProfileFields } from "./pages/Profile/redux/profile-slice/profile.slice";
import { getMobileSettingsThunkAction } from "components/UpdateMobileModal/redux/mobile.slice";
import { logoutResponse } from "features/auth/authSlice";
import { selectAuthOIDC } from "features/auth/selectors";
import { timeLastUpdateMobile } from "components/UpdateMobileModal/redux/mobile.select";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEventListener, useMemoizedFn } from "ahooks";
import { useSelector } from "react-redux";
import { RatingLazy } from "./pages/Rating";
import {
  getShopAvailableThunkAction,
  getShopSettingsThunkAction,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";

const App = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthOIDC);
  const timeLastUpdate = useAppSelector(timeLastUpdateMobile);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }, []);

  useEventListener("resize", () => {
    if (!Capacitor.isNativePlatform()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }, []);

  const hanleGetMobileSettings = useMemoizedFn(() => {
    const date1 = new Date().getTime();
    const date2 = (timeLastUpdate || new Date(0)).getTime();

    const diffInMilliseconds = Math.abs(date1 - date2);

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    diffInHours > 1 && dispatch(getMobileSettingsThunkAction());
  });

  useEffect(() => {
    authState.idToken && dispatch(getShopSettingsThunkAction());
    authState.idToken && dispatch(getShopAvailableThunkAction());
    authState.idToken && dispatch(getCapabilitiesThunkAction());
    Capacitor.isNativePlatform() && hanleGetMobileSettings();
  }, [dispatch, authState, hanleGetMobileSettings]);

  useEffect(() => {
    dispatch(getInitProfileFields());
  }, [dispatch]);

  const logout = () => {
    dispatch(clear());
    dispatch(logoutResponse());
    deleteCookie("jwt_authorization");
    deleteCookie("jwt_refresh");
  };

  const oidc = window.localStorage?.oidc ? JSON.parse(window.localStorage?.oidc) : {};

  const handleCookieChange = useMemoizedFn(async () => {
    const autorizationToken = oidc.idToken;

    authRoutesConfig.find((route) => window.location.pathname.includes(route.path)) === undefined &&
      !(await getCookieValue("jwt_authorization").then((res) => res)) &&
      autorizationToken &&
      logout();
  });

  useEffect(() => {
    handleCookieChange();
    const intervalChangeCookie = setInterval(handleCookieChange, 1000);

    return () => clearInterval(intervalChangeCookie);
  }, [handleCookieChange]);

  return (
    <View>
      <Routes>
        {authRoutesConfig.map((route) => (
          <Route
            exact
            path={route.path}
            element={
              <UrlContext.Provider value={route.contextInitial}>
                <Suspense fallback={<div></div>}>
                  <AuthorizationLazy />
                </Suspense>
              </UrlContext.Provider>
            }
            key={generateUid()}
          />
        ))}

        <Route
          exact
          path={WebAppRoutes.PROFILE + "/*"}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy sx={{ marginBottom: 0 }}>
                  <Suspense fallback={<div></div>}>
                    <ProfileLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />
        <Route
          exact
          path={WebAppRoutes.COURSE_SHARED + "/*"}
          element={<Navigate to="/" replace />}
        />
        <Route
          exact
          path={WebAppRoutes.COURSE_SHARED + "/:course_share_id/*"}
          element={
            <NeedAuthAnonymos>
              <div></div>
            </NeedAuthAnonymos>
          }
        />

        <Route exact path={WebAppRoutes.COURSE + "/*"} element={<Navigate to="/" replace />} />
        <Route
          exact
          path={WebAppRoutes.COURSE + "/:course_id/*"}
          element={
            <NeedAuthShared>
              <Suspense fallback={<div></div>}>
                <CourseLazy />
              </Suspense>
            </NeedAuthShared>
          }
        />

        <Route
          exact
          path={WebAppRoutes.COURSE_PREVIEW + "/:course_id"}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <CourseLandingLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.COURSES}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <CoursesLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.REWARDS_SHOP}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <RewardsShopLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.OPERATION_HISTORY}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <OperationHistoryLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.HOME}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <MyCoursesLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.HISTORY}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <CourseHistoryLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.SETTINGS}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <SettingsLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.ACHIEVEMENTS}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <AchievementsLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.RATING}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <RatingLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.ORDERS}
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <OrdersLazy />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/500"
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <ErrorPageLazy
                      pic={<P500Pic />}
                      title={ErrorPageMessages.PAGE_500_TITLE}
                      text={ErrorPageMessages.PAGE_500_DETAIL}
                    />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/503"
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <ErrorPageLazy
                      pic={<P503Pic />}
                      title={ErrorPageMessages.PAGE_503_TITLE}
                      text={ErrorPageMessages.PAGE_503_DETAIL}
                    />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/403"
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <ErrorPageLazy
                      pic={<P403Pic />}
                      title={ErrorPageMessages.PAGE_403_TITLE}
                      text={ErrorPageMessages.PAGE_403_DETAIL}
                    />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />
        <Route
          exact
          path="/404"
          element={
            <NeedAuth>
              <Suspense fallback={<div></div>}>
                <MainLazy>
                  <Suspense fallback={<div></div>}>
                    <ErrorPageLazy
                      pic={<P404Pic />}
                      title={ErrorPageMessages.PAGE_404_TITLE}
                      text={ErrorPageMessages.PAGE_404_DETAIL}
                    />
                  </Suspense>
                </MainLazy>
              </Suspense>
            </NeedAuth>
          }
        />

        <Route exact path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </View>
  );
};

export default App;
