import { H1 } from "components/Typography/Titles";
import { Button } from "components/Buttons";
import { useUpdateMobileModal } from "./useUpdateMobileModal";
import styles from "./UpdateMobileModal.module.scss";

export const UpdateMobileModal = (): JSX.Element => {
  const { isOpen, handleContainerClick, containerRef, onConfirm, onCancel } =
    useUpdateMobileModal();

  return (
    <>
      {isOpen && (
        <div ref={containerRef} onClick={handleContainerClick} className={styles["container"]}>
          <div className={styles["modal"]}>
            <H1 className={styles["title"]}>Требуется обновление</H1>
            <div className={styles["text"]}>
              Версия приложения устарела и может работать неправильно.
            </div>
            <div className={styles["controls"]}>
              <Button size="big" className="modal__button" color="primary" onClick={onConfirm}>
                Обновить
              </Button>
              <Button size="big" className="modal__button" color="base" onClick={onCancel}>
                Позже
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
