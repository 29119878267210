import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { СompetenciesTypeData } from "../../../components/CompetenciesCard/competancies.types";
import { WebAppRoutes } from "../../../app/routes";
import { RootState } from "../../../app/store";
import {
  IBadgesRequest,
  IBadgesResponse,
  ICertificateAvalabilityDownloadResponse,
  ICertificateAvalabilityResponse,
} from "../../Achievements/redux/interfaces";
import { CourseLesson, CoursePagination, CourseSecton, CourseState, ICourse } from "./interfaces";
import {
  CourseCoinsData,
  CourseFeedbackRequest,
  CourseFeedbacksResponse,
  CourseFormat,
  MulticourseContentCourse,
  MulticourseContentResponse,
  ViewedInfoRequest,
  ViewedInfoResponse,
} from "./interfaces/course.interface";
import { postMeetingViewed } from "../components/Meeting";
import {
  enrollCourse,
  getCourseLesson,
  getCourseResults,
  setCourseLessonFinish,
  setCourseLessonStart,
  setTaskEnd,
  getPageContent,
  getPageViewInfo,
  getBadges,
  savePageViewInfo,
  saveCoursePageViewed,
  getCourseFeedbacks,
  getCourseReportScores,
  getLessonCompetencies,
  getMulticourseContent,
  getCourseResultCompetencies,
  saveCourseFeedback,
  getCourseCoins,
  getCoursePollUrl,
  getCoursesKeywords,
  getCertificateAvailability,
  updateCourseVisite,
  getCertificateDownloadAvailability,
  getCourse,
  getCourseContent,
  getCourseCompetences,
  getCourseSummary,
} from "../../../api";
import { SearchParamsOption } from "ky";
import { getMulticourseContentSections } from "api/course/get-multicourse-content-sections";

const coursesAdapter = createEntityAdapter<ICourse>({
  selectId: (course) => course.id,
});

const sectionsAdapter = createEntityAdapter<CourseSecton>({
  selectId: (section) => section.id,
});

const lessonsAdapter = createEntityAdapter<CourseLesson>({
  selectId: (lesson) => lesson.id,
});

const paginationsAdapter = createEntityAdapter<CoursePagination>({
  selectId: (page) => page.id,
});

const initialState: CourseState = {
  isLoading: false,
  options: {
    isBottomNav: false,
    isAsideNav: false,
    courseMenuIsOpen: true,
  },
  courseResult: {
    isFinish: false,
    start_ts: 0,
    end_ts: 0,
    grade: 0,
    days: 0,
  },
  isLoadingCourseLesson: false,
  pageBeforeCourse: WebAppRoutes.HOME,
  activeLessonId: 0,
  activeContentId: 0,
  scores: null,
  achievements: [],
  competence: [],
  content: [],
  feedbacks: { paging: { offset: 0, total: 0, limit: 10 }, data: [] },
  feedbacksIsLoading: false,
  summary: {},
  multicourseContent: { courses: [], any_order: false },
  keywordsCoursesList: [],
  certificateAvailability: false,
  certificateDownloadAvailability: false,
  multicourseContentSections: [],
};

const getCourseInfo = createAsyncThunk(
  "course/getCourseInfo",
  async (course_id: number, thunkAPI) => {
    try {
      const [course, courseContent, feedbacks, competence, summary] = await Promise.all([
        ((await getCourse({ id: course_id, signal: thunkAPI.signal })) as any).data,
        ((await getCourseContent({ id: course_id, signal: thunkAPI.signal })) as any).data,
        (await getCourseFeedbacks({ courseId: course_id, signal: thunkAPI.signal })) as any,
        ((await getCourseCompetences({ courseId: course_id, signal: thunkAPI.signal })) as any)
          .data,
        ((await getCourseSummary({ id: course_id, signal: thunkAPI.signal })) as any).data,
      ]);

      let multicourseContent = [];
      let multicourseContentSections = [];

      if (course?.format === CourseFormat.multicourse) {
        multicourseContent = ((await getMulticourseContent({ id: course_id })) as any).data || {};
        multicourseContentSections =
          ((await getMulticourseContentSections({ id: course_id })) as any).data || [];
      }

      return {
        course,
        courseContent,
        multicourseContent,
        feedbacks,
        competence,
        summary,
        multicourseContentSections,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getCourseLessonThunkAction = createAsyncThunk(
  "course/getCourseLesson",
  async (lesson_id: number, thunkAPI) => {
    return await getCourseLesson({ lessonId: lesson_id, signal: thunkAPI.signal });
  }
);

const setCourseLessonStartThunkAction = createAsyncThunk(
  "course/setCourseLessonStart",
  async (lesson_id: number, thunkAPI) =>
    await setCourseLessonStart({ lessonId: lesson_id, signal: thunkAPI.signal })
);

const setCourseLessonFinishThunkAction = createAsyncThunk(
  "course/setCourseLessonFinish",
  async (lesson_id: number, thunkAPI) => {
    try {
      return await setCourseLessonFinish({ lessonId: lesson_id, signal: thunkAPI.signal });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const setCourseTaskEndThunkAction = createAsyncThunk(
  "course/setCourseTaskEnd",
  async (lesson_id: number, thunkAPI) => {
    try {
      return await setTaskEnd({ id: lesson_id, signal: thunkAPI.signal });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const setCourseEnrolled = createAsyncThunk(
  "course/setCourseEnrolled",
  async (arg: number, thunkAPI) => {
    try {
      return await enrollCourse(arg);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getCourseResultsThunkAction = createAsyncThunk(
  "course/getCourseResults",
  async (courseId: number, { rejectWithValue }) => {
    try {
      return await getCourseResults(courseId);
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getCoursePageContent = createAsyncThunk(
  "course/getCoursePageContent",
  async (pageId: number, thunkAPI) => await getPageContent({ id: pageId, signal: thunkAPI.signal })
);

const getCoursePageViewedInfo = createAsyncThunk<ViewedInfoResponse, number>(
  "course/getCoursePageViewedInfo",
  async (pageId: number) => (await getPageViewInfo(pageId)) as any
);

const postCoursePageViewedInfo = createAsyncThunk(
  "course/postCoursePageViewedInfo",
  async (info: ViewedInfoRequest & { pageId: number }, thunkAPI) => {
    const { pageId, ...request } = info;
    return await savePageViewInfo({ id: pageId, data: request });
  }
);

const postCoursePageViewed = createAsyncThunk(
  "course/postCoursePageViewed",
  async (pageId: number, thunkAPI) => await saveCoursePageViewed(pageId)
);

const getCourseReportsScores = createAsyncThunk(
  "course/getCourseReportsScores",
  async (courseId: number) => ((await getCourseReportScores(courseId)) as any).data
);

const getLessonCompetence = createAsyncThunk(
  "course/getLessonCompetence",
  async ({ id }: { id: number }) => await getLessonCompetencies(id)
);

const getMulticourseContentThunkAction = createAsyncThunk(
  "course/getMulticourseContent",
  async ({ id }: { id: number }) => await getMulticourseContent({ id })
);

const getBadgesThunkAction = createAsyncThunk(
  "course/getBadges",
  async (params: IBadgesRequest) => await getBadges(params as SearchParamsOption).json()
);

const setCourseFeedback = createAsyncThunk(
  "course/setCourseFeedback",
  async (data: CourseFeedbackRequest, thunkAPI) => {
    try {
      await saveCourseFeedback({ courseId: data.course_id, data });
      return data.course_id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getResultCompetence = createAsyncThunk(
  "course/getResultCompetence",
  async ({ id }: { id: number }) => await getCourseResultCompetencies(id)
);

const getCourseFeedbacksThunkAction = createAsyncThunk(
  "course/getCourseFeedbacks",
  async ({
    id,
    offset = 0,
    limit = 10,
    add = false,
  }: {
    id: number;
    offset?: number;
    limit?: number;
    add?: boolean;
  }) => {
    const response = await getCourseFeedbacks({
      courseId: id,
      params: {
        offset,
        limit,
      },
    });

    return { data: response, add };
  }
);

const getCourseCoinsThunkAction = createAsyncThunk(
  "course/getCourseCoins",
  async (courseId: number) => await getCourseCoins(courseId)
);

const getPollUrl = createAsyncThunk("course/getPollUrl", async (courseId: number, thunkAPI) => {
  try {
    return await getCoursePollUrl(courseId);
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      code: error.response.status,
      details: error.response.statusText,
    });
  }
});

const getKeywordsCoursesList = createAsyncThunk(
  "rewards-shop/getKeywordsCoursesList",
  async (_args, thunkAPI) => {
    try {
      return await getCoursesKeywords();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
      });
    }
  }
);

const updateVisite = createAsyncThunk("course/updateVisite", async (id: number, thunkAPI) => {
  try {
    return await updateCourseVisite(id);
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ status: error?.response?.status });
  }
});

const getCertificateAvailabilityThunkAction = createAsyncThunk(
  "course/getCertificateAvailability",
  async (courseId: number, thunkAPI) => {
    try {
      return await getCertificateAvailability(courseId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
      });
    }
  }
);

const getCertificateDownloadAvailabilityThunkAction = createAsyncThunk(
  "course/getCertificateDownloadAvailability",
  async (courseId: number, thunkAPI) => {
    try {
      return await getCertificateDownloadAvailability(courseId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
      });
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState: {
    ...initialState,
    pagination: paginationsAdapter.getInitialState(),
    courses: coursesAdapter.getInitialState({ loading: false }),
    sections: sectionsAdapter.getInitialState({ loaded: false }),
    lessons: lessonsAdapter.getInitialState({ loaded: false }),
  },
  reducers: {
    setActiveLesson(state, { payload }) {
      // @ts-ignore
      state.activeLessonId = payload ? parseInt(payload) : state.lessons.ids[0];
    },
    setActiveContent(state, action: PayloadAction<string | undefined>) {
      state.activeContentId = action.payload
        ? parseInt(action.payload)
        : state.multicourseContent?.courses?.[0]?.id;
    },
    // Определяем страницу, с которой пользователь перешел в курс
    setPageBeforeCourse(state, action: PayloadAction<string>) {
      state.pageBeforeCourse = action.payload;
    },
    setLessonQuizCompleted(state, action: PayloadAction<string | undefined>) {
      if (!action.payload) return;

      lessonsAdapter.updateOne(state.lessons, {
        id: action.payload,
        changes: {
          status: "completed",
        },
      });
    },

    showBottomNav(state) {
      state.options.isBottomNav = true;
    },
    hideBottomNav(state) {
      state.options.isBottomNav = false;
    },
    showAsideNav(state) {
      state.options.isAsideNav = true;
    },
    hideAsideNav(state) {
      state.options.isAsideNav = false;
    },
    setIsOpenCourseMenu(state, action: { payload: boolean }) {
      state.options.courseMenuIsOpen = action.payload;
    },
    clearCourseData(state) {
      coursesAdapter.removeAll(state.courses);
      lessonsAdapter.removeAll(state.lessons);
      sectionsAdapter.removeAll(state.sections);
      // TODO: WTF
      state.isLoading = false;
      state.options = {
        isBottomNav: false,
        isAsideNav: false,
        courseMenuIsOpen: true,
      };
      state.courseResult = {
        isFinish: false,
        grade: 0,
        start_ts: 0,
        end_ts: 0,
        days: 0,
      };
      state.activeLessonId = 0;
      state.scores = null;
      state.achievements = [];
      state.coins = undefined;
    },
    clearScores(state) {
      state.scores = null;
    },
    clearAchievements(state) {
      state.achievements = [];
    },
    setLessonStatus(state, action: PayloadAction<number>) {
      const lesson = state.lessons.entities[action.payload];
      if (!lesson) return;
      lesson.status = "completed";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseInfo.pending, (state, action) => {
        state.isLoading = true;
        state.courses.loading = false;
      })
      .addCase(getCourseInfo.fulfilled, (state, action) => {
        if (action.payload?.course) {
          coursesAdapter.addOne(
            state.courses,
            Object.assign({ sectionsId: [], lessonsId: [] }, action.payload.course)
          );
        }

        if (action.payload.courseContent) {
          state.sections.loaded = true;

          const mappedSectionData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) => ({
              ...section,
              lessons: section.lessons.map((lesson: any) => lesson.id),
            }));
          const mappedLessonsData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) => section.lessons)
            .flat();
          const mappedPagiantionData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) =>
              section.lessons.map((lesson: any) => ({
                id: lesson.id,
                lessonId: lesson.id,
                sectionId: section.id,
                sectionTitle: section.title,
                unitTitle: lesson.title,
                link: "/lesson/" + lesson.id,
              }))
            )
            .flat();

          state.multicourseContent = {
            courses: (
              action.payload.multicourseContent?.courses as MulticourseContentCourse[]
            )?.sort((a, b) => a.order - b.order),
            any_order: action.payload.multicourseContent?.any_order,
            courses_count: action.payload.multicourseContent?.courses_count,
          };
          state.content = action.payload.courseContent;
          state.multicourseContentSections = action.payload.multicourseContentSections;

          sectionsAdapter.setAll(state.sections, mappedSectionData);
          lessonsAdapter.setAll(state.lessons, mappedLessonsData);
          paginationsAdapter.setAll(state.pagination, mappedPagiantionData);
          // @ts-ignore
          coursesAdapter.updateOne(state.courses, {
            // @ts-ignore
            id: parseInt(action.payload?.courseId),
            changes: {
              // @ts-ignore
              lessonsId: mappedLessonsData.map((lesson) => lesson.id),
              // @ts-ignore
              sectionsId: mappedSectionData.map((section) => section.id),
            },
          });
        }

        action.payload.courseContent && (state.content = action.payload.courseContent);
        action.payload.competence && (state.competence = action.payload.competence);
        action.payload.feedbacks && (state.feedbacks = action.payload.feedbacks);
        action.payload.multicourseContent &&
          (state.multicourseContent = action.payload.multicourseContent);
        action.payload.summary && (state.summary = action.payload.summary);

        state.isLoading = false;
        state.courses.loading = true;
        state.sections.loaded = false;
      })
      .addCase(getCourseInfo.rejected, (state, action) => {
        state.isLoading = true;
        state.lessons.loaded = false;
        state.sections.loaded = false;
        state.courses.entities = {};
        state.courses.ids = [];
      })
      .addCase(postMeetingViewed.fulfilled, (state, action) => {
        lessonsAdapter.updateOne(state.lessons, {
          id: action.payload,
          changes: {
            status: "completed",
          },
        });
      })
      .addCase(setCourseLessonFinishThunkAction.fulfilled, (state, action: any) => {
        const { id, status } = action.payload?.data ?? ({} as any);
        lessonsAdapter.updateOne(state.lessons, {
          id: parseInt(id),
          changes: {
            status,
          },
        });
      })
      .addCase(getCourseResultsThunkAction.pending, (state, action) => {
        state.courseResult = {
          isFinish: false,
          grade: 0,
          start_ts: 0,
          end_ts: 0,
          days: 0,
        };
      })
      .addCase(getCourseResultsThunkAction.fulfilled, (state, action) => {
        state.courseResult.isFinish = true;
        //@ts-ignore
        state.courseResult.grade = action.payload?.data.grade;
        //@ts-ignore
        state.courseResult.start_ts = action.payload?.data.start_ts;
        //@ts-ignore
        state.courseResult.end_ts = action.payload?.data.end_ts;
        //@ts-ignore
        state.courseResult.days = action.payload?.data.days;
      })
      .addCase(getCourseResultsThunkAction.rejected, (state, action) => {
        state.courseResult = {
          isFinish: false,
          grade: 0,
          start_ts: 0,
          end_ts: 0,
          days: 0,
        };
      })
      .addCase(getCourseLessonThunkAction.pending, (state) => {
        state.isLoadingCourseLesson = true;
      })
      .addCase(getCourseLessonThunkAction.fulfilled, (state, action: any) => {
        state.isLoadingCourseLesson = false;
        // state.activeLesson = action.payload.data;
        //@ts-ignore
        const id = parseInt(action.payload?.data?.id);
        const lesson = state.lessons.entities[id];
        lessonsAdapter.updateOne(state.lessons, {
          //@ts-ignore
          id: parseInt(action.payload?.data?.id),
          changes:
            lesson?.type !== "page"
              ? action.payload?.data
              : //@ts-ignore
                { ...action.payload?.data, status: lesson?.status || action.payload.data?.status },
        });
        state.isLoadingCourseLesson = false;
      })
      .addCase(getCourseLessonThunkAction.rejected, (state) => {
        state.isLoadingCourseLesson = false;
      })
      .addCase(getCourseReportsScores.fulfilled, (state, action) => {
        state.scores = action.payload[0] ?? null;
      })
      .addCase(getBadgesThunkAction.pending, (state) => {
        state.achievements = [];
      })
      .addCase(
        getCertificateAvailabilityThunkAction.fulfilled,
        (state, action: PayloadAction<ICertificateAvalabilityResponse | any>) => {
          state.certificateAvailability = action.payload.data.enable;
        }
      )
      .addCase(
        getCertificateDownloadAvailabilityThunkAction.fulfilled,
        (state, action: PayloadAction<ICertificateAvalabilityDownloadResponse | any>) => {
          //@ts-ignore
          state.certificateDownloadAvailability = action.payload.data.status;
        }
      )
      .addCase(
        getBadgesThunkAction.fulfilled,
        (state, action: PayloadAction<IBadgesResponse | any>) => {
          state.achievements = action.payload.data || [];
        }
      )
      .addCase(getCourseCoinsThunkAction.pending, (state) => {
        state.coins = undefined;
      })
      .addCase(
        getCourseCoinsThunkAction.fulfilled,
        (state, action: PayloadAction<{ data: CourseCoinsData } | any>) => {
          state.coins = action.payload?.data;
        }
      )
      .addCase(getCourseCoinsThunkAction.rejected, (state) => {
        state.coins = undefined;
      })
      .addCase(
        getLessonCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData | any>) => {
          state.competence = action.payload || [];
        }
      )
      .addCase(
        getResultCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData | any>) => {
          state.competence = action.payload || [];
        }
      )
      .addCase(
        getCourseFeedbacksThunkAction.fulfilled,
        (state, action: PayloadAction<{ data: CourseFeedbacksResponse; add?: boolean } | any>) => {
          if (action.payload.add) {
            state.feedbacks.data = [...state.feedbacks.data, ...action.payload.data.data];
            state.feedbacks.paging = action.payload.data.paging;
          } else {
            state.feedbacks = action.payload.data || {};
          }
          state.feedbacksIsLoading = false;
        }
      )
      .addCase(getCourseFeedbacksThunkAction.pending, (state, action) => {
        state.feedbacksIsLoading = true;
      })
      .addCase(
        getPollUrl.fulfilled,
        (state, action: PayloadAction<{ data: { link?: string } } | any>) => {
          state.pollUrl = action.payload?.data?.link || "";
        }
      )
      .addCase(getCourseFeedbacksThunkAction.rejected, (state, action) => {
        state.feedbacksIsLoading = false;
      })
      .addCase(
        getMulticourseContentThunkAction.fulfilled,
        (state, action: PayloadAction<MulticourseContentResponse | any>) => {
          state.multicourseContent = action.payload?.data;
        }
      )
      .addCase(getMulticourseContentThunkAction.rejected, (state) => {})
      .addCase(setCourseFeedback.fulfilled, (state, action: PayloadAction<string | any>) => {
        coursesAdapter.updateOne(state.courses, {
          id: parseInt(action.payload),
          changes: { commenting: false },
        });
      })
      .addCase(getKeywordsCoursesList.fulfilled, (state, action) => {
        //@ts-ignore
        state.keywordsCoursesList = action.payload.data;
      })
      .addCase(postCoursePageViewed.fulfilled, (state, action) => {
        const lesson = state.lessons.entities[action.meta.arg];
        if (!lesson) return;
        lesson.status = "completed";
      });
  },
});

export const coursesSelectors = coursesAdapter.getSelectors(
  (state: RootState) => state.course.courses
);
export const sectionsSelectors = sectionsAdapter.getSelectors(
  (state: RootState) => state.course.sections
);
export const lessonsSelectors = lessonsAdapter.getSelectors(
  (state: RootState) => state.course.lessons
);

export const selectMulticourseContent = (state: RootState) => state.course.multicourseContent;

export const {
  setActiveLesson,
  setActiveContent,
  setLessonStatus,
  setPageBeforeCourse,
  setLessonQuizCompleted,
  clearCourseData,
  showBottomNav,
  showAsideNav,
  hideAsideNav,
  setIsOpenCourseMenu,
  hideBottomNav,
  clearScores,
  clearAchievements,
} = courseSlice.actions;

export {
  getBadgesThunkAction,
  getCourseCoinsThunkAction,
  getCourseFeedbacksThunkAction,
  getCourseInfo,
  getCourseLessonThunkAction,
  getCoursePageContent,
  getCoursePageViewedInfo,
  getCourseReportsScores,
  getCourseResultsThunkAction,
  getKeywordsCoursesList,
  getLessonCompetence,
  getMulticourseContentThunkAction,
  getPollUrl,
  getResultCompetence,
  postCoursePageViewed,
  postCoursePageViewedInfo,
  setCourseEnrolled,
  setCourseFeedback,
  setCourseLessonFinishThunkAction,
  setCourseLessonStartThunkAction,
  updateVisite,
  setCourseTaskEndThunkAction,
  getCertificateAvailabilityThunkAction,
  getCertificateDownloadAvailabilityThunkAction,
};

export default courseSlice.reducer;
