import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { Button } from "components/Buttons";
import styles from "./DescriptionBeforeStartMeeting.module.scss";
import { MeetingType } from "../../../../redux";

interface DescriptionBeforeStartMeetingProps {
  startDate: Date;
  showButton: boolean;
  isRecordedMeeting: boolean;
  hasEndDate?: boolean;
  hasRecords?: boolean;
  onClickButton: () => void;
  meetingType: MeetingType;
}

export const DescriptionBeforeStartMeeting = ({
  startDate,
  showButton,
  hasEndDate = false,
  hasRecords = false,
  onClickButton,
  isRecordedMeeting = false,
  meetingType,
}: DescriptionBeforeStartMeetingProps) => (
  <>
    <div className={styles["description-container"]}>
      {startDate && +startDate > 0 && (
        <p className={styles.description}>
          Вебинар начнется{" "}
          <span className={styles["bold-date"]}>
            {format(startDate, "dd.MM.yyyy (EEEE) в HH:mm", { locale: ru })}
          </span>
        </p>
      )}

      {!startDate && !hasRecords && (
        <p className={styles.description}>Дата начала вебинара еще не назначена.</p>
      )}
      {!startDate && !hasEndDate && !hasRecords && (
        <p className={styles.description}>Дата и время начала вебинара будет извеcтна позже</p>
      )}

      {isRecordedMeeting && meetingType !== MeetingType.otherSystem && (
        <p className={styles.description}>
          По окончанию вебинара вам будет доступна запись на весь срок обучения.
        </p>
      )}
    </div>
    {showButton && startDate && (
      <Button color="primary" className={styles["button"]} onClick={onClickButton}>
        Напомнить
      </Button>
    )}
  </>
);
