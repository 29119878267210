import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "./reducer";
import { avatarsApi } from "./rtk-api";

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(avatarsApi.middleware),
});

export type RootState = ReturnType<typeof appReducer>;
export type appState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
