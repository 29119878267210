import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MobileState } from "./mobile.types";
import { getMobileSettings } from "../../../api";

const initialState: MobileState = {
  settings: {
    ios_link: "",
    android_link: "",
    mobile_version: "",
  },
  timeLastUpdate: null,
};

const getMobileSettingsThunkAction = createAsyncThunk(
  "mobile/getMobileSettingsing",
  async (_arg, thunkAPI) => {
    try {
      const request = await getMobileSettings();
      const response = await request.json();
      return {
        status: request.status,
        //@ts-ignore
        settings: response?.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
      });
    }
  }
);

const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMobileSettingsThunkAction.fulfilled, (state, action) => {
      state.settings = action.payload.settings;
      state.timeLastUpdate = new Date();
    });
  },
});

export { getMobileSettingsThunkAction };

export default mobileSlice.reducer;
