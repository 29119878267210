export const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M4.5 7.47266H19.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8332 7.47266V19.1393C17.8332 19.9727 16.9998 20.806 16.1665 20.806H7.83317C6.99984 20.806 6.1665 19.9727 6.1665 19.1393V7.47266"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6665 7.47201V5.80534C8.6665 4.97201 9.49984 4.13867 10.3332 4.13867H13.6665C14.4998 4.13867 15.3332 4.97201 15.3332 5.80534V7.47201"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3335 11.6387V16.6387"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6665 11.6387V16.6387"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
