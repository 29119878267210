import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import stylesColor from "./Badge-colors.module.scss";
import stylesTypes from "./Badge-types.module.scss";
import styles from "./Badge.module.scss";
import { BadgeColor, BadgeType } from "./Types";

interface BadgeProps {
  children?: ReactNode;
  color?: BadgeColor;
  type?: BadgeType;
  className?: string;
  style?: CSSProperties;
  withBorder?: boolean;
}

export const Badge = ({
  children = "",
  color = "base",
  type = "base",
  className = "",
  style = {},
  withBorder = false,
}: BadgeProps) => (
  <div
    className={classNames(
      styles["badge"],
      {
        [styles["badge--border"]]: withBorder,
        [stylesColor["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
      },
      stylesTypes[type],
      stylesColor[color],
      className
    )}
    style={style}
  >
    {children}
  </div>
);
