import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const updateConversationMessage = ({
  courseId,
  messageId,
  data,
}: {
  courseId: number;
  messageId: number;
  data: any;
}) =>
  KY.put(EndPoints.CONVERSATION_MESSAGE(courseId, messageId), {
    body: JSON.stringify(data),
  }).json();
