import styles from "./IconButton.module.scss";
import classNames from "classnames";

interface IconButtonProps {
  className?: string;
  children?: JSX.Element;
  hasCircle?: boolean;
  onClick?: () => void;
}

export const IconButton = ({
  className = "",
  children,
  hasCircle = false,
  onClick = () => {},
}: IconButtonProps) => {
  const iconButtonClassNames = classNames(
    styles["icon-button"],
    { [`${styles["icon-button_has-circle"]}`]: hasCircle },
    className
  );

  return (
    <button className={iconButtonClassNames} onClick={onClick} type="button">
      {children}
    </button>
  );
};
