import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../app/store";
import { IQuizQuestion } from "./interface";
import {
  getQuizQuestionsAnswersByAttemptId,
  getQuizQuestionsById,
  getQuizQuestionsStatus,
  saveQuizQuestionAnswer,
} from "../../../../../../api";

const getQuizeQuestions = createAsyncThunk(
  "courseQuizeQuestions/getQuizeQuestions",
  async (lesson_id: number) => await getQuizQuestionsById(lesson_id)
);

const setQuizeQuestionAnswer = createAsyncThunk<
  any,
  { quizeId: number | string; questionId: number | string; answer: any; attemptId: any }
>(
  "courseQuizeQuestions/setQuizeQuestionAnswer",
  async ({ quizeId, questionId, answer, attemptId }) =>
    await saveQuizQuestionAnswer({ quizeId, questionId, answer, attemptId })
);

const getQuizeQuestionAnswers = createAsyncThunk<
  any,
  { quizeId: number | string; attemptId: number | string }
>(
  "courseQuizeQuestions/getQuizeQuestionAnswers",
  async ({ quizeId, attemptId }) => await getQuizQuestionsAnswersByAttemptId({ quizeId, attemptId })
);

const getQuizeQuestionStatus = createAsyncThunk(
  "courseQuizeQuestions/getQuizeQuestionStatus",
  async () => await getQuizQuestionsStatus()
);

const questionsAdapter = createEntityAdapter<IQuizQuestion>({
  selectId: (question) => question.id,
});

const initialState = {
  questions: questionsAdapter.getInitialState(),
  currentOpenPageInQuiz: 1,
  answersByPage: {},
  answersOfAttempt: [],
  allAnswers: [],
  answerIsLoading: false,
};

const courseQuizeQuestionsSlice = createSlice({
  name: "courseQuizeQuestions",
  initialState,
  reducers: {
    clearCurrentOpenPageinQuiz(state) {
      state.currentOpenPageInQuiz = 1;
    },
    setAnswerIsLoading(state, action) {
      state.answerIsLoading = action.payload;
    },
    clearAllAnswers(state) {
      state.allAnswers = [];
    },
    setQuestAnswer(state, { payload }) {
      const copyAnswers: any = { ...state.answersByPage };
      const copyAllAnswers = [...state.allAnswers];
      const findIndexInCopyAllAnswers = copyAllAnswers.findIndex(
        (answer: any) => answer.id === payload.answer.id
      );

      if (findIndexInCopyAllAnswers >= 0) {
        (state.allAnswers as any)[findIndexInCopyAllAnswers] = payload.answer;
      } else {
        (state.allAnswers as any) = [...copyAllAnswers, payload.answer].sort((a, b) => a.id - b.id);
      }

      const findIndexCopyAnswers =
        copyAnswers[payload.page]?.findIndex(
          (copyAnswer: any) => copyAnswer.id === payload.answer.id
        ) ?? -1;

      if (findIndexCopyAnswers === -1) {
        copyAnswers[payload.page as string] = [
          ...(copyAnswers[payload.page as string] ?? []),
          payload.answer,
        ];
      } else {
        copyAnswers[payload.page as string][findIndexCopyAnswers].answer = payload.answer.answer;
      }

      state.answersByPage = copyAnswers;
    },
    clearQuestAnswer(state) {
      state.answersByPage = {};
    },
    clearCurrentOpenPageQuiz(state) {
      state.currentOpenPageInQuiz = 1;
    },
    clearQuestions(state) {
      questionsAdapter.removeAll(state.questions);
    },
    setCurrentOpenPageInQuiz(state, action) {
      state.currentOpenPageInQuiz = action.payload;
    },
    clearAnswersOfAttempt(state) {
      state.answersOfAttempt = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuizeQuestions.fulfilled, (state, { payload }) => {
        //@ts-ignore
        questionsAdapter.setAll(state.questions, payload.data);
      })
      .addCase(getQuizeQuestionStatus.fulfilled, (state, { payload }) => {})
      .addCase(getQuizeQuestionAnswers.fulfilled, (state, action) => {
        state.allAnswers = [...action.payload].map((value: any) => ({
          id: value.question_id,
          answer: value.answer,
        })) as any;
        state.answersOfAttempt = action.payload;
      });
  },
});

export const questionsSelectors = questionsAdapter.getSelectors(
  (state: RootState) => state.courseQuizeQuestions.questions
);

export const {
  setQuestAnswer,
  clearQuestAnswer,
  clearQuestions,
  clearCurrentOpenPageQuiz,
  setCurrentOpenPageInQuiz,
  clearCurrentOpenPageinQuiz,
  clearAllAnswers,
  clearAnswersOfAttempt,
  setAnswerIsLoading,
} = courseQuizeQuestionsSlice.actions;
export { getQuizeQuestions, setQuizeQuestionAnswer, getQuizeQuestionAnswers };
export default courseQuizeQuestionsSlice.reducer;
