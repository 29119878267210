import { SVGProps } from "react";

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99988 17.809C13.4185 17.809 17.0005 14.2272 17.0005 9.80896C17.0005 5.39068 13.4185 1.80896 8.99988 1.80896C4.58126 1.80896 0.999268 5.39068 0.999268 9.80896C0.999268 14.2272 4.58126 17.809 8.99988 17.809Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0006 19.8089L14.6506 15.4589"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
