export const H1 = ({ children, className, ...rest }) => {
  return (
    <h1 {...rest} className={`title ${className ?? ""}`} data-variant="h1">
      {children}
    </h1>
  );
};

export const H2 = ({ children, className, ...rest }) => {
  return (
    <h2 {...rest} className={`title ${className ?? ""}`} data-variant="h2">
      {children}
    </h2>
  );
};

export const H3 = ({ children, className, ...rest }) => {
  return (
    <h3 {...rest} className={`title ${className ?? ""}`} data-variant="h3">
      {children}
    </h3>
  );
};

export const H4 = ({ children, className, ...rest }) => {
  return (
    <h4 {...rest} className={`title ${className ?? ""}`} data-variant="h4">
      {children}
    </h4>
  );
};

export const Subtitle = ({ children, className, ...rest }) => {
  return (
    <h4 {...rest} className={`title ${className ?? ""}`} data-variant="subtitle-1">
      {children}
    </h4>
  );
};
