import { useEffect, useState } from "react";
import { useAsyncEffect } from "ahooks";
import { useSelector } from "react-redux";

import { selectSelectedTheme } from "../../features/theme/selectors";
import { useAppSelector } from "hooks/redux";
import { isHasInternetSelector } from "app/store";
import { KY } from "api/ky-instance";

const View = ({ children }) => {
  const [isSetCookies, setIsSetCookies] = useState(false);

  const theme = useSelector(selectSelectedTheme);
  const isHasInternet = useAppSelector(isHasInternetSelector);

  useAsyncEffect(async () => {
    try {
      if (isHasInternet) {
        await KY.get("ping");
      }
    } finally {
      setIsSetCookies(true);
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
    document.body.setAttribute("data-dark", false);
  }, [theme]);

  if (!isHasInternet) {
    return <div className="view">{children}</div>;
  }

  return isSetCookies ? <div className="view">{children}</div> : <></>;
};

export default View;
