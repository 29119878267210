import classNames from "classnames";
import { Badge } from "components/Badge";
import Container from "components/Container/Container";
import { CheckIcon, DoneIcon } from "components/Icons";
import { CourseWebinarImg } from "components/Svg/Pics";
import { LoadVideosSvgImage } from "components/svg-images";
import { LoadVideosMyAcademySvgImage } from "components/svg-images/LoadVideosMyAcademySvgImage";
import { isAfter, isBefore } from "date-fns";
import { useAppSelector } from "hooks/redux";
import { LessonCompetenciesList } from "pages/Course/components/LessonCompetenciesList";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { CourseCoinAmount } from "../../../../../../components/CourseCoinAmount";
import { CourseCoin } from "../../../../redux/interfaces/course.interface";
import { AchievementsListLesson } from "../../../AchievementsListLesson";
import { useVideoViewingTracking } from "../../hooks/useVideoViewingTracking";
import { MeetingType } from "../../redux";
import { getStatusLessonRus } from "../../tools/get-status-lesson-rus.tool";
import { StatusLessonType } from "../../types";
import { MeetingContent } from "../CourseMeetingStart/components/MeetingContent";
import styles from "./CourseMeetingEnd.module.scss";
import { RecordLinks } from "./components/RecordLinks";
import { Capacitor } from "@capacitor/core";

interface CourseMeetingEndProps {
  title?: string;
  isRecording?: boolean;
  status?: StatusLessonType;
  hasStartDate?: boolean;
  hasEndDate?: boolean;
  hasRecords?: boolean;
  records?: { url: string }[];
  coins?: CourseCoin;
  lessonId?: number;
  description?: string;
  meetingType: MeetingType;
  startDate?: Date;
  endDate?: Date;
  meetingUrl?: string;
  isConference: boolean;
}

export const CourseMeetingEnd = ({
  title = "",
  lessonId,
  isRecording = false,
  hasStartDate = false,
  hasEndDate = false,
  hasRecords = false,
  status,
  records = [],
  coins,
  description,
  meetingType,
  startDate,
  endDate,
  meetingUrl,
  isConference,
}: CourseMeetingEndProps) => {
  const statusForBadge = status === "completed" ? "success" : "base";
  const lessonIsCompleted = status === "completed";
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const { isLoadData, initializeVideos } = useVideoViewingTracking(
    lessonId,
    status,
    isConference,
    (records.length === 0 && isRecording) || !meetingUrl
  );

  const isCurDateAfterStartDate = startDate ? isAfter(Date.now(), startDate) : false;
  const isCurDateBeforeEndDate = endDate ? isBefore(Date.now(), endDate) : false;
  const isEnableCompetenciesInEsMobile =
    selectedLmsSettings.competences &&
    Capacitor.isNativePlatform() &&
    process.env.REACT_APP_THEME === "base";

  return (
    <Container>
      <div className="course-test-block">
        <div
          className={classNames("course-test-content course-webinar-content", {
            [styles["course-content_remove-max-width"]]: records.length === 0 && isRecording,
          })}
        >
          <h1 className={styles.title}>{title}</h1>

          <div className="course-test-top-info">
            {status && (
              <div className={styles["status-container"]}>
                <Badge color={statusForBadge} className={styles["status-badge"]}>
                  {lessonIsCompleted ? (
                    <DoneIcon className={styles["status-icon"]} />
                  ) : (
                    <CheckIcon className={styles["status-icon"]} />
                  )}
                  {getStatusLessonRus(status)}
                </Badge>
              </div>
            )}

            <CourseCoinAmount coin={coins} />
          </div>

          <AchievementsListLesson />
          {lessonId && (selectedLmsSettings.competences || isEnableCompetenciesInEsMobile) && (
            <LessonCompetenciesList id={lessonId} />
          )}

          {description &&
            isLoadData &&
            ((!startDate && !endDate) || isCurDateAfterStartDate || isCurDateBeforeEndDate) && (
              <MeetingContent data={description} onDataLoaded={initializeVideos} />
            )}

          <div className={styles["description-container"]}>
            <p className={styles.description}>
              Вебинар завершён.{" "}
              {records.length === 0 && isRecording && meetingType === MeetingType.educationStudio
                ? "Видео скоро будет загружено."
                : ""}
              {records.length > 0 &&
              ((isRecording && meetingType === MeetingType.educationStudio) ||
                !hasStartDate ||
                !hasEndDate)
                ? "Вам доступна запись вебинара."
                : ""}
            </p>
            <p className={styles.description}>
              {records.length > 0 && isRecording ? "Видео будет открыто в новом окне." : ""}
            </p>
          </div>
          <div className={styles["content-container"]}>
            {records.length > 0 && <RecordLinks records={records} />}
            {records.length === 0 &&
              isRecording &&
              (process.env.REACT_APP_THEME !== "myAcademy" ? (
                <LoadVideosSvgImage className={styles["load-video-image"]} />
              ) : (
                <LoadVideosMyAcademySvgImage className={styles["load-video-image"]} />
              ))}
          </div>
        </div>
        {(records.length !== 0 || !isRecording) && (
          <div className="course-test-img course-webinar-img">
            <CourseWebinarImg />
          </div>
        )}
      </div>
    </Container>
  );
};
