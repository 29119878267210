import { SVGProps } from "react";
export const CupSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={156}
    height={101}
    viewBox="0 0 156 101"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M66.592 80.998c-8.165 8.688-20.24 13.427-33.046 11.748C12.523 90.014-2.446 70.774.332 49.582 3.11 28.389 22.304 13.589 43.497 16.367a38.447 38.447 0 0 1 13.534 4.4l-2.74-17.265H155.86c-.499 2.163-3.679 29.069-9.542 80.718-.242 2.114-.773 3.964-1.64 5.717-.409.793-.77 1.417-1.445 2.498-1.661 2.617-4.188 4.44-7.123 5.783-2.503 1.102-4.475 1.629-13.327 2.002-4.041.116-35.565-.283-39.654 0-8.875-.179-13.665-7.827-13.93-8.813-.792-1.683-1.656-3.75-1.847-5.625l-.76-4.784Zm-30.939-4.91c12.08 1.605 23.1-7.045 24.66-18.956 1.56-11.91-7.046-23.1-18.956-24.659-11.91-1.56-23.1 7.045-24.66 18.956-1.48 12.293 7.046 23.1 18.956 24.659Z"
    />
    <path
      fill="#7D6BF2"
      d="M66.592 80.997c-8.165 8.689-20.24 13.427-33.046 11.749C12.523 90.014-2.446 70.774.332 49.581 3.11 28.388 22.304 13.59 43.497 16.367a38.447 38.447 0 0 1 13.534 4.4l-2.74-17.265H155.86c-.499 2.162-3.679 29.068-9.542 80.717-.242 2.115-.773 3.965-1.64 5.718-.409.792-.77 1.417-1.445 2.497-1.661 2.618-4.188 4.441-7.123 5.784-2.503 1.102-4.475 1.628-13.327 2.002-4.041.115-35.565-.284-39.654 0-8.875-.179-13.665-7.828-13.93-8.813-.792-1.683-1.656-3.751-1.847-5.626l-.76-4.784Zm-30.939-4.91c12.08 1.605 23.1-7.045 24.66-18.955 1.56-11.91-7.046-23.1-18.956-24.66-11.91-1.56-23.1 7.046-24.66 18.956-1.48 12.294 7.046 23.1 18.956 24.66Z"
      opacity={0.16}
    />
    <path
      fill="#43375D"
      d="M66.592 80.997c-8.165 8.689-20.24 13.427-33.046 11.749C12.523 90.014-2.446 70.774.332 49.581 3.11 28.388 22.304 13.59 43.497 16.367a38.447 38.447 0 0 1 13.534 4.4l-2.74-17.265H155.86c-.499 2.162-3.679 29.068-9.542 80.717-.242 2.115-.773 3.965-1.64 5.718-.409.792-.77 1.417-1.445 2.497-1.661 2.618-4.188 4.441-7.123 5.784-2.503 1.102-4.475 1.628-13.327 2.002-4.041.115-35.565-.284-39.654 0-8.875-.179-13.665-7.828-13.93-8.813-.792-1.683-1.656-3.751-1.847-5.626l-.76-4.784Zm-30.939-4.91c12.08 1.605 23.1-7.045 24.66-18.955 1.56-11.91-7.046-23.1-18.956-24.66-11.91-1.56-23.1 7.046-24.66 18.956-1.48 12.294 7.046 23.1 18.956 24.66Z"
      opacity={0.03}
    />
    <path
      fill="#fff"
      d="M105.076 7.005c28.047 0 50.784-1.568 50.784-3.503C155.86 1.568 133.123 0 105.076 0 77.028 0 54.291 1.568 54.291 3.502c0 1.935 22.737 3.503 50.785 3.503Z"
    />
    <path
      fill="#7D6BF2"
      d="M105.076 7.005c28.047 0 50.784-1.568 50.784-3.503C155.86 1.568 133.123 0 105.076 0 77.028 0 54.291 1.568 54.291 3.502c0 1.935 22.737 3.503 50.785 3.503Z"
      opacity={0.2}
    />
    <path
      fill="#43375D"
      d="M105.076 7.005c28.047 0 50.784-1.568 50.784-3.503C155.86 1.568 133.123 0 105.076 0 77.028 0 54.291 1.568 54.291 3.502c0 1.935 22.737 3.503 50.785 3.503Z"
      opacity={0.07}
    />
  </svg>
);
