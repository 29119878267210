import ky from "ky";
import { EndPoints } from "../app/endpoints";
import { Capacitor } from "@capacitor/core";
import { deleteCookie, setCookie } from "../services/cookie";
import { store } from "../app/store";
import { clear, logoutResponse } from "../features/auth/authSlice";

const KY = ky.create({
  prefixUrl: `${Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""}/api`,
  headers: {
    "Content-type": "application/json, charset=utf-8",
    "Cache-Control": "no-cache",
  },
  timeout: false,
  credentials: "include",
  hooks: {
    beforeRequest: [
      (request) => {
        const oidc = localStorage.getItem("oidc");
        if (oidc) {
          const parsedOidc = JSON.parse(oidc);

          if (parsedOidc.idToken) {
            parsedOidc.idToken &&
              setCookie({
                name: "jwt_authorization",
                value: parsedOidc.idToken,
                days: 1,
              });
            parsedOidc.refreshToken &&
              setCookie({
                name: "jwt_refresh",
                value: parsedOidc.refreshToken,
                days: 1,
              });
            request.headers.set("Authorization", `Bearer ${parsedOidc.idToken}`);
          }
        }

        return request;
      },
    ],
    afterResponse: [
      async (request, _, response) => {
        const oidc = localStorage.getItem("oidc");
        const parsedOidc = oidc ? JSON.parse(oidc) : null;
        const authUrls = [
          EndPoints.AUTH_REFRESH,
          EndPoints.AUTH_LOGIN,
          EndPoints.AUTH_LOGIN_ANONYMOUS,
        ];

        // Handle token removal and return response for specific URLs
        if (response.status === 400 && request.url.includes(EndPoints.AUTH_REFRESH)) {
          return response;
        }

        if (authUrls.some((url) => request.url.includes(url))) {
          return response;
        }

        if (request.headers.get("X-Retried")) {
          return response;
        }

        if (response.status === 401) {
          try {
            const url = `${
              Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
            }/api/${EndPoints.AUTH_REFRESH}`;

            const { headers } = await ky.post(url, {
              headers: {
                Refresh: parsedOidc.refreshToken || "",
                Authorization: parsedOidc.idToken ? `Bearer ${parsedOidc.idToken}` : "",
              },
            });

            const newAuthToken = headers.get("authorization") || "";
            const newRefreshToken = headers.get("refresh") || "";

            if (newAuthToken && newRefreshToken) {
              localStorage.setItem(
                "oidc",
                JSON.stringify({
                  idToken: newAuthToken.replace("Bearer ", ""),
                  refreshToken: newRefreshToken,
                })
              );

              newAuthToken &&
                setCookie({
                  name: "jwt_authorization",
                  value: newAuthToken,
                  days: 1,
                });

              newRefreshToken &&
                setCookie({
                  name: "jwt_refresh",
                  value: newRefreshToken,
                  days: 1,
                });
              request.headers.set("X-Retried", "true");

              return KY(request);
            }

            return response;
          } catch (error) {
            const { response } = error as { response: Response };

            if (response.status === 404) {
              const { dispatch } = store;
              dispatch(clear());
              dispatch(logoutResponse());
              deleteCookie("jwt_authorization");
              deleteCookie("jwt_refresh");
              return response;
            }

            return response;
          }
        }

        return response;
      },
    ],
  },
});

export { KY };
