import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const saveTaskAnswer = ({ id, formData }: { id: number; formData: FormData }) =>
  KY.post(EndPoints.TASK_FILE_ANSWER(id), {
    body: formData,
    headers: {
      "Content-Type": undefined,
    },
  }).json();
