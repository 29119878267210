import { SearchParamsOption } from "ky";
import { KY } from "../ky-instance";
import { EndPoints } from "../../app/endpoints";

export const getCourseFeedbacks = ({
  courseId,
  params,
  signal,
}: {
  courseId: number;
  params?: SearchParamsOption;
  signal?: AbortSignal;
}) =>
  KY.get(EndPoints.СOURSE_FEEDBACKS(courseId), {
    searchParams: params ? JSON.parse(JSON.stringify(params)) : params,
    signal,
  }).json();
