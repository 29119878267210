import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { СompetenciesTypeData } from "../../../../components/CompetenciesCard/competancies.types";
import { IBadgesRequest, IBadgesResponse } from "../../../Achievements/redux/interfaces";
import { IProfileLevel, IRatingLevelsResponse } from "../../../Rating/redux/interfaces";
import { Ctitzenship, KnownError, ProfileInitalState } from "./interface";
import {
  acceptAgreement,
  deleteProfileFile,
  getBadges,
  getCitizenship,
  getCoursesHistory,
  getEducationDocs,
  getProfile,
  getProfileClubs,
  getProfileCompetences,
  getRatingLevels,
  getWorkplaces,
  revokePersonalAgreement,
  saveEducationDocs,
  saveProfile,
} from "../../../../api";
import { SearchParamsOption } from "ky";

const initialState: ProfileInitalState = {
  isEdit: false,
  load: false,
  isLoadingPutProfile: false,
  isLoading: false,
  avatarIsLoading: true,
  avatar: {
    type: "preset",
    src: "12",
  },
  details: {
    firstname: "",
    middlename: "",
    lastname: "",
    birthdate: "",
    gender: "",
    email: "",
    mobile_phone: "",
    workplace_id: "",
    citizenship_id: "",
    residence_address: "",
    snils: "",
    assignment: "",
    policy_agreed: false,
    points: 0,
    change: 0,
  },
  requests: { personalAgreement: null },
  filesLoad: false,
  files: [],
  filesErrors: [],
  referencebook: {
    citizenship: [],
    workplace: [],
    gender: [
      {
        id: "male",
        name: "Мужской",
      },
      {
        id: "female",
        name: "Женский",
      },
    ],
  },
  achievesData: [],
  coursesHistory: { results: [], start_ts: 0 },
  coursesHistoryLoading: true,
  achievementData: {} as IBadgesResponse,
  competenceData: [],
  myLevel: {} as IProfileLevel,
  isLoadingMyLevels: true,
  clubs: [],
  newAvatarIsLoading: false,
};

const getReferencebookCtitzenship = createAsyncThunk<
  Ctitzenship[],
  void,
  { rejectValue: KnownError }
>("profile/getReferencebookCtitzenship", async (arg, { rejectWithValue }) => {
  try {
    const response = await (await getCitizenship()).json();

    //@ts-ignore
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      status: error.response.status,
      code: error.response.status,
      details: error.response.statusText,
    });
  }
});

const getReferencebookWorkplace = createAsyncThunk(
  "profile/getReferencebookWorkplace",
  async ({ only_visible }: { only_visible?: boolean }, { rejectWithValue }) => {
    try {
      return await getWorkplaces({ only_visible: only_visible ? 1 : 0 });
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getProfileFiles = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileFiles",
  async (arg, { rejectWithValue }) => {
    try {
      return await getEducationDocs();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const putProfileFile = createAsyncThunk<
  any,
  { formData: any; fileName: string },
  { rejectValue: KnownError }
>("profile/putProfileFile", async ({ formData, fileName }, { rejectWithValue }) => {
  try {
    return await saveEducationDocs(formData);
  } catch (error: any) {
    return rejectWithValue({
      fileName: fileName,
      status: error.response.status,
      code: error.response.data,
      details: error.response.data.error.details,
    });
  }
});

const deleteProfileFileThunkAction = createAsyncThunk<any, number, { rejectValue: KnownError }>(
  "profile/deleteProfileFile",
  async (fileId: number, { rejectWithValue }) => {
    try {
      return await deleteProfileFile(fileId);
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getProfileFields = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileFields",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(getReferencebookCtitzenship());
    dispatch(getReferencebookWorkplace({}));
    dispatch(getProfileFiles());
    try {
      return await getProfile();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getInitProfileFields = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getInitProfileFields",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(getReferencebookCtitzenship());
    dispatch(getReferencebookWorkplace({}));
    dispatch(getProfileFiles());
    try {
      return await getProfile();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const putProfileFields = createAsyncThunk<any, FormData, { rejectValue: KnownError }>(
  "profile/putProfileField",
  async (payload, { rejectWithValue }) => {
    try {
      return await saveProfile(payload).json();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.data.error.details,
      });
    }
  }
);

const putProfileAgreement = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/putProfileAgreement",
  async (_, { rejectWithValue }) => {
    try {
      return await acceptAgreement();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.data.error.details,
      });
    }
  }
);

const putRevokePersonalAgreement = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/putRevokePersonalAgreement",
  async (_, { rejectWithValue }) => {
    try {
      return await revokePersonalAgreement();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const getProfileCoursesHistory = createAsyncThunk("profile/courses-history", async () => {
  try {
    return await getCoursesHistory();
  } catch (error: any) {
    // return rejectWithValue({
    //   code: error.response.status,
    //   status: error.response.status,
    //   details: error.response.data,
    // });
  }
});

const getProfileBadgesThunkAction = createAsyncThunk(
  "profile/getBadges",
  async (params: IBadgesRequest) => await getBadges(params as SearchParamsOption).json()
);

const getProfileCompetence = createAsyncThunk(
  "profile/getCompetence",
  async () => await getProfileCompetences()
);

const getRatingLevelsThunkAction = createAsyncThunk(
  "profile/getRatingLevels",
  async () => await getRatingLevels()
);

const getUserClubs = createAsyncThunk("profile/getUserClubs", async (_, thunkAPI) => {
  try {
    return await getProfileClubs();
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      code: error.response.status,
      details: error.response.statusText,
    });
  }
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    runPersonalAgreement(state) {
      state.requests = { personalAgreement: "new" };
    },
    clearFilesError(state) {
      state.filesErrors = [];
    },
    setLoadingNewAvatar(state, action) {
      state.newAvatarIsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitProfileFields.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getInitProfileFields.fulfilled, (state, action) => {
        state.details = Object.assign({}, action.payload.data);
        if (!state.details.birthdate) {
          state.details.birthdate = "";
        }
        state.load = true;
        state.isLoading = false;
      })
      .addCase(getInitProfileFields.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfileFields.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfileFields.fulfilled, (state, action) => {
        state.details = Object.assign({}, action.payload.data);
        if (!state.details.birthdate) {
          state.details.birthdate = "";
        }
        state.requests = action.payload.data.requests;
        state.load = true;
        state.isLoading = false;
      })
      .addCase(getProfileFields.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReferencebookCtitzenship.fulfilled, (state, action) => {
        state.referencebook.citizenship = action.payload;
        if (!state.referencebook.citizenship?.length) {
          state.referencebook.citizenship = [];
        }
      })
      .addCase(getReferencebookCtitzenship.rejected, (state, action) => {
        state.referencebook.citizenship = [];
      })
      .addCase(getReferencebookWorkplace.fulfilled, (state, action) => {
        //@ts-ignore
        state.referencebook.workplace = action.payload ?? [];
        if (!state.referencebook.workplace.length) {
          state.referencebook.workplace = [];
        }
      })
      .addCase(getReferencebookWorkplace.rejected, (state, action) => {
        state.referencebook.workplace = [];
      })
      .addCase(putProfileFields.pending, (state) => {
        state.isLoadingPutProfile = true;
      })
      .addCase(putProfileFields.fulfilled, (state) => {
        state.isLoadingPutProfile = false;
      })
      .addCase(putProfileFields.rejected, (state, { payload }) => {
        state.isLoadingPutProfile = false;
        if (payload?.status === 400 && Array.isArray(payload?.details)) {
          state.filesErrors = [];
          payload.details.forEach((item) => {
            state.filesErrors.push({
              name: item.file,
              error: item.errors?.[0],
            });
          });
        }
      })
      .addCase(getProfileFiles.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.files = payload.data;
        }
        state.filesLoad = true;
      })
      .addCase(getProfileFiles.rejected, (state, action) => {})

      .addCase(putProfileFile.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.files.push(payload?.data);
        }
      })
      .addCase(putProfileFile.rejected, (state, { payload }) => {
        state.filesErrors = [];
        if (payload?.status === 400) {
          state.filesErrors.push({
            name: payload?.fileName ? payload?.fileName : "",
            error: payload?.details?.error_codes?.[0],
          });
        }
      })
      .addCase(deleteProfileFileThunkAction.fulfilled, (state, action) => {
        const currentState = current(state.files);
        if (action?.payload?.data?.id) {
          state.files = currentState.filter((item) => {
            return item.id !== parseInt(action?.payload?.data?.id);
          });
        }
      })
      .addCase(deleteProfileFileThunkAction.rejected, (state, action) => {})
      .addCase(getProfileCoursesHistory.pending, (state) => {
        state.coursesHistoryLoading = true;
      })
      .addCase(getProfileCoursesHistory.fulfilled, (state, action: any) => {
        if (action?.payload?.data) {
          state.coursesHistory = action.payload.data ?? { results: [], start_ts: 0 };
        }
        state.coursesHistoryLoading = false;
      })
      .addCase(getProfileCoursesHistory.rejected, (state) => {
        state.coursesHistoryLoading = false;
      })
      .addCase(
        getProfileBadgesThunkAction.fulfilled,
        (state, action: PayloadAction<IBadgesResponse | any>) => {
          state.achievementData = action.payload || {};
        }
      )
      .addCase(
        getProfileCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData | any>) => {
          state.competenceData = action.payload;
        }
      )
      .addCase(getRatingLevelsThunkAction.pending, (state) => {
        state.isLoadingMyLevels = true;
      })
      .addCase(
        getRatingLevelsThunkAction.fulfilled,
        (state, action: PayloadAction<IRatingLevelsResponse | any>) => {
          state.myLevel = action.payload?.data || {};
          state.isLoadingMyLevels = false;
        }
      )
      .addCase(getRatingLevelsThunkAction.rejected, (state) => {
        state.isLoadingMyLevels = false;
      })
      .addCase(getUserClubs.fulfilled, (state, action: any) => {
        state.clubs = action.payload.data;
      });
  },
});

export const { runPersonalAgreement, clearFilesError, setLoadingNewAvatar } = profileSlice.actions;
export {
  deleteProfileFileThunkAction,
  getProfileBadgesThunkAction,
  getInitProfileFields,
  getProfileCompetence,
  getProfileCoursesHistory,
  getProfileFields,
  getRatingLevelsThunkAction,
  getReferencebookWorkplace,
  getUserClubs,
  putProfileAgreement,
  putProfileFields,
  putProfileFile,
  putRevokePersonalAgreement,
};
export default profileSlice.reducer;
