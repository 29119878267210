import { SVGProps } from "react";

export const CapybaraSupermanSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={326}
    height={317}
    viewBox="0 0 326 317"
    fill="none"
    {...props}
  >
    <path
      fill="#00AEFF"
      d="m139.006 264.691-35.017-1.083c-10.628-12.794-18.96-15.472-24.923-15.169-4.78.245-8.787 2.101-12.202-.08-4.145-2.642-2.794-9.176-5.394-13.342-1.74-2.802-5.133-4.202-8.425-4.448-3.285-.252-6.556.47-9.812 1.011-7.783 1.292-15.906 1.56-23.422-.83s-14.368-7.747-17.14-15.126c8.671 1.538 20.736 2.512 34.612-.058 32.808-6.072 52.779-27.703 59.486-35.71l20.678-10.671 47.999-22.238 10.981 26.036 15.646 48.749a118.775 118.775 0 0 0-20.685 28.757 118.347 118.347 0 0 0-9.921 27.083c-3.27-4.065-3.22-10.881-7.934-13.112-1.596-.751-3.733-.643-4.91-.671a25.151 25.151 0 0 0-8.527 1.256"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m139.006 264.691-35.017-1.083c-10.628-12.794-18.96-15.472-24.923-15.169-4.78.245-8.787 2.101-12.202-.08-4.145-2.642-2.794-9.176-5.394-13.342-1.74-2.802-5.133-4.202-8.425-4.448-3.285-.252-6.556.47-9.812 1.011-7.783 1.292-15.906 1.56-23.422-.83s-14.368-7.747-17.14-15.126c8.671 1.538 20.736 2.512 34.612-.058 32.808-6.072 52.779-27.703 59.486-35.71l20.678-10.671 47.999-22.238 10.981 26.036 15.646 48.749a118.775 118.775 0 0 0-20.685 28.757 118.347 118.347 0 0 0-9.921 27.083c-3.27-4.065-3.22-10.881-7.934-13.112-1.596-.751-3.733-.643-4.91-.671a25.151 25.151 0 0 0-8.527 1.256"
    />
    <path
      fill="#fff"
      d="m143.699 152.422 18.05 31.046h25.992l-12.996-37.544-12.274-10.83-18.772 17.328Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M245.089 111.961c3.784-2.751 9.892-8.043 13.704-16.65 1.863-4.209 2.657-7.53 3.162-11.61a98.468 98.468 0 0 0-.671-28.612c-.931-5.43-2.361-10.88-5.336-15.516-2.974-4.635-7.703-8.382-13.169-9.097-8.094-1.061-15.09 3.509-17.992 5.487-11.798 8.058-31.508 18.556-61.594 32.707-5.364-3.35-13.971-7.855-19.855-10.166-.679-.267-1.401-.527-2.115-.383-.679.138-1.242.643-1.603 1.228-1.452 2.368.101 5.43 1.877 7.566 3.762 4.52 8.7 8.05 14.194 10.144 0 0 .239 7.502.997 11.827 2.44 13.949 7.213 25.364 11.812 33.927"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M172.731 74.257a36.385 36.385 0 0 1 15.082.535M173.655 82.373a82.08 82.08 0 0 1 16.895.31c-.325.902-1.235 2.982-3.293 3.95-1.761.83-4.007.67-6.122-.405M218.426 40.15c-.657 6.744-1.379 23.502 8.014 41.364 5.191 9.877 11.783 16.873 17.22 21.523M250.555 54.951c4.765 10.289 4.772 18.548 4.332 23.104-.412 4.238-.989 10.224-4.917 11.863-2.996 1.249-6.455-.65-7.826-1.51M262.107 79.498c2.809-3.502 34.605-43.038 41.103-46.952 1.011-.606 3.733-2.324 5.827-1.227.917.477 1.531 1.38 2.05 2.267 3.033 5.191 4.152 11.458 3.112 17.379-1.242 7.06-4.332 12.086-5.227 13.443-14.411 21.891-38.706 40.432-54.814 67.067a129.833 129.833 0 0 0-7.22 13.718c-.773 11.538-8.152 37.616-19.249 47.529"
    />
    <path
      fill="#fff"
      d="M120.198 156.933a50.8 50.8 0 0 0-5.379-18.815c-1.393-2.729-3.184-5.466-5.949-6.787-3.906-1.87-8.816-.274-11.82 2.852-3.003 3.126-4.404 7.451-5.162 11.711-2.021 11.277-.08 23.227 5.394 33.291 1.047 1.928 2.26 3.834 3.985 5.191 4.188 3.307 10.267 2.636 15.292.852 6.823-2.418 12.924-6.505 18.736-10.822a231.954 231.954 0 0 0 25.595-22.065"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M120.198 156.933a50.8 50.8 0 0 0-5.379-18.815c-1.393-2.729-3.184-5.466-5.949-6.787-3.906-1.87-8.816-.274-11.82 2.852-3.003 3.126-4.404 7.451-5.162 11.711-2.021 11.277-.08 23.227 5.394 33.291 1.047 1.928 2.26 3.834 3.985 5.191 4.188 3.307 10.267 2.636 15.292.852 6.823-2.418 12.924-6.505 18.736-10.822a231.954 231.954 0 0 0 25.595-22.065M180.528 141.793c4.202 1.624 6.542 6.18 7.466 10.592a29.492 29.492 0 0 1-1.459 16.858M185.698 137.273c5.963 3.011 10.606 8.542 12.526 14.939 1.928 6.397 1.105 13.573-2.209 19.371M193.56 131.279c6.181 2.874 9.523 9.56 11.949 15.92 1.545 4.058 2.925 8.224 3.329 12.549.404 4.325-.217 8.844-2.448 12.57M207.329 129.266c10.866 11.869 14.859 29.616 10.137 44.995"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.149 168.224c5.711-11.754 14.649-21.913 25.58-29.075 4.195-2.751 8.867-5.119 13.877-5.278M197.293 170.326c3.993-8.852 11.076-15.927 18.577-22.093 4.83-3.964 10.419-7.82 16.664-7.639M206.744 171.012c5.235-10.476 15.797-18.115 27.385-19.797M217.87 172.983c3.263-5.011 8.686-8.556 14.577-9.538"
    />
    <path
      fill="#191229"
      d="M182.326 83.83c0 1.394 1.776 2.527 3.971 2.527 2.195 0 3.971-1.133 3.971-2.527 0-1.393-1.213-1.805-3.401-1.805-2.187 0-4.541.412-4.541 1.805Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M255.248 78.416c-.809 3.971 1.025 6.946 2.888 7.22 1.776.267 3.451-1.957 3.61-2.166"
    />
    <path
      fill="#191229"
      d="M237.588 46.787c1.216-1.824 1.382-3.85.37-4.525-1.012-.675-2.818.258-4.035 2.082-1.216 1.825-1.382 3.851-.37 4.526 1.012.675 2.818-.258 4.035-2.083Z"
    />
    <path
      fill="#fff"
      d="M149.475 163.252a24.94 24.94 0 0 1 2.166 3.61c3.133 6.382 4.108 15.27-1.798 31.963-5.704 16.108-13.494 27.277-15.53 30.129-12.274 17.234-21.234 22.173-38.59 45.775-5.705 7.754-11.279 16.122-8.34 21.371 1.928 3.451 6.75 4.159 7.942 4.332 14.75 2.159 28.31-17.068 60.648-53.428a1334.388 1334.388 0 0 1 22.382-24.548l.209-.101c6.571-.137 13.242-.347 20.007-.621 5.112-.209 10.166-.448 15.162-.722a99.218 99.218 0 0 1-5.054 12.996c-3.177 6.729-5.552 10.447-11.552 20.216-12.209 19.884-13.797 23.768-12.274 27.436 1.48 3.559 5.473 5.935 8.664 6.498 7.913 1.401 23.443-17.205 32.338-28.916a105.857 105.857 0 0 0 10.635-17.198l3.098-6.289-.137.318c7.978-18.122 11.689-35.212 6.05-40.331-1.921-1.747-5.133-2.116-11.552-2.888-9.826-1.184-17.812.708-22.382 2.166"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M149.475 163.252a24.94 24.94 0 0 1 2.166 3.61c3.133 6.382 4.108 15.27-1.798 31.963-5.704 16.108-13.494 27.277-15.53 30.129-12.274 17.234-21.234 22.173-38.59 45.775-5.705 7.754-11.279 16.122-8.34 21.371 1.928 3.451 6.75 4.159 7.942 4.332 14.75 2.159 28.31-17.068 60.648-53.428a1334.388 1334.388 0 0 1 22.382-24.548l.209-.101c6.571-.137 13.242-.347 20.007-.621 5.112-.209 10.166-.448 15.162-.722a99.218 99.218 0 0 1-5.054 12.996c-3.177 6.729-5.552 10.447-11.552 20.216-12.209 19.884-13.797 23.768-12.274 27.436 1.48 3.559 5.473 5.935 8.664 6.498 7.913 1.401 23.443-17.205 32.338-28.916a105.857 105.857 0 0 0 10.635-17.198l3.098-6.289-.137.318c7.978-18.122 11.689-35.212 6.05-40.331-1.921-1.747-5.133-2.116-11.552-2.888-9.826-1.184-17.812.708-22.382 2.166"
    />
    <path
      fill="#fff"
      d="M119.151 149.534c4.036-1.068 6.91-2.556 8.664-3.61 3.22-1.927 5.646-4.151 9.754-8.656"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M119.151 149.534c4.036-1.068 6.91-2.556 8.664-3.61 3.22-1.927 5.646-4.151 9.754-8.656"
    />
    <path
      fill="#fff"
      d="M165.446 118.697c-3.769-.188-7.733-.34-11.112 1.336-2.635 1.314-4.216 3.061-5.314 4.303l-2.029 2.296-1.862 2.115-1.322 1.495c-2.498 2.837-4.519 5.126-6.245 7.017"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.446 118.697c-3.769-.188-7.733-.34-11.112 1.336-2.635 1.314-4.216 3.061-5.314 4.303l-2.029 2.296-1.862 2.115-1.322 1.495c-2.498 2.837-4.519 5.126-6.245 7.017"
    />
  </svg>
);
