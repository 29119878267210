import { Capacitor, CapacitorCookies } from "@capacitor/core";

interface SetCookieProp {
  name: string;
  value: string;
  days?: number;
  path?: string;
}

export const setCookie = ({ name, value, days, path = "/" }: SetCookieProp) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  Capacitor.getPlatform() === "ios"
    ? CapacitorCookies.setCookie({
        key: name,
        value,
        expires,
        path,
      })
    : (document.cookie = name + "=" + (value || "") + expires + "; path=" + path);
};
