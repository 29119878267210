import { NotificationProps } from "./interfaces";

export const notificationReducer = (
  state: any[],
  action: { type: string; payload: NotificationProps }
) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return [...state, { ...action.payload }];
    case "REMOVE_NOTIFICATION":
      return [...state.filter((el) => el.id !== action.payload.id)];
    default:
      return state;
  }
};
