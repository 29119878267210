import { CourseCompetence } from "pages/Courses/redux/interface";

export interface TransformedCompetence {
  name: string;
  attributes: {
    id: number;
    parentId: number | null;
  };
  children: TransformedCompetence[] | null;
}

export const transformCompetence = (
  items: CourseCompetence[],
  parentId: number = 0
): TransformedCompetence[] | null => {
  const children = items
    .filter((item) => item.parent_id === parentId)
    .map((item) => ({
      attributes: {
        id: item.id,
        parentId: item.parent_id,
      },
      name: item.name,
      children: transformCompetence(items, item.id),
    }));

  return children.length > 0 ? children : null;
};
