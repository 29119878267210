import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

interface AuthData {
  login: string;
  password: string;
  mobile_application: boolean;
}

export const auth = (data: AuthData) =>
  KY.post(EndPoints.AUTH_LOGIN, {
    body: JSON.stringify(data),
  });
