import { SVGProps } from "react";

export const StarFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.505 2.602C5.17 1.409 5.503.812 6 .812c.497 0 .83.597 1.495 1.79l.172.309c.189.339.283.508.43.62.148.112.331.153.698.236l.334.076c1.292.292 1.937.438 2.09.932.155.494-.286 1.009-1.166 2.038l-.228.267c-.25.292-.375.438-.431.62-.057.18-.038.375 0 .766l.035.355c.133 1.374.2 2.06-.203 2.366-.402.305-1.007.027-2.216-.53l-.313-.144c-.343-.158-.515-.238-.697-.238-.182 0-.354.08-.697.238l-.313.144c-1.21.557-1.814.835-2.216.53-.402-.306-.336-.992-.203-2.366l.035-.355c.038-.39.057-.586 0-.767-.056-.18-.181-.327-.431-.62l-.228-.266C1.067 5.783.627 5.27.78 4.775c.154-.494.8-.64 2.09-.932l.335-.076c.367-.083.55-.124.698-.236.147-.112.241-.281.43-.62l.172-.31Z"
    />
  </svg>
);
