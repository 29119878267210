import { SearchParamsOption } from "ky";
import { KY } from "../ky-instance";
import { EndPoints } from "../../app/endpoints";

export const getCourses = ({
  params,
  signal,
}: {
  params: SearchParamsOption;
  signal: AbortSignal;
}) =>
  KY.get(EndPoints.COURSES_LIST, {
    searchParams: params ? JSON.parse(JSON.stringify(params)) : params,
    signal,
  }).json();
