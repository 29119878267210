import { SVGProps } from "react";

export const СapybaraThumbUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={256}
    height={237}
    viewBox="0 0 256 237"
    fill="none"
    {...props}
  >
    <path
      fill="#E7F7FF"
      d="M207.891 65.193c7.796-114.287-145.985-57.12-184.8 12.651-38.816 69.773-9.913 81.647 37.209 135.407 47.122 53.76 116.077 5.783 159.061-40.301 47.148-50.546-14.41-64.656-11.47-107.757Z"
    />
    <path
      fill="#fff"
      d="m76.104 110.98 11.782 2.104 2.524 12.203c.28 4.068.926 12.54 1.262 13.886.421 1.683 0 1.683 12.203 9.258 12.203 7.574 26.089 10.519 36.188 11.782 10.099 1.262 25.247-4.629 31.559-6.312 6.312-1.683 10.52 36.188 2.104 37.45-8.416 1.262-28.193 2.104-43.762 0-15.569-2.104-43.762-15.569-48.39-17.252-3.703-1.347-18.655-8.977-25.669-12.624l-2.104-5.891c-.14-3.787-.588-11.782-1.261-13.465-.842-2.104 7.995-23.144 8.836-24.827.673-1.346 10.1-4.769 14.728-6.312Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M51.608 159.83c12.841 6.352 41.54 20.154 66.488 28.284 4.038 1.316 9.59 3.01 17.011 3.93a86.116 86.116 0 0 0 22.365-.163"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M157.466 191.881a93.003 93.003 0 0 0 12.517-2.608"
    />
    <path
      fill="#fff"
      d="M249.3 199.487a2345.867 2345.867 0 0 1-1.803-43.865s-.391-12.282-1.256-29.425c3.089 2.716 6.063 3.587 6.063 3.587-1.358-2.091-3.112-9.59-6.61-15.623-.09-1.67-.066.679-.162-1.051l7.253 2.854-8.112-17.221-.012.348c-.241-3.563-.493-7.162-.763-10.768-.625-8.292-1.226-13.772-2.897-20.394-1.171-4.645-1.712-5.732-2.439-9.788-.865-4.807 3.317-3.63 6.111-7.638 2.788-4.008 4.416-9.361 2.331-13.784-.691.216-1.394.463-2.097.751-6.946 2.824-11.393 8.148-14.067 12.403l-3.545 4.308c-8.466-6.586-19.108-13.514-32.069-19.343a156.36 156.36 0 0 0-12.018-4.807l-8.424 92.116c.613 8.322.132 18.766-2.842 32.25a63.201 63.201 0 0 1-.685 2.794l-15.281 11.507 7.758-1.989-11.171 17.756 7.349-2.332a318.544 318.544 0 0 0-7.145 14.602 429.859 429.859 0 0 0-9.818 23.188"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M249.3 199.487a2345.867 2345.867 0 0 1-1.803-43.865s-.391-12.282-1.256-29.425c3.089 2.716 6.063 3.587 6.063 3.587-1.358-2.091-3.112-9.59-6.61-15.623-.09-1.67-.066.679-.162-1.051l7.253 2.854-8.112-17.221-.012.348c-.241-3.563-.493-7.162-.763-10.768-.625-8.292-1.226-13.772-2.897-20.394-1.171-4.645-1.712-5.732-2.439-9.788-.865-4.807 3.317-3.63 6.111-7.638 2.788-4.008 4.416-9.361 2.331-13.784-.691.216-1.394.463-2.097.751-6.946 2.824-11.393 8.148-14.067 12.403l-3.545 4.308c-8.466-6.586-19.108-13.514-32.069-19.343a156.36 156.36 0 0 0-12.018-4.807l-8.424 92.116c.613 8.322.132 18.766-2.842 32.25a63.201 63.201 0 0 1-.685 2.794l-15.281 11.507 7.758-1.989-11.171 17.756 7.349-2.332a318.544 318.544 0 0 0-7.145 14.602 429.859 429.859 0 0 0-9.818 23.188"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M222.873 61.267c-.679-3.077-3.083-5.853-6.183-6.418-3.095-.565-6.598 1.55-7.061 4.663 0 0 2.512.637 4.188.901 5.625.884 11.189 2.458 16.609 4.183M206.204 40.417c-.384-.805-2.77-5.973-.276-10.894a10.812 10.812 0 0 1 2.782-3.437c1.821 1.021 3.016 2.86 4.134 4.62 1.574 2.488 3.179 5.036 3.864 7.902.402 1.677.973 4.717.132 8.713"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M118.307 32.444c-10.029 9.313-7.71 25.777-3.606 52.277 5.066 32.688 7.595 49.026 21.031 56.483 11.778 6.537 27.737 4.699 38.457-2.404 2.686-1.778 11.23-7.992 19.228-39.658 8.743-34.623 13.142-52.019 3.005-63.694-10.972-12.637-32.478-11.086-52.878-9.614-12.883.931-19.643 1.418-25.237 6.61Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M126.154 115.781c3.858 1.844 8.382 3.707 12.246 1.874 4.357-2.073 4.939-7.733 6.117-12.408 3.684-14.614 6.778-36.36 7.439-52.403-.763 5.258-.372 44.231 1.731 54.903.721 3.653.228 8.07 3.293 10.185 1.508 1.045 3.419 1.304 5.251 1.388 8.25.396 16.615-2.121 23.267-7.013"
    />
    <path
      fill="#191229"
      d="M139.084 50.154c1.126-5.09.326-9.596-1.787-10.063-2.113-.467-4.738 3.28-5.864 8.371-1.126 5.091-.325 9.596 1.787 10.064 2.113.467 4.738-3.281 5.864-8.372ZM174.097 58.294c2.026-.336 3.013-4.563 2.204-9.44-.81-4.879-3.108-8.56-5.135-8.224-2.026.336-3.013 4.563-2.204 9.44.809 4.879 3.108 8.56 5.135 8.224ZM219.129 60.406c-.565 1.76-2.181.913-4.422.192-2.248-.72-4.273-1.033-3.708-2.794.565-1.76 2.842-2.608 5.09-1.887 2.247.721 3.611 2.728 3.046 4.489h-.006Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M171.785 142.404c8.263-.288 16.14-3.154 27.641-6.009a146.233 146.233 0 0 0 27.04-9.614"
    />
    <path
      fill="#fff"
      d="M44.998 93.134c5.817-24.294 6.142-32.508 5.036-35.008-.144-.33-.853-2.253-.661-4.603.126-1.55.943-3.16 2.398-3.725.793-.307 1.682-.27 2.523-.157 2.71.373 11.58 5.583 14.926 15.93 3.257 10.053-.739 19-1.995 21.548"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M44.998 93.134c5.817-24.294 6.142-32.508 5.036-35.008-.144-.33-.853-2.253-.661-4.603.126-1.55.943-3.16 2.398-3.725.793-.307 1.682-.27 2.523-.157 2.71.373 11.58 5.583 14.926 15.93 3.257 10.053-.739 19-1.995 21.548"
    />
    <path
      fill="#fff"
      d="M64.521 125.355a111.052 111.052 0 0 0 22.293 2.23c1.304 0 2.644-.024 3.87.42 2.307.836 3.767 3.666 3.785 6.111.03 3.455-2.98 6.869-7.408 7.692"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M64.521 125.355a111.052 111.052 0 0 0 22.293 2.23c1.304 0 2.644-.024 3.87.42 2.307.836 3.767 3.666 3.785 6.111.03 3.455-2.98 6.869-7.408 7.692"
    />
    <path
      fill="#fff"
      d="M65.88 109.343a108.398 108.398 0 0 1 17.13-1.448c4.038-.018 8.671.499 10.979 3.81 1.76 2.523 1.201 8.148.925 9.836-.775 4.741-3.677 5.342-4.849 6.436"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M65.88 109.343a108.398 108.398 0 0 1 17.13-1.448c4.038-.018 8.671.499 10.979 3.81 1.76 2.523 1.201 8.148.925 9.836-.775 4.741-3.677 5.342-4.849 6.436"
    />
    <path
      fill="#fff"
      d="M84.657 108.153c5.138-2.296 8.46-7.175 8.394-12.3 0 0-.042-3.492-5.396-7.926-1.003-.836-2.127-1.563-3.383-1.905-1.376-.373-2.83-.265-4.248-.138a184.907 184.907 0 0 0-30.94 5.432c-1.436.378-3.04.92-3.647 2.277l-2.193-.12c-3.696.685-6.051 4.645-6.177 8.4-.132 3.756 1.43 7.343 2.98 10.762-4.158 4.363-3.683 12.252.968 16.086-2.416 1.851-3.6 5.167-2.909 8.13.691 2.962 3.233 5.408 6.213 5.991-4.068 3.04-4.494 9.848-.84 13.375 2.114 2.037 5.083 2.891 7.961 3.462 11.279 2.247 23.17 1.321 33.968-2.644 3.197-1.172 6.766-5.655 6.964-9.05.15-2.602-1.916-4.915-4.326-5.9-2.692-1.106-4.993-1.1-7.703-.884-3.401.271-8.305.523-14.578.337"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M84.657 108.153c5.138-2.296 8.46-7.175 8.394-12.3 0 0-.042-3.492-5.396-7.926-1.003-.836-2.127-1.563-3.383-1.905-1.376-.373-2.83-.265-4.248-.138a184.907 184.907 0 0 0-30.94 5.432c-1.436.378-3.04.92-3.647 2.277l-2.193-.12c-3.696.685-6.051 4.645-6.177 8.4-.132 3.756 1.43 7.343 2.98 10.762-4.158 4.363-3.683 12.252.968 16.086-2.416 1.851-3.6 5.167-2.909 8.13.691 2.962 3.233 5.408 6.213 5.991-4.068 3.04-4.494 9.848-.84 13.375 2.114 2.037 5.083 2.891 7.961 3.462 11.279 2.247 23.17 1.321 33.968-2.644 3.197-1.172 6.766-5.655 6.964-9.05.15-2.602-1.916-4.915-4.326-5.9-2.692-1.106-4.993-1.1-7.703-.884-3.401.271-8.305.523-14.578.337"
    />
    <path
      fill="#00AEFF"
      d="M126.839 49.566c-30.284 2.091-31.979 3.786-34.07 34.07-2.091-30.284-3.786-31.979-34.07-34.07 30.284-2.09 31.979-3.785 34.07-34.07 2.091 30.285 3.785 31.98 34.07 34.07Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M126.839 49.566c-30.284 2.091-31.979 3.786-34.07 34.07-2.091-30.284-3.786-31.979-34.07-34.07 30.284-2.09 31.979-3.785 34.07-34.07 2.091 30.285 3.785 31.98 34.07 34.07Z"
    />
    <path
      fill="#FF8B00"
      d="M69.154 179.961c-30.284 2.091-31.979 3.785-34.07 34.07-2.091-30.285-3.786-31.979-34.07-34.07 30.284-2.091 31.979-3.786 34.07-34.07 2.091 30.284 3.785 31.979 34.07 34.07Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M69.154 179.961c-30.284 2.091-31.979 3.785-34.07 34.07-2.091-30.285-3.786-31.979-34.07-34.07 30.284-2.091 31.979-3.786 34.07-34.07 2.091 30.284 3.785 31.979 34.07 34.07Z"
    />
  </svg>
);
