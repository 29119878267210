import { IProfile } from "../../pages/Profile/redux/profile-slice/interface";

// interface ProfileCheck {
//   [n: string]: string | boolean | object | null;
//   lastname: string | null;
//   firstname: string | null;
//   middlename: string | null;
//   birthdate: string | null;
//   gender: string | null;
//   email: string | null;
//   mobile_phone: string | null;
//   workplace_id: string | null;
//   citizenship_id: string | null;
//   residence_address: string | null;
//   snils: string | null;
//   policy_agreed: boolean;
//   requests: { personalAgreement: number | null };
// }

const requiredFields = [
  "lastname",
  "firstname",
  "middlename",
  "workplace_id",
  "mobile_phone",
  "gender",
  "citizenship_id",
  "snils",
  "birthdate",
  "residence_address",
  "policy_agreed",
];

export const checkFullnessProfileUtil = (profile: IProfile, educationDocs: any[]) => {
  const result = { profileValid: true, educationDocsValid: true };

  const isEducationDocs = educationDocs.length > 0;
  if (!isEducationDocs) {
    result.educationDocsValid = false;
  }

  for (const requiredField of requiredFields) {
    if (!profile[requiredField]) {
      result.profileValid = false;
      break;
    }
  }

  return result;
};
