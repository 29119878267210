import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const getCourseCompetences = ({
  courseId,
  signal,
}: {
  courseId: number;
  signal: AbortSignal;
}) => KY.get(EndPoints.СOURSE_COMPETENCE(courseId), { signal }).json();
