import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

interface GetMulticourseContentSectionsProps {
  id: number;
  signal?: AbortSignal;
}

export const getMulticourseContentSections = ({ id, signal }: GetMulticourseContentSectionsProps) =>
  KY.get(EndPoints.MULTICOURSE_PAGE_CONTENT_SECTIONS(id), { signal }).json();
