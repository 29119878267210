import { avatarsApi } from "../avatars.api";

const deleteAvatarApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    deleteAvatar: build.mutation<void, { imageId: number }>({
      query: ({ imageId }) => ({
        url: "profile/images",
        method: "DELETE",
        body: { image_id: imageId },
      }),
      invalidatesTags: (result, error, { imageId }) => [
        { type: "updateAvatars", id: imageId },
        { type: "updateAvatar" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useDeleteAvatarMutation } = deleteAvatarApi;
