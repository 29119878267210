import { MouseEvent } from "react";

interface CreateRippleParams {
  clientWidth: number;
  clientHeight: number;
  clientX: number;
  clientY: number;
  offsetLeft: number;
  offsetTop: number;
}

const useRipple = () => {
  const createRipple = ({
    clientWidth,
    clientHeight,
    clientX,
    clientY,
    offsetLeft,
    offsetTop,
  }: CreateRippleParams) => {
    const circle = document.createElement("span");
    const diameter = Math.max(clientWidth, clientHeight);
    const radius = diameter / 2;

    circle.style.position = "absolute";
    circle.style.borderRadius = "50%";
    circle.style.transform = "scale(0)";
    circle.style.animation = "ripple 600ms linear";
    circle.style.backgroundColor = "rgba(255, 255, 255, 0.3)";
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${clientX - offsetLeft - radius}px`;
    circle.style.top = `${clientY - offsetTop - radius}px`;
    circle.style.zIndex = "100";
    circle.classList.add("ripple");

    return circle;
  };

  const addRipple = (event: MouseEvent<HTMLElement>) => {
    const targetClickedElement = event.currentTarget;
    const { left: offsetLeft, top: offsetTop } = event.currentTarget.getBoundingClientRect();
    targetClickedElement.style.position = "relative";
    targetClickedElement.style.overflow = "hidden";
    const rippleParams = {
      clientWidth: targetClickedElement.clientWidth,
      clientHeight: targetClickedElement.clientHeight,
      clientX: event.clientX,
      clientY: event.clientY,
      offsetLeft,
      offsetTop,
    };
    const ripple = createRipple(rippleParams);

    const currentRipple = targetClickedElement.getElementsByClassName("ripple")[0];
    if (currentRipple) {
      currentRipple.remove();
    }
    targetClickedElement.appendChild(ripple);
  };

  return addRipple;
};

export { useRipple };
