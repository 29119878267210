import { useHover } from "ahooks";
import classNames from "classnames";
import { CloseIcon } from "components/Icons";
import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Tooltip.module.scss";

interface TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  showCloseIcon?: boolean;
  className?: string;
  wrapperClassName?: string;
}

export const Tooltip = ({
  children,
  className,
  content = "",
  showCloseIcon = false,
  wrapperClassName,
}: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHover(ref);

  const [isShow, setIsShow] = useState<boolean>(false);

  const changeIsShow = () => setIsShow((prev) => !prev);

  useEffect(() => {
    setIsShow(isHovering);
  }, [isHovering]);

  return (
    <div ref={ref} className={classNames(styles["wrapper"], wrapperClassName)}>
      {children}
      {isShow && (
        <div className={classNames(styles["tooltip"], className)}>
          {content}
          {showCloseIcon && <CloseIcon onClick={changeIsShow} className={styles["icon"]} />}
        </div>
      )}
    </div>
  );
};
