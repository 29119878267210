import { SVGProps } from "react";
export const SecondFlowerSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={152}
    height={235}
    viewBox="0 0 152 235"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M37.535 150.645C8.596 81.163-3.608 46.125.92 45.529c4.4-.578 23.99 31.438 58.768 96.05C52.327 45.894 51.96-1.291 58.582.027c6.55 1.303 14.435 47.014 23.653 137.132 13.247-49.933 23.051-82.724 29.414-98.375 10.398-25.577 13.671-13.713 12.647-.546-.682 8.779-5.895 43.677-15.64 104.696l-19.253 5.148h19.452c3.886 0 7.036 2.422 7.036 5.409v57.558c0 13.228-13.951 23.951-31.16 23.951H67.644c-17.21 0-31.16-10.723-31.16-23.951v-57.558c0-1.044.385-2.019 1.051-2.846Z"
    />
    <path
      fill="#7D6BF2"
      d="M37.535 150.645C8.596 81.163-3.608 46.125.92 45.529c4.4-.578 23.99 31.438 58.768 96.05C52.327 45.894 51.96-1.291 58.582.027c6.55 1.303 14.435 47.014 23.653 137.132 13.247-49.933 23.051-82.724 29.414-98.375 10.398-25.577 13.671-13.713 12.647-.546-.682 8.779-5.895 43.677-15.64 104.696l-19.253 5.148h19.452c3.886 0 7.036 2.422 7.036 5.409v57.558c0 13.228-13.951 23.951-31.16 23.951H67.644c-17.21 0-31.16-10.723-31.16-23.951v-57.558c0-1.044.385-2.019 1.051-2.846Z"
      opacity={0.2}
    />
    <path
      fill="#43375D"
      d="M37.535 150.645C8.596 81.163-3.608 46.125.92 45.529c4.4-.578 23.99 31.438 58.768 96.05C52.327 45.894 51.96-1.291 58.582.027c6.55 1.303 14.435 47.014 23.653 137.132 13.247-49.933 23.051-82.724 29.414-98.375 10.398-25.577 13.671-13.713 12.647-.546-.682 8.779-5.895 43.677-15.64 104.696l-19.253 5.148h19.452c3.886 0 7.036 2.422 7.036 5.409v57.558c0 13.228-13.951 23.951-31.16 23.951H67.644c-17.21 0-31.16-10.723-31.16-23.951v-57.558c0-1.044.385-2.019 1.051-2.846Z"
      opacity={0.07}
    />
    <path
      fill="#fff"
      d="M108.855 148.082H43.99c-12.35-42.747-18.7-83.687-19.051-122.82-.003-.337-.037-.913-.08-1.631-.304-5.202-1.05-17.909 6.23-2.33C36.178 32.185 49.856 71.29 72.127 138.61 81.357 61.168 87.192 19.205 89.63 12.721c2.595-6.9 4.524-6.947 5.786-.14l.091 123.908c32.386-62.458 50.687-92.337 54.901-89.636 4.392 2.814-7.412 37.48-35.412 103.996-1.205-1.651-3.504-2.767-6.141-2.767ZM36.484 172.766v21.461h79.407v-21.461H36.484Z"
    />
    <g fill="#7D6BF2" opacity={0.16}>
      <path d="M108.855 148.082H43.99c-12.35-42.747-18.7-83.687-19.051-122.82-.003-.337-.037-.913-.08-1.631-.304-5.202-1.05-17.909 6.23-2.33C36.178 32.185 49.856 71.29 72.127 138.61 81.357 61.168 87.192 19.205 89.63 12.721c2.595-6.9 4.524-6.947 5.786-.14l.091 123.908c32.386-62.458 50.687-92.337 54.901-89.636 4.392 2.814-7.412 37.48-35.412 103.996-1.205-1.651-3.504-2.767-6.141-2.767ZM36.484 172.766v21.461h79.407v-21.461H36.484Z" />
    </g>
    <g fill="#43375D" opacity={0.03}>
      <path d="M108.855 148.082H43.99c-12.35-42.747-18.7-83.687-19.051-122.82-.003-.337-.037-.913-.08-1.631-.304-5.202-1.05-17.909 6.23-2.33C36.178 32.185 49.856 71.29 72.127 138.61 81.357 61.168 87.192 19.205 89.63 12.721c2.595-6.9 4.524-6.947 5.786-.14l.091 123.908c32.386-62.458 50.687-92.337 54.901-89.636 4.392 2.814-7.412 37.48-35.412 103.996-1.205-1.651-3.504-2.767-6.141-2.767ZM36.484 172.766v21.461h79.407v-21.461H36.484Z" />
    </g>
  </svg>
);
