import { getSharedCourseId, getTokenAnonymous } from "../features/auth/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthOIDC } from "../features/auth/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { WebAppRoutes } from "../app/routes";

const NeedAuthAnonymos = ({ children }) => {
  const authState = useSelector(selectAuthOIDC);
  const { course_share_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (course_share_id && !authState.idToken) {
      dispatch(getTokenAnonymous(course_share_id)).then(() => {
        dispatch(getSharedCourseId(course_share_id)).then((result) => {
          if (result.payload?.data?.course_id) {
            navigate(WebAppRoutes.COURSE + "/" + result.payload?.data?.course_id + "/lesson", {
              replace: true,
            });
          } else {
            navigate("/403", { relative: true });
          }
        });
      });
    }
    if (authState.idToken) {
      dispatch(getSharedCourseId(course_share_id)).then((result) => {
        if (result.payload?.data?.course_id) {
          navigate(WebAppRoutes.COURSE + "/" + result.payload?.data?.course_id, { replace: true });
        }
      });
    }
  }, [authState.idToken, course_share_id, navigate, dispatch]);

  return children;
};

export default NeedAuthAnonymos;
