import { createContext, Dispatch, useReducer } from "react";
import { NotificationContainer } from "./components";
import { NotificationProps } from "./interfaces";
import { notificationReducer } from "./NotificationReducer";

export const NotificationContext = createContext<
  Dispatch<{
    type: string;
    payload: NotificationProps;
  }>
>(() => {});

export const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(notificationReducer, []);
  return (
    <NotificationContext.Provider value={dispatch}>
      <NotificationContainer notifications={state} />
      {children}
    </NotificationContext.Provider>
  );
};
