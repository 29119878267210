export const CornerArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M9.3335 6.47266L6.00016 3.13932L2.66683 6.47266"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 13.8047L8.66683 13.8047C7.95959 13.8047 7.28131 13.5237 6.78121 13.0236C6.28111 12.5235 6.00016 11.8453 6.00016 11.138L6.00016 3.13802"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
