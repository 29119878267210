import { СompetenciesType } from "./competancies.types";
import { useEffect, useState } from "react";
import { CompetenciesCardInfo } from "./components/CompetenciesCardInfo";
import classNames from "classnames";
import { CourseCompetence } from "pages/Courses/redux/interface";
import { CompetenceCardBar } from "./components/CompetenceCardBar";
import styles from "./CompetenciesCard.module.scss";
import { CompetencyTree } from "pages/Profile/components/CompetencyTree";
import { useAppSelector } from "hooks/redux";
import { coursesCompetenceSelectors } from "pages/Courses/redux/coursesListSlice";
import { IconButton } from "components/Buttons/IconButton/IconButton";
import { CloseIcon } from "components/Icons";
import { useMediaQuery } from "hooks";

interface CompetenciesCardProps {
  competence: СompetenciesType;
  className?: string;
  showTree?: boolean;
}

export const CompetenciesCard = ({
  competence,
  className,
  showTree = false,
}: CompetenciesCardProps): JSX.Element => {
  const allCompetentces = useAppSelector(coursesCompetenceSelectors.selectAll);
  const [parentCompetentces, setParentCompetences] = useState<CourseCompetence[]>([]);
  const [childrenCompetentces, setChildrenCompetences] = useState<CourseCompetence[]>([]);
  const [showCompetencyTree, setShowCompetencyTree] = useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width: 767px)");

  // получение всех родительских компетенций из справочника
  const findParents = (id: number) => {
    const parentObjects: CourseCompetence[] = [];

    const getParent = (id: number) => {
      const parent = allCompetentces.find((item) => item.id === id);

      parent && parentObjects.push(parent);

      parent?.parent_id && getParent(parent.parent_id);
    };

    getParent(id);

    return parentObjects;
  };

  // получение всех дочерних компетенций из справочника
  const findChildren = (id: number) => {
    const list: CourseCompetence[] = [];

    const getChildren = (id: number) => {
      const children = allCompetentces.filter((item) => item.parent_id === id);

      if (children.length) {
        list.push(...children);
        children.forEach((item) => {
          getChildren(item.id);
        });
      }
    };

    getChildren(id);

    return list;
  };

  useEffect(() => {
    if (competence.parent_id) {
      setParentCompetences(findParents(competence.parent_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence]);

  useEffect(() => {
    competence.id && setChildrenCompetences(findChildren(competence.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence]);

  const changeStateCompetenceTree = () => {
    if (!isMobile && showTree) {
      setShowCompetencyTree((prev) => !prev);
    }
  };
  return (
    <>
      <div className={classNames(styles["wrapper"], className)}>
        <div className={styles["competence-header"]}>
          <CompetenceCardBar competence={competence} parentCompetentces={parentCompetentces} />
          {isMobile && <p className={styles["competence-name"]}>{competence.name}</p>}
        </div>
        <CompetenciesCardInfo
          competence={competence}
          parents={parentCompetentces}
          childrenCompetentces={childrenCompetentces}
          clicklOnCompetence={changeStateCompetenceTree}
        />
      </div>
      {showCompetencyTree && !isMobile && showTree && (
        <div className={styles["competence-tree-container"]}>
          <div className={styles["close-button-container"]}>
            <IconButton className={styles["close-button"]} onClick={changeStateCompetenceTree}>
              <CloseIcon />
            </IconButton>
          </div>
          <CompetencyTree
            data={allCompetentces}
            competenceId={competence.id}
            parentId={parentCompetentces[0].id}
          />
        </div>
      )}
    </>
  );
};
