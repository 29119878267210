import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IShopProductFull } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import { getShopProductThunkAction } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { IOrder, OrdersSlice } from "./interfaces/orders.interface";
import { cancelShopOrder, getShopOrders } from "api";

const initialState: OrdersSlice = {
  orders: [],
  productDetailsLoading: false,
  ordersIsLoading: true,
};

const getUserOrders = createAsyncThunk("shop/getUserOrders", async (_, { rejectWithValue }) => {
  try {
    return (await getShopOrders()).items;
  } catch (error: any) {
    return rejectWithValue({
      status: error.response.status,
      code: error.response.status,
      details: error.response.statusText,
    });
  }
});

const updateOrderStatus = createAsyncThunk(
  "shop/updateOrderStatus",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return await cancelShopOrder(id);
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUserOrders.fulfilled,
      (state, action: PayloadAction<IOrder[] | undefined>) => {
        state.orders = action.payload || [];
        state.ordersIsLoading = false;
      }
    );
    builder.addCase(getUserOrders.rejected, (state) => {
      state.ordersIsLoading = false;
    });
    builder.addCase(getShopProductThunkAction.pending, (state) => {
      state.productDetailsLoading = true;
    });
    builder.addCase(getShopProductThunkAction.rejected, (state) => {
      state.productDetailsLoading = false;
    });
    builder.addCase(
      getShopProductThunkAction.fulfilled,
      (state, action: PayloadAction<IShopProductFull>) => {
        state.productDetailsLoading = false;
        state.currentProduct = action.payload;
      }
    );
  },
});

export { getUserOrders, updateOrderStatus };
export default ordersSlice.reducer;
