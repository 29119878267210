export const getYouTubeIframe = (src?: string) => {
  if (!src) return;

  const videoId = src.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/
  )?.[1];
  if (!videoId) return;

  // Формируем ссылку на встроенный плеер YouTube
  const embeddedLink = `https://www.youtube.com/embed/${videoId}`;

  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", embeddedLink);
  iframe.setAttribute("allowfullscreen", ""); // Разрешаем полноэкранный режим
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("width", "500");
  iframe.setAttribute("height", "300");
  return iframe;
};
