import { SVGProps } from "react";

export const CapybaraOnTreadmillSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={390}
    height={381}
    viewBox="0 0 390 381"
    fill="none"
    {...props}
  >
    <rect
      width={278.423}
      height={288.239}
      x={82.602}
      y={-4}
      fill="#fff"
      rx={16}
      transform="rotate(15 82.602 -4)"
    />
    <path
      fill="#fff"
      d="M188.04 160.98c-5.24-3.02-38.17-7.79-39.51-4.95-3.45 7.35 28.76 14.39 31.99 15.68l7.51-10.73h.01Z"
    />
    <path
      fill="#fff"
      d="M314.33 232.85c-2.61-2.15-6.49-.07-9.02 2.17-4.25 3.77-9.01 7.6-12.19 12.31l-.09.08c2.97-4.91 5.6-11.03 7.58-18.81 5.26-20.64-3.76-49.96-15.83-67.51 3.67.77 7.2 2.22 10.35 4.25a25.265 25.265 0 0 0-5.3-7.75c1.79 1.14 3.59 2.28 5.38 3.42-11.72-28.31-23.59-57.27-25.52-87.85-.48-7.62-.34-15.31-1.73-22.82-1.04-5.62-3.06-11.23-6.46-15.76h.16c3.32-2.78 6.19-6.1 8.49-9.78.73-1.16 1.42-2.49 1.18-3.84-.46-2.62-3.93-3.41-6.52-2.83-4.45.99-9.01 5.18-12.24 9.65l.14-.39c-2.55-1.13-5.33-1.75-8.06-1.75 0 0 3.3-9.19.99-10.4-4.55-2.38-15.7 13.27-15.7 13.27a592.614 592.614 0 0 1-66.85 27.37c-7.92 2.69-16.64 5.81-20.65 13.15-4.86 8.9-.7 19.83 3.49 29.06 1.92 4.24 3.85 8.48 5.77 12.72 4.74 10.44 10.41 21.8 21.04 26.06 6.73 2.7 14.44 1.97 21.26-.49 6.82-2.47 12.91-6.55 18.9-10.63l-20.96 23.36 11-5.28c-8.22 10.23-16.45 20.45-24.67 30.68-2.46 3.06-5.09 6.25-8.73 7.73-5.04 2.05-67.84-11.9-63.92-.91 1.21 3.39 28.79 20.04 48.53 22.53 1.74.22-9.9 23.67-9.9 23.67-22.38-4.25-24.52 22.25-22.91 32.56 2.05 13.08 11.74 23.91 23.02 30.83 11.28 6.93 24.18 10.62 36.74 14.8.69.23 1.43.5 1.83 1.11.81 1.24-.32 2.82-1.48 3.75a20.087 20.087 0 0 1-8.23 3.94c-1.45.32-3.17.65-3.77 2.01-1.02 2.26 2.08 4.13 4.53 4.54 9.27 1.57 19.02.13 27.44-4.04 3.94-1.95 8.06-5.44 7.52-9.81-.44-3.52-3.74-5.89-6.85-7.57-6.95-3.76-15.32-7.28-17.59-14.85-.24-.81-.38-1.76.12-2.44.53-.71 1.53-.83 2.42-.86 11.97-.46 23.89 3.08 35.82 2.03 13.83-1.21 34.59-1.55 42.37-13.05.75-1.11 1.46-2.34 2.15-3.64v.04c1.83-.55 3.78-1.23 5.78-2.07l-13.86 12.14c1.64 2.6 10.55 9.27 17.97 7.25 8.46-2.3 22.48-35.68 23.87-35.46.48.08-2.09 15.16 2.18 16.38 5.53 1.58 11.85-15.62 12.67-25.73.19-2.34.16-5.07-1.65-6.57l-.01.03Z"
    />
    <path
      fill="#fff"
      d="M388.07 297.94c-1.14-6.78-7.56-11.34-14.33-10.2l-2.21.27c-52.59 16.03-110 18.97-166.98 8.51l-75.04-18.53c-12.22 16.9-73.16 45.69-73.16 45.69l-3.03 28.03s100.06 44.68 269.39 18.96c2.77-.42 5.15-1.81 6.94-3.69.32-.33.61-.68.89-1.05l48.18-52.8s10.5-8.42 9.35-15.2v.01Z"
    />
    <path
      fill="#fff"
      d="M93.74 134.96c-8.06-1.96-16.47-2.21-24.6-.57a236.905 236.905 0 0 0-61.93 21.8c-2.37 1.23-4.18 3.41-4.7 6.03-.39 1.98.04 3.64.96 5.03h-.02c2.86 56.63 28.51 112.23 52.91 156.43l32.38-10.55c-26.59-32.24-52.06-97.02-65.11-136.73 16.33 4.96 47.99 13.47 73.09 13.53h-.15c9.72 37.26 22.39 83.08 29.1 92.36l12.14 4.01-28.26-97.23-.27.02c1.63-.24 3.22-.53 4.73-.89l-9.19-10.63c-3.41.46-7.27.52-11.38.3 2.61 2.28 9.01 2.84 12.34-.91-4.33-13.59-8.06-25.79-11.76-34.88 13.79 3.97 45.45 17.33 50.14 8.13 2.18-4.27-33.77-11.21-50.41-15.25h-.01ZM32.87 159c-1.37-3.61 33.6-15.23 51.15-17.57l9.41 36.45c-24.66-1.33-58.5-13.44-60.56-18.87V159Zm51.15-17.57c1.76-.24 3.35-.38 4.71-.41 1.09-.03 2.94.39 5.28 1.06-3.08-5.56-10.02-4.81-9.99-.65Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M226.58 100.86c2.27 4.67.93 10.45-2.05 14.7s-7.35 7.29-11.63 10.21c-5.99 4.08-12.08 8.17-18.9 10.63-6.82 2.47-14.53 3.19-21.26.49-10.64-4.26-16.31-15.63-21.04-26.06-1.92-4.24-3.85-8.48-5.77-12.72-4.19-9.23-8.35-20.16-3.49-29.06 4.01-7.34 12.73-10.46 20.65-13.15 22.81-7.75 45.15-16.9 66.85-27.37 0 0 11.15-15.65 15.7-13.27 2.31 1.21-.99 10.38-.99 10.38M261.48 34.57c3.41 4.53 5.43 10.15 6.48 15.79 1.39 7.51 1.25 15.2 1.73 22.82 1.93 30.58 13.8 59.54 25.52 87.85-1.79-1.14-3.59-2.28-5.38-3.42 2.23 2.22 4.04 4.86 5.3 7.75a30.869 30.869 0 0 0-10.35-4.25c12.08 17.55 21.09 46.86 15.83 67.51-7.1 27.86-22.25 34.98-33.24 38.3"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M236.32 27.93c4.85-3.04 11.1-2.87 16.38-.52M212.89 125.77l-20.96 23.36 11-5.28c-8.22 10.23-16.45 20.45-24.67 30.68-2.46 3.06-5.09 6.25-8.73 7.73-5.04 2.05-67.84-11.9-63.92-.91 1.38 3.89 37.49 25.25 56.64 22.63 19.15-2.62 37.22-12.53 49.73-27.28l.25 12.18 7.26-18.49M167.02 238.57c-43.73-30.7-47.73 8.46-45.68 21.54 2.05 13.08 11.74 23.91 23.02 30.83 11.28 6.93 24.18 10.62 36.74 14.8.69.23 1.43.5 1.83 1.11.81 1.24-.32 2.82-1.48 3.75a20.087 20.087 0 0 1-8.23 3.94c-1.45.32-3.17.65-3.77 2.01-1.02 2.26 2.08 4.13 4.53 4.54 9.27 1.57 19.02.13 27.44-4.04 3.94-1.95 8.06-5.44 7.52-9.81-.44-3.52-3.74-5.89-6.85-7.57-6.95-3.76-15.32-7.28-17.59-14.85-.24-.81-.38-1.76.12-2.44.53-.71 1.53-.83 2.42-.86 11.97-.46 23.89 3.08 35.82 2.03 13.83-1.21 34.59-1.55 42.37-13.05 7.78-11.5 12.38-33.94 8.37-47.24"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M154.15 204.2c-1.07 3.91-8 16.57-9.06 20.48M188.04 160.98c-5.24-3.02-38.17-7.79-39.51-4.95-3.45 7.35 28.76 14.39 31.99 15.68M259.3 276.98c1.64 2.6 10.55 9.27 17.97 7.25 8.46-2.3 22.48-35.68 23.87-35.46.48.08-2.09 15.16 2.18 16.38 5.53 1.58 11.85-15.62 12.67-25.73.19-2.34.16-5.07-1.65-6.57-2.61-2.15-6.49-.07-9.02 2.17-4.25 3.77-9.01 7.6-12.19 12.31M261.66 34.61c3.32-2.8 6.19-6.12 8.49-9.8.73-1.16 1.42-2.49 1.18-3.84-.46-2.62-3.93-3.41-6.52-2.83-6.38 1.42-13.02 9.37-15.58 15.38"
    />
    <path
      fill="#191229"
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M151.48 61.47c-.29 1.48-.89 2.9-1.72 4.16-1.54 2.32-4.17 3.69-6.95 3.69l4.34-5.8 4.34-2.04-.01-.01Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M228.63 44.54s-.17-7.3-7.43-4.64c-5.41 1.98-5.41 9.15-5.41 9.15l19.8-6.44"
    />
    <path
      fill="#191229"
      d="m221.96 46.54-.76-6.64-4.76 5.42s-.92 4.3-.64 4.3 6.17-3.08 6.17-3.08h-.01Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M166.29 54.8c6.83 28.77-4.49 73.71-4.49 73.71M237.64 125.77l13.7 14.25-6.85 18.98-.74-15.19-2.48 11.89s-3.63-5.56-3.63-6.58"
    />
    <path
      fill="#191229"
      d="M309.01 231.95s-3.38 5.93-5.78 14.68c-5.36 19.63-1.24 19.36 1.31 18.77 2.4-.55 4.26-4.67 5.29-6.38 2.53-4.17 5.61-17.52 5.61-17.52l.62-8.12-7.05-1.43ZM208.37 305.29c-3.74-3.01-4.72 5.75-17.59 7.73-12.87 1.98-16.11 1.05-16.11 1.05s-5.85.71-4.7 5c1.15 4.29 21.67 2.34 27.78-.11 4.57-1.83 14.45-10.59 10.62-13.67Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M104.81 177.57c-23.87 3.2-69.54-12.23-71.94-18.57-1.51-3.98 40.99-17.64 55.86-17.98 7.5-.17 49.93 19.96 55.42 9.19 2.18-4.27-33.77-11.21-50.41-15.25-8.06-1.96-16.47-2.21-24.6-.57a236.905 236.905 0 0 0-61.93 21.8c-2.37 1.23-4.18 3.41-4.7 6.03-1.19 6.03 5.09 9.04 10.27 10.7 0 0 67.29 23.49 101.22 15.28"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M3.45 167.24c2.86 56.63 28.51 112.24 52.91 156.44M23.63 176.4c13.05 39.71 32.82 96.78 59.4 129.02M96.56 189.94c9.72 37.25 22.39 83.07 29.1 92.35M84.02 141.43c1.38 4.84 4.91 18.96 9.43 36.54M109.54 189.07c5.61 18.42 10.72 37.08 13.55 49.78M94.01 142.07c3.7 9.09 7.43 21.3 11.76 34.89"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M129.51 277.99c-12.22 16.9-73.16 45.69-73.16 45.69 39.43 9.07 79.05 18.17 119.41 20.96 25.35 1.75 135.62 3.99 140.29.21 18.42-14.9 37.05-41.94 55.47-56.84-52.59 16.03-110 18.97-166.98 8.51"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M318.58 346.13c6.77-1.14 13.19 3.42 14.33 10.2.68 4.01-.65 7.9-3.25 10.65-1.79 1.89-4.18 3.27-6.94 3.69-169.33 25.72-269.39-18.96-269.39-18.96l3.03-28.03"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M129.51 277.99c-12.22 16.9-73.16 45.69-73.16 45.69 39.43 9.07 79.05 18.17 119.41 20.96 25.35 1.75 135.62 3.99 140.29.21 18.42-14.9 37.05-41.94 55.47-56.84-52.59 16.03-110 18.97-166.98 8.51"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M318.58 346.13c6.77-1.14 13.19 3.42 14.33 10.2.68 4.01-.65 7.9-3.25 10.65-1.79 1.89-4.18 3.27-6.94 3.69-169.33 25.72-269.39-18.96-269.39-18.96l3.03-28.03"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M373.74 287.74c6.77-1.14 13.19 3.42 14.33 10.2 1.14 6.78-9.35 15.2-9.35 15.2l-48.18 52.81M327.45 272.69c16.2-2.63 33.49-5.78 45.23-17.24M187.66 318.07c14.33 1.44 28.75 1.99 43.15 1.66M192.15 329.94h59.62"
    />
    <path
      fill="#FF8B00"
      d="m223.73 29.95-5.7 4.16 49.55 11.19-.96-8.63s-6.18-3.84-6.32-3.75c-.14.09-16.27 1.42-16.27 1.42l-20.3-4.38v-.01Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.03}
      d="M223.86 30.52s24.5 5.93 31.77 4.05c13.6-3.52 11.2 11.09 11.2 11.09L216.66 33.8l7.19-3.27.01-.01ZM344.06 255.18c9.69-4.95 19.37-9.9 29.06-14.85"
    />
  </svg>
);
