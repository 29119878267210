import classNames from "classnames";
import { PlusIcon } from "components/Icons";
import styles from "./AddButton.module.scss";

interface AddButtonProps {
  className?: string;
  children?: string;
  onClick?: () => void;
}

export const AddButton = ({ className = "", children, onClick = () => {} }: AddButtonProps) => (
  <button className={classNames(styles["add-button"], className)} onClick={onClick} type="button">
    <PlusIcon className={styles["add-button__icon"]} />
    {children}
  </button>
);
