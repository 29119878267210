import { SVGProps } from "react";

export const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2fff/svg"
    {...props}
  >
    <path
      d="M21 21H16.2M21 21L15 15L21 21ZM21 21V16.2V21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 21L9 15M3 16.2V21V16.2ZM3 21H7.8H3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 3L15 9M21 7.8V3V7.8ZM21 3H16.2H21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3L9 9M3 7.8V3V7.8ZM3 3H7.8H3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
