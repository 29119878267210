import { SVGProps } from "react";

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      d="M16.1038 5.14113C16.3158 4.9292 16.5674 4.76108 16.8443 4.64639C17.1212 4.53169 17.418 4.47266 17.7177 4.47266C18.0174 4.47266 18.3142 4.53169 18.5911 4.64639C18.868 4.76108 19.1196 4.9292 19.3315 5.14113C19.5435 5.35307 19.7116 5.60467 19.8263 5.88157C19.941 6.15847 20 6.45526 20 6.75498C20 7.0547 19.941 7.35148 19.8263 7.62838C19.7116 7.90529 19.5435 8.15689 19.3315 8.36882L8.43807 19.2623L4 20.4727L5.21038 16.0346L16.1038 5.14113Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
