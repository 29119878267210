import { SVGProps } from "react";

export const ClubStarBackgroundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 63"
    width={64}
    height={63}
    fill="none"
    {...props}
  >
    <path
      fill="#FF8B00"
      d="M30.761.477a2 2 0 0 1 2.478 0l2.386 1.883a2 2 0 0 0 1.932.306l2.851-1.053a2 2 0 0 1 2.357.766l1.688 2.528a2 2 0 0 0 1.742.888l3.037-.121a2 2 0 0 1 2.005 1.456l.824 2.926a2 2 0 0 0 1.383 1.383l2.926.824a2 2 0 0 1 1.456 2.005l-.12 3.037a2 2 0 0 0 .887 1.743l2.528 1.687a2 2 0 0 1 .766 2.357l-1.053 2.851a2 2 0 0 0 .306 1.932l1.883 2.386a2 2 0 0 1 0 2.478l-1.883 2.386a2 2 0 0 0-.306 1.932l1.053 2.851a2 2 0 0 1-.766 2.357l-2.528 1.687a2 2 0 0 0-.888 1.743l.121 3.037a2 2 0 0 1-1.456 2.005l-2.926.824a2 2 0 0 0-1.383 1.383l-.824 2.926a2 2 0 0 1-2.005 1.456l-3.037-.12a2 2 0 0 0-1.742.887l-1.688 2.528a2 2 0 0 1-2.357.766l-2.851-1.053a2 2 0 0 0-1.932.306l-2.386 1.883a2 2 0 0 1-2.478 0l-2.386-1.883a2 2 0 0 0-1.932-.306l-2.851 1.053a2 2 0 0 1-2.357-.766l-1.687-2.528a2 2 0 0 0-1.743-.888l-3.037.121a2 2 0 0 1-2.005-1.456l-.824-2.926a2 2 0 0 0-1.383-1.383l-2.926-.824a2 2 0 0 1-1.456-2.005l.12-3.037a2 2 0 0 0-.887-1.742l-2.528-1.688a2 2 0 0 1-.766-2.357l1.053-2.851a2 2 0 0 0-.306-1.932L.977 32.74a2 2 0 0 1 0-2.478l1.883-2.386a2 2 0 0 0 .306-1.932l-1.053-2.851a2 2 0 0 1 .766-2.357l2.528-1.687a2 2 0 0 0 .888-1.743l-.121-3.037a2 2 0 0 1 1.456-2.005l2.926-.824a2 2 0 0 0 1.383-1.383l.824-2.926a2 2 0 0 1 2.005-1.456l3.037.12a2 2 0 0 0 1.743-.887l1.687-2.528a2 2 0 0 1 2.357-.766l2.851 1.053a2 2 0 0 0 1.932-.306L30.76.477Z"
    />
  </svg>
);
