import { SVGProps } from "react";

export const MeetingMyAcademyIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={578}
    viewBox="0 0 578 490"
    height={490}
    fill="none"
    {...props}
  >
    <rect
      width={424.305}
      height={424.305}
      x={94.818}
      y={-15}
      fill="#E7F7FF"
      rx={60}
      transform="rotate(15 94.818 -15)"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M453.455 152.02H62.531v271.383h390.924V152.02ZM554.341 152.02h-71.46v229.3h71.46v-229.3Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M440.847 173.057H182.334v208.264h258.513V173.057ZM205.451 208.824h213.915M205.451 225.648h213.915"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M205.451 276.137c79.534 1.246 153.428 1.637 213.915 0M419.83 307.691h-60.951v61.008h60.951v-61.008Z"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M453.455 152.02H62.531v271.383h390.924V152.02ZM554.341 152.02h-71.46v229.3h71.46v-229.3Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M440.847 173.057H182.334v208.264h258.513V173.057Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M205.451 242.48c80.166 1.014 155.509 1.944 213.915 0M205.451 190.4c80.166 1.014 155.509 1.944 213.915 0M205.451 259.314c82.015-4.222 157.563-5.896 213.915 0M205.451 292.971c79.45-3.59 152.945-4.761 213.915 0M419.83 307.691h-60.951v61.008h60.951v-61.008Z"
    />
    <path
      fill="#00AEFF"
      d="M569.104 68.32c-187.276-6.324-365.149-7.663-526.038 0-.836 13.82-1.468 29.397-1.942 46.435 203.555 3.208 390.329 2.845 529.829-7.589a7792.877 7792.877 0 0 0-1.839-38.846h-.01Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M570.953 107.166h-.009.009ZM569.104 462.909c-99.212 11.384-314.121 5.469-526.038 0-2.825-156.565-5.175-308.936 0-394.59 160.898-7.663 338.772-6.323 526.038 0 7.564 151.879 10.695 291.844 0 394.59Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M41.115 114.755c199.921 1.739 384.689.707 529.829-7.589M545.077 80.037l17.245 18.488M560.296 80.037l-15.219 18.488M531.53 80.037h-18.48v19.725h18.48V80.037ZM501.77 89.281h-18.285M527.722 400.785h-48.576v21.037h48.576v-21.037ZM545.998 423.393c6.671 0 12.078-5.413 12.078-12.09s-5.407-12.09-12.078-12.09c-6.671 0-12.079 5.413-12.079 12.09s5.408 12.09 12.079 12.09ZM539.187 411.303h12.069"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m545.691 405.352 6.039 6.045-5.863 5.859M84.897 423.404c3.623-18.387 10.6-35.964 20.422-51.932 9.319-15.159 20.116-36.242 16.845-48.239-4.376-16.061-11.688-53.503-5.24-73.479"
    />
    <path
      fill="#fff"
      d="M181.118 423.404c-1.533-5.803-3.41-12.025-5.696-18.721l-9.161-26.97 9.161 10.128-5.946-23.687s75.845 6.993 99.194-1.377c12.766-4.575 6.606-118.779-.92-121.737-33.068-12.964-35.911-6.937-103.635 2.642-16.678 2.362-34.806-7.691-39.071-5.292-2.229 1.255-4.051 3.134-5.509 5.515"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M181.118 423.404c-1.533-5.803-3.41-12.025-5.696-18.721l-9.161-26.97 9.161 10.128-5.946-23.687s75.845 6.993 99.194-1.377c12.766-4.575 6.606-118.779-.92-121.737-33.068-12.964-35.911-6.937-103.635 2.642-16.678 2.362-34.806-7.691-39.071-5.292-2.229 1.255-4.051 3.134-5.509 5.515"
    />
    <path
      fill="#fff"
      d="M123.093 246.88s-29.165-22.301-30.996-13.792c-1.84 8.51 28.246 17.475 28.246 17.475"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M123.093 246.88s-29.165-22.301-30.996-13.792c-1.84 8.51 28.246 17.475 28.246 17.475"
    />
    <path
      fill="#fff"
      d="M140.542 238.836s14.746-17.196 16.446-13.429c1.7 3.766-10.471 15.633-10.471 15.633"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M140.542 238.836s14.746-17.196 16.446-13.429c1.7 3.766-10.471 15.633-10.471 15.633"
    />
    <path fill="#fff" d="M197.257 239.523s-14.922 86.49 6.197 111.08l-6.197-111.08Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M197.257 239.523s-14.922 86.49 6.197 111.08"
    />
    <path
      fill="#fff"
      d="M233 250.562s16.53 83.087 4.897 87.988c-11.633 4.901-23.879-5.208-23.879-5.208"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M233 250.562s16.53 83.087 4.897 87.988c-11.633 4.901-23.879-5.208-23.879-5.208"
    />
    <path fill="#fff" d="M261.172 328.738c-7.35 19.205-19.122 0-19.122 0h19.122Z" />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M261.172 328.738c-7.35 19.205-19.122 0-19.122 0"
    />
    <path
      fill="#191229"
      d="M211.881 275.683c2.325 0 4.209-4.389 4.209-9.803 0-5.413-1.884-9.802-4.209-9.802-2.324 0-4.209 4.389-4.209 9.802 0 5.414 1.885 9.803 4.209 9.803ZM251.611 273.544c2.324 0 4.209-4.389 4.209-9.802 0-5.414-1.885-9.803-4.209-9.803-2.325 0-4.209 4.389-4.209 9.803 0 5.413 1.884 9.802 4.209 9.802Z"
    />
    <path
      fill="#fff"
      d="M131.186 256.654c3.419-5.19 9.171-8.101 14.875-7.44 5.501.632 8.864 4.343 9.747 5.375a224.864 224.864 0 0 0-17.189 4.557"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M131.186 256.654c3.419-5.19 9.171-8.101 14.875-7.44 5.501.632 8.864 4.343 9.747 5.375a224.864 224.864 0 0 0-17.189 4.557"
    />
    <path
      fill="#191229"
      d="M147.306 250.452c-1.681 1.479-2.053 3.701-1.235 4.966 1.003 1.544 4.032 2.037 6.197 0 1.096-1.125 1.663-2.613 1.236-3.72-.614-1.618-3.41-2.688-6.198-1.237v-.009Z"
    />
  </svg>
);
