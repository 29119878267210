import { Timeout } from "ahooks/lib/useRequest/src/types";
import classNames from "classnames";
import { NotificationContext } from "components/Notification/NotificationProvider";
import { NotificationProps } from "components/Notification/interfaces";
import { CloseIcon, TestAlertRejectIcon, TestCheckIcon } from "components/Svg/Icons";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./Notification.module.scss";

export const Notification = ({
  message,
  type = "simple",
  id = "",
  showTime = null,
  closeButtonType = "default",
  contentPosition = "center",
}: NotificationProps) => {
  const [showNotification, setShowNotification] = useState(true);
  const notificationElementRef = useRef<HTMLDivElement>(null);
  const dispatch = useContext(NotificationContext);

  const dispatchRemoveNotification = useCallback(
    (id: string) =>
      dispatch({
        type: "REMOVE_NOTIFICATION",
        payload: {
          id,
          message: "",
        },
      }),
    [dispatch]
  );

  const onCloseNotification = useCallback(
    (id: string) => {
      notificationElementRef.current?.addEventListener("animationend", (event: AnimationEvent) => {
        const targetElement = event.target as HTMLElement;
        if (notificationElementRef.current?.isEqualNode(targetElement)) {
          dispatchRemoveNotification(id);
        }
      });
      setShowNotification(false);
    },
    [dispatchRemoveNotification]
  );

  useEffect(() => {
    let timeoutId: Timeout | undefined;
    if (showTime) {
      timeoutId = setTimeout(() => {
        onCloseNotification(id);
      }, showTime * 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch, id, showTime, dispatchRemoveNotification, onCloseNotification]);

  return (
    <>
      <div
        ref={notificationElementRef}
        className={`${styles["notification"]} ${
          showNotification ? styles["notification_show"] : styles["notification_hide"]
        } d-flex`}
        style={{
          animationName: styles[showNotification ? "showNotification" : "hideNotification"],
          animationDuration: "1s",
        }}
      >
        <div
          className={classNames(
            styles["notification__message-container"],
            styles["notification__message-container__" + contentPosition]
          )}
        >
          <div className={styles["notification__message-icon-container"]}>
            {type !== "simple" ? (
              type === "success" ? (
                <TestCheckIcon className={styles["notification__message-icon"]} />
              ) : (
                <TestAlertRejectIcon className={styles["notification__message-icon"]} />
              )
            ) : null}
          </div>
          <p className={styles["notification__message"]}>{message}</p>
        </div>
        {showTime && (
          <div
            className={`${styles["notification__time-bar"]} ${
              styles[`notification__time-bar_${type}`]
            }`}
            style={{ animationDuration: `${showTime}s` }}
          ></div>
        )}

        {!showTime && (
          <button
            className={classNames(
              styles["notification__close-button"],
              styles["notification__close-button__" + closeButtonType]
            )}
            onClick={() => onCloseNotification(id)}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    </>
  );
};
