import { SVGProps } from "react";

export const SendIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_19172_4507)">
        <path
          d="M21.285 12.4735H5.72866"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2847 12.4703L2.19283 21.6626L5.72836 12.4703L2.19283 3.27787L21.2847 12.4703Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19172_4507">
          <rect width="24" height="24" fill="white" transform="translate(0 0.472656)" />
        </clipPath>
      </defs>
    </svg>
  );
};
