import { SVGProps } from "react";

export const ClockSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={49}
    viewBox="0 0 48 49"
    fill="none"
    {...props}
  >
    <rect
      width={42.889}
      height={42.889}
      x={9.099}
      y={-1.877}
      fill="#E8EAEF"
      rx={10}
      transform="rotate(15 9.1 -1.877)"
    />
    <circle cx={25.5} cy={24.5} r={15.5} stroke="#A4A4AF" strokeLinecap="round" strokeWidth={2} />
    <circle cx={25.5} cy={24.5} r={1.5} stroke="#A4A4AF" strokeLinecap="round" strokeWidth={2} />
    <path
      stroke="#A4A4AF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M25.5 16v6M25.5 9v2M10 24.5h2M14.54 13.54l1.414 1.413M14.54 35.46l1.413-1.413M25.5 38v2M39 24.5h2M35.046 34.047l1.414 1.414M35.047 14.953l1.414-1.414M31.04 29.787l-3.883-4.08M6.271 21.628c-.844 5.32.791 13.246 6.884 17.504"
    />
  </svg>
);
