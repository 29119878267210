import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AchievementsState, IBadgesRequest, IBadgesResponse } from "./interfaces";
import { getBadges } from "../../../api";
import { SearchParamsOption } from "ky";

const name = "achievement";

const initialState: AchievementsState = {
  isLoading: false,
  paging: {
    total: 0,
    offset: 0,
    limit: 30,
    hasMore: true,
  },
  achievements: [],
};

const getBadgesThunkAction = createAsyncThunk(
  `${name}/getBadges`,
  async (params: IBadgesRequest, thunkAPI) => {
    try {
      const request = await getBadges(params as SearchParamsOption);
      const response = await request.json();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const achievementSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBadgesThunkAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getBadgesThunkAction.fulfilled,
        (state, action: PayloadAction<IBadgesResponse | any>) => {
          state.isLoading = false;
          state.achievements = [...state.achievements, ...action.payload.data];

          const paging = action.payload.paging;
          state.paging = {
            total: paging.total,
            limit: paging.limit,
            offset: paging.offset + paging.limit,
            hasMore: state.achievements.length < paging.total,
          };
        }
      )
      .addCase(getBadgesThunkAction.rejected, (state) => {
        state.paging.hasMore = false;
      });
  },
});

export { getBadgesThunkAction };

const achievementReducer = achievementSlice.reducer;
export { achievementReducer };
