import { SVGProps } from "react";

export const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08984 8.00057C8.32495 7.33224 8.789 6.76868 9.3998 6.4097C10.0106 6.05073 10.7287 5.91951 11.427 6.03928C12.1253 6.15906 12.7587 6.52209 13.2149 7.0641C13.6712 7.6061 13.9209 8.29209 13.9198 9.00057C13.9198 11.0006 10.9198 12.0006 10.9198 12.0006"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16H11.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
