import { SVGProps } from "react";

export const CommentsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 21.1667C2 21.9918 2.32778 22.7831 2.91122 23.3666C3.49467 23.95 4.28599 24.2778 5.11111 24.2778H23.7778L30 30.5V5.61111C30 4.78599 29.6722 3.99467 29.0888 3.41122C28.5053 2.82778 27.714 2.5 26.8889 2.5H5.11111C4.28599 2.5 3.49467 2.82778 2.91122 3.41122C2.32778 3.99467 2 4.78599 2 5.61111V21.1667Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
