import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  revokeAgreementModal: { isOpen: false },
  selectAvatarModal: { isOpen: false },
  confirmModal: { isOpen: false },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openConfirmModal(state) {
      state.confirmModal.isOpen = true;
    },
    closeConfirmModal(state) {
      state.confirmModal.isOpen = false;
    },
    openRevokeAgreementModal(state) {
      state.revokeAgreementModal.isOpen = true;
    },
    closeopenRevokeAgreementModal(state) {
      state.revokeAgreementModal.isOpen = false;
    },
    openSelectAvatarModal(state) {
      state.selectAvatarModal.isOpen = true;
    },
    closeSelectAvatarModal(state) {
      state.selectAvatarModal.isOpen = false;
    },
  },
});

export const {
  openRevokeAgreementModal,
  closeopenRevokeAgreementModal,
  openSelectAvatarModal,
  closeSelectAvatarModal,
  openConfirmModal,
  closeConfirmModal,
} = modalsSlice.actions;
export default modalsSlice.reducer;
