import { fromUnixTime, isAfter } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import { selectLessonCoins } from "../../redux/courseSelectors";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import { CourseMeetingStart } from "./components";
import { CourseMeetingEnd } from "./components/CourseMeetingEnd";
import {
  getMeetingThunkAction,
  isLoadinMeetingSelector,
  meetingLessonSelector,
  removeLesson,
  resetEnableNotification,
} from "./redux";

interface CourseMeetingProps {
  lessonId: number;
  isConference?: boolean;
}

export const CourseMeeting = ({ lessonId, isConference }: CourseMeetingProps) => {
  const dispatch = useAppDispatch();
  const isLoadingMeeting = useAppSelector(isLoadinMeetingSelector);
  const lesson = useAppSelector(meetingLessonSelector);
  const coins = useAppSelector(selectLessonCoins(lessonId));

  useEffect(() => {
    dispatch(getMeetingThunkAction({ id: lessonId, isConference }));
    return () => {
      dispatch(resetEnableNotification());
      dispatch(removeLesson());
    };
  }, [dispatch, lessonId, isConference]);

  const webinarIsCompleted =
    lesson?.meeting_status === "completed" && isAfter(Date.now(), fromUnixTime(lesson?.end_ts));

  if (isLoadingMeeting || !lesson) {
    return <CourseLoadSkeleton />;
  }

  return webinarIsCompleted ? (
    <CourseMeetingEnd
      lessonId={lesson?.meeting_id}
      status={lesson?.status}
      startDate={lesson?.start_ts ? fromUnixTime(lesson?.start_ts ?? "") : undefined}
      endDate={lesson?.end_ts ? fromUnixTime(lesson?.end_ts) : undefined}
      title={lesson?.title ?? ""}
      isRecording={lesson?.with_record}
      records={lesson?.record_url}
      coins={coins}
      description={lesson?.description}
      meetingType={lesson?.meeting_type}
      isConference={!!isConference}
      meetingUrl={lesson.meeting_url}
    />
  ) : (
    <CourseMeetingStart
      lessonId={lesson?.meeting_id}
      title={lesson?.title}
      status={lesson?.status}
      startDate={lesson?.start_ts ? fromUnixTime(lesson?.start_ts ?? "") : undefined}
      endDate={lesson?.end_ts ? fromUnixTime(lesson?.end_ts) : undefined}
      isRecordedMeeting={lesson?.with_record ?? false}
      hasRecords={lesson?.record_url > 0}
      meetingUrl={lesson?.meeting_url ?? ""}
      coins={coins}
      description={lesson?.description}
      meetingType={lesson?.meeting_type}
      isConference={!!isConference}
    />
  );
};
