import { ViewedVideoInfo } from "pages/Course/redux/interfaces/course.interface";
import { useCallback, useEffect, useRef } from "react";

export const useVideoView = (
  info: React.MutableRefObject<{
    finished: Set<string>;
    state: ViewedVideoInfo[];
  }>,
  finishCheck: boolean = true,
  onFinish?: () => void
) => {
  const timerRef = useRef<NodeJS.Timer>();
  const activeVideosDurationRef = useRef<HTMLVideoElement[]>([]);

  const checkVideoFinish = useCallback(
    (video: HTMLVideoElement) => {
      const name = video.getAttribute("name") ?? "";
      const videoInfo = info.current.state?.find((x) => x.name === name);

      if (!videoInfo) return;
      if (
        (videoInfo.time / video.duration) * 100 >= 60 &&
        !info.current.finished.has(name) &&
        finishCheck
      ) {
        info.current.finished.add(name);
        onFinish?.();
        console.log("[SMART VIEW] Просмотрено видео: " + name);
      }
    },
    [info, finishCheck, onFinish]
  );

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      activeVideosDurationRef.current.forEach((video) => {
        const name = video.getAttribute("name") ?? "";
        let videoInfo = info.current.state?.find((x) => x.name === name);
        if (!videoInfo) {
          videoInfo = {
            name: name,
            time: video.playbackRate,
          };
          info.current.state?.push(videoInfo);
        } else {
          videoInfo.time += video.playbackRate;
        }

        console.log(videoInfo);

        checkVideoFinish(video);
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [info, checkVideoFinish]);

  const initializeVideos = useCallback(
    (videos: NodeListOf<HTMLVideoElement> | undefined) => {
      const activeVideos = videos ? Array.from(videos).filter((x) => !!x.getAttribute("name")) : [];
      // Пуллим фактические видосы в память
      info.current.state = activeVideos?.map((x) => {
        const name = x.getAttribute("name") ?? "";
        return {
          name: name,
          time: Number(info.current.state?.find((y) => y.name === name)?.time) || 0,
        };
      });

      const videosTime: { [name: string]: number } = {};
      info.current.state?.forEach((x) => {
        videosTime[x.name] = Number(x.time);
      });

      activeVideos?.forEach((videoElement) => {
        const name = videoElement.getAttribute("name") ?? "";

        videoElement.addEventListener("play", () => {
          if (!activeVideosDurationRef.current?.includes(videoElement)) {
            activeVideosDurationRef.current?.push(videoElement);
          }
        });

        videoElement.addEventListener(
          "pause",
          () =>
            (activeVideosDurationRef.current = activeVideosDurationRef.current?.filter(
              (x) => x !== videoElement
            ))
        );

        videoElement.addEventListener("loadeddata", () => checkVideoFinish(videoElement));

        //@ts-ignore
        videoElement.currentTime = videosTime[name].toString() ?? 0;
      });
    },
    [info, checkVideoFinish]
  );

  return {
    initializeVideos,
  };
};
