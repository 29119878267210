import { CourseCompetence } from "pages/Courses/redux/interface";

export const findRootNode = (
  nodes: CourseCompetence[],
  startNodeId: number
): CourseCompetence | null => {
  const startNode = nodes.find((node) => node.id === startNodeId);

  if (!startNode) {
    return null;
  }

  if (startNode?.parent_id === null) {
    return startNode;
  }

  return findRootNode(nodes, startNode.parent_id);
};
