import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";
import styles from "./LinkButton.module.scss";

export const LinkButton = ({
  children,
  className = "",
  ...otherProps
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className={classNames(styles["button-link"], className)} {...otherProps}>
      {children}
    </button>
  );
};
