import { SVGProps } from "react";

export const HappyCapybaraMyAcademyIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={306}
    height={289}
    viewBox="0 0 306 289"
    fill="none"
    {...props}
  >
    <rect
      width={235.551}
      height={243.892}
      fill="#fff"
      rx={16}
      transform="rotate(15.002 56.354 310.627) skewX(.005)"
    />
    <path
      fill="#fff"
      d="m76.09 142.77 11.844 2.115 2.537 12.269c.282 4.09.93 12.608 1.27 13.962.422 1.692 0 1.692 12.266 9.307 12.267 7.616 26.227 10.577 36.379 11.847 10.152 1.269 25.38-4.654 31.725-6.347 6.345-1.692 10.575 36.385 2.115 37.654-8.46 1.269-28.341 2.116-43.992 0-15.652-2.115-43.993-15.654-48.646-17.346-3.722-1.354-18.753-9.026-25.803-12.692l-2.115-5.923c-.14-3.808-.592-11.846-1.269-13.539-.846-2.115 8.037-23.269 8.883-24.961.677-1.354 10.153-4.795 14.806-6.346Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M51.466 191.886c12.908 6.386 41.758 20.263 66.838 28.437 4.059 1.324 9.641 3.027 17.101 3.952 8.384 1.045 16.001.725 22.482-.164"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M157.881 224.112a93.39 93.39 0 0 0 12.582-2.622"
    />
    <path
      fill="#fff"
      d="M250.198 231.76c-.93-18.481-1.48-33.621-1.812-44.103 0 0-.393-12.349-1.263-29.585 3.105 2.73 6.095 3.606 6.095 3.606-1.365-2.102-3.129-9.642-6.644-15.708-.091-1.679-.067.683-.164-1.057l7.291 2.87-8.154-17.315-.012.35c-.242-3.582-.496-7.201-.768-10.826-.628-8.338-1.232-13.847-2.911-20.505-1.178-4.67-1.722-5.764-2.453-9.842-.869-4.833 3.335-3.649 6.144-7.679 2.802-4.03 4.439-9.412 2.343-13.859-.694.218-1.401.465-2.108.755-6.983 2.84-11.453 8.193-14.141 12.47l-3.563 4.332c-8.512-6.622-19.209-13.588-32.239-19.448a157.045 157.045 0 0 0-12.081-4.833L175.29 154c.616 8.367.133 18.867-2.858 32.425a62.17 62.17 0 0 1-.688 2.809l-15.361 11.569 7.798-1.999-11.229 17.852 7.387-2.344a320.694 320.694 0 0 0-7.182 14.681 431.75 431.75 0 0 0-9.87 23.315"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M250.198 231.76c-.93-18.481-1.48-33.621-1.812-44.103 0 0-.393-12.349-1.263-29.585 3.105 2.73 6.095 3.606 6.095 3.606-1.365-2.102-3.129-9.642-6.644-15.708-.091-1.679-.067.683-.164-1.057l7.291 2.87-8.154-17.315-.012.35c-.242-3.582-.496-7.201-.768-10.826-.628-8.338-1.232-13.847-2.911-20.505-1.178-4.67-1.722-5.764-2.453-9.842-.869-4.833 3.335-3.649 6.144-7.679 2.802-4.03 4.439-9.412 2.343-13.859-.694.218-1.401.465-2.108.755-6.983 2.84-11.453 8.193-14.141 12.47l-3.563 4.332c-8.512-6.622-19.209-13.588-32.239-19.448a157.045 157.045 0 0 0-12.081-4.833L175.29 154c.616 8.367.133 18.867-2.858 32.425a62.17 62.17 0 0 1-.688 2.809l-15.361 11.569 7.798-1.999-11.229 17.852 7.387-2.344a320.694 320.694 0 0 0-7.182 14.681 431.75 431.75 0 0 0-9.87 23.315"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M223.632 92.786c-.683-3.093-3.099-5.884-6.216-6.452-3.111-.568-6.633 1.559-7.098 4.688 0 0 2.525.64 4.211.906 5.653.889 11.247 2.472 16.696 4.205M206.875 71.823c-.387-.81-2.785-6.005-.278-10.953a10.85 10.85 0 0 1 2.797-3.456c1.83 1.027 3.032 2.876 4.155 4.646 1.583 2.501 3.196 5.063 3.884 7.945.405 1.685.979 4.742.133 8.76"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M118.515 63.807c-10.081 9.364-7.75 25.918-3.624 52.561 5.092 32.866 7.635 49.293 21.142 56.791 11.839 6.573 27.883 4.724 38.659-2.417 2.7-1.788 11.29-8.035 19.329-39.874 8.789-34.811 13.211-52.301 3.021-64.04-11.03-12.706-32.649-11.147-53.157-9.667-12.95.937-19.746 1.426-25.37 6.646Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M126.404 147.597c3.878 1.855 8.427 3.728 12.311 1.885 4.379-2.084 4.965-7.775 6.149-12.475 3.703-14.693 6.814-36.558 7.478-52.689-.767 5.287-.374 44.472 1.74 55.202.725 3.673.229 8.114 3.31 10.24 1.516 1.052 3.437 1.311 5.279 1.396 8.294.399 16.702-2.133 23.389-7.051"
    />
    <path
      fill="#191229"
      d="M139.402 81.615c1.132-5.118.328-9.648-1.796-10.117-2.124-.47-4.763 3.298-5.895 8.416-1.131 5.118-.327 9.648 1.797 10.118 2.123.47 4.762-3.298 5.894-8.417ZM174.6 89.797c2.037-.338 3.029-4.588 2.215-9.492-.813-4.905-3.124-8.607-5.162-8.269-2.037.338-3.029 4.588-2.215 9.493.813 4.904 3.124 8.606 5.162 8.268ZM219.869 91.924c-.568 1.77-2.193.918-4.446.193-2.259-.725-4.295-1.039-3.727-2.809.568-1.77 2.857-2.622 5.116-1.897 2.259.725 3.63 2.743 3.063 4.513h-.006Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M172.274 174.366c8.306-.29 16.225-3.172 27.787-6.041a146.965 146.965 0 0 0 27.182-9.667"
    />
    <path
      fill="#fff"
      d="M44.82 124.826c5.847-24.426 6.174-32.685 5.062-35.198-.145-.333-.857-2.266-.664-4.628.127-1.559.948-3.178 2.41-3.746.797-.308 1.691-.272 2.537-.157 2.724.375 11.64 5.613 15.005 16.016 3.273 10.108-.743 19.104-2.006 21.665"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M44.82 124.826c5.847-24.426 6.174-32.685 5.062-35.198-.145-.333-.857-2.266-.664-4.628.127-1.559.948-3.178 2.41-3.746.797-.308 1.691-.272 2.537-.157 2.724.375 11.64 5.613 15.005 16.016 3.273 10.108-.743 19.104-2.006 21.665"
    />
    <path
      fill="#fff"
      d="M64.445 157.227a111.702 111.702 0 0 0 22.41 2.241c1.311 0 2.658-.024 3.89.423 2.32.84 3.788 3.685 3.806 6.144.03 3.474-2.996 6.906-7.448 7.733"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M64.445 157.227a111.702 111.702 0 0 0 22.41 2.241c1.311 0 2.658-.024 3.89.423 2.32.84 3.788 3.685 3.806 6.144.03 3.474-2.996 6.906-7.448 7.733"
    />
    <path
      fill="#fff"
      d="M65.81 141.126a109.062 109.062 0 0 1 17.222-1.456c4.06-.018 8.716.502 11.036 3.831 1.77 2.537 1.208 8.192.93 9.89-.779 4.766-3.697 5.371-4.874 6.47"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M65.81 141.126a109.062 109.062 0 0 1 17.222-1.456c4.06-.018 8.716.502 11.036 3.831 1.77 2.537 1.208 8.192.93 9.89-.779 4.766-3.697 5.371-4.874 6.47"
    />
    <path
      fill="#fff"
      d="M84.688 139.931c5.164-2.308 8.504-7.214 8.438-12.367 0 0-.042-3.511-5.424-7.969-1.01-.84-2.139-1.571-3.401-1.915-1.383-.375-2.845-.266-4.27-.139a185.853 185.853 0 0 0-31.103 5.461c-1.444.381-3.057.925-3.667 2.29l-2.205-.121c-3.714.689-6.082 4.67-6.21 8.446-.132 3.776 1.438 7.383 2.997 10.821-4.18 4.386-3.703 12.318.972 16.173-2.428 1.861-3.618 5.195-2.923 8.174.694 2.978 3.25 5.437 6.246 6.023-4.09 3.057-4.519 9.902-.846 13.449 2.126 2.048 5.11 2.906 8.004 3.48 11.338 2.259 23.292 1.329 34.146-2.659 3.214-1.178 6.802-5.685 7.002-9.098.15-2.616-1.928-4.942-4.35-5.933-2.706-1.112-5.02-1.105-7.744-.888-3.418.272-8.348.526-14.654.338"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M84.688 139.931c5.164-2.308 8.504-7.214 8.438-12.367 0 0-.042-3.511-5.424-7.969-1.01-.84-2.139-1.571-3.401-1.915-1.383-.375-2.845-.266-4.27-.139a185.853 185.853 0 0 0-31.103 5.461c-1.444.381-3.057.925-3.667 2.29l-2.205-.121c-3.714.689-6.082 4.67-6.21 8.446-.132 3.776 1.438 7.383 2.997 10.821-4.18 4.386-3.703 12.318.972 16.173-2.428 1.861-3.618 5.195-2.923 8.174.694 2.978 3.25 5.437 6.246 6.023-4.09 3.057-4.519 9.902-.846 13.449 2.126 2.048 5.11 2.906 8.004 3.48 11.338 2.259 23.292 1.329 34.146-2.659 3.214-1.178 6.802-5.685 7.002-9.098.15-2.616-1.928-4.942-4.35-5.933-2.706-1.112-5.02-1.105-7.744-.888-3.418.272-8.348.526-14.654.338"
    />
    <path
      fill="#00AEFF"
      d="M127.092 81.025c-30.444 2.102-32.147 3.806-34.25 34.256-2.102-30.45-3.805-32.154-34.25-34.256 30.445-2.102 32.148-3.806 34.25-34.256 2.103 30.45 3.806 32.154 34.25 34.256Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M127.092 81.025c-30.444 2.102-32.147 3.806-34.25 34.256-2.102-30.45-3.805-32.154-34.25-34.256 30.445-2.102 32.148-3.806 34.25-34.256 2.103 30.45 3.806 32.154 34.25 34.256Z"
    />
    <path
      fill="#FF8B00"
      d="M69.104 212.127c-30.444 2.102-32.148 3.806-34.25 34.255-2.102-30.449-3.805-32.153-34.25-34.255 30.445-2.103 32.148-3.807 34.25-34.256 2.102 30.449 3.806 32.153 34.25 34.256Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M69.104 212.127c-30.444 2.102-32.148 3.806-34.25 34.255-2.102-30.449-3.805-32.153-34.25-34.255 30.445-2.103 32.148-3.807 34.25-34.256 2.102 30.449 3.806 32.153 34.25 34.256Z"
    />
  </svg>
);
