import { SVGProps } from "react";

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={29}
    fill="none"
    viewBox="0 0 29 29"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.517}
      d="m14.823 3.207 3.622 7.338 8.1 1.184-5.86 5.71 1.383 8.064-7.245-3.81-7.244 3.81 1.383-8.065L3.1 11.73l8.1-1.184 3.622-7.338Z"
    />
  </svg>
);
