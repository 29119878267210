import { SVGProps } from "react";

export const ScormMyAcademyIllustration1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="_\u0421\u043B\u043E\u0439_1"
    data-name="\u0421\u043B\u043E\u0439 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    {...props}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: none;\n        stroke-linecap: round;\n        stroke-linejoin: round;\n        stroke-width: 1.81px;\n      }\n\n      .cls-1, .cls-2 {\n        stroke: #1d1d1b;\n      }\n\n      .cls-3 {\n        fill: #fff;\n      }\n\n      .cls-3, .cls-4 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #23bbee;\n        stroke-miterlimit: 10;\n        stroke-width: 2px;\n      }\n\n      .cls-4 {\n        fill: #1d1d1b;\n      }\n    "
        }
      </style>
    </defs>
    <g>
      <path
        className="cls-3"
        d="m187.63,249.27c-5.34,22.19,7.99,42.68,22.32,57.41-6.82,12.32-51.34-12.34-44.52-24.66,0,0-.68-17.77,1.3-33.47"
      />
      <path className="cls-1" d="m187.63,249.27c-5.34,22.19,7.99,42.68,22.32,57.41" />
      <path
        className="cls-1"
        d="m165.43,282.02c-1.15-4.83-2.38-12.83-.67-22.4,1.12-6.31,3.18-11.38,5-15"
      />
    </g>
    <g>
      <polygon
        className="cls-2"
        points="437.76 265.11 227.07 265.11 227.07 230.6 77.24 317.11 212.76 411.62 227.07 403.61 227.07 369.11 423.76 376.62 437.76 369.11 437.76 265.11"
      />
      <polygon
        className="cls-2"
        points="423.76 273.11 213.07 273.11 213.07 238.6 63.24 325.11 213.07 411.61 213.07 377.11 423.76 377.11 423.76 273.11"
      />
      <line className="cls-2" x1={437.76} y1={265.11} x2={423.76} y2={273.11} />
    </g>
    <path
      className="cls-3"
      d="m405.7,252.8l2.21-7.8,7.43-76.75s-43.03-48.49-49.59-56.74c-13.04-16.39-51.86-37.48-104.06-12.33-35.51,17.1-30.47,159.86-25.21,166.88,5.26,7.02,113.28,12.57,113.28,12.57l49.94-19.65"
    />
    <path
      className="cls-1"
      d="m405.7,252.8l2.21-7.8,7.43-76.75s-43.03-48.49-49.59-56.74c-13.04-16.39-51.86-37.48-104.06-12.33-35.51,17.1-30.47,159.86-25.21,166.88,5.26,7.02,113.28,12.57,113.28,12.57l41.64-15.73"
    />
    <line className="cls-1" x1={361.97} y1={108.88} x2={363.94} y2={249.83} />
    <path className="cls-1" d="m299.83,124.47l-6.58,113.19s46.36,33.88,53.92-17.85" />
    <ellipse className="cls-4" cx={333.76} cy={133.74} rx={5.89} ry={18.26} />
    <ellipse className="cls-4" cx={265.7} cy={133.74} rx={5.89} ry={18.26} />
    <path className="cls-1" d="m408,172.04s-9.86-2.8-10.85-6.91c-.99-4.11-5.75-5.98-5.75-5.98" />
    <path
      className="cls-4"
      d="m393.87,159.86s.49-3.45,3.95-1.48c3.45,1.97,2.79,10.48,2.79,10.48l-2.99-2.45-3.75-6.55Z"
    />
    <path className="cls-1" d="m259.81,225.49c1.55,32.89,33.44,12.17,33.44,12.17" />
    <path
      className="cls-3"
      d="m407.92,302.9s-38.06-44.95-53.51-45.16c-4.66-.06-16.25,11.35-6.14,14.46,17.1,5.26,32.44,62.72,42.52,72.36,10.08,9.65,27.08-8.92,29.45-17.1,3.03-10.45-2.71-43.42-2.71-43.42l15.04,10.09-19.86-47.38-7.01,6.06-11.94,5.82-19,21"
    />
    <path
      className="cls-1"
      d="m407.92,302.9s-38.06-44.95-53.51-45.16c-4.66-.06-16.25,11.35-6.14,14.46,17.1,5.26,32.44,62.72,42.52,72.36,10.08,9.65,27.08-8.92,29.45-17.1,3.03-10.45-2.71-43.42-2.71-43.42l15.04,10.09-19.86-47.38-7.01,6.06"
    />
    <path
      className="cls-3"
      d="m169.57,244.72c1.76-3.66,6.07-5.58,10.13-5.47,4.06.11,7.88,1.9,11.34,4.04,2.5,1.55,4.95,3.36,6.47,5.88,2.62,4.34,1.83,10.33-1.54,14.11-3.38,3.78-8.99,5.24-13.89,3.98-5.14-1.33-9.18-5.21-12.89-9.01"
    />
    <path
      className="cls-1"
      d="m169.57,244.72c1.76-3.66,6.07-5.58,10.13-5.47,4.06.11,7.88,1.9,11.34,4.04,2.5,1.55,4.95,3.36,6.47,5.88,2.62,4.34,1.83,10.33-1.54,14.11-3.38,3.78-8.99,5.24-13.89,3.98-5.14-1.33-9.18-5.21-12.89-9.01"
    />
    <path
      className="cls-1"
      d="m163.76,266.62c.33-3.21.94-6.93,2-11,1.04-3.96,2.3-7.42,3.54-10.33"
    />
    <line className="cls-1" x1={380.02} y1={273.81} x2={392.97} y2={259.5} />
    <path
      className="cls-1"
      d="m412.46,164.24c2.33.56,4.78-.45,6.65-1.94s3.32-3.43,4.98-5.15,3.67-3.28,6.03-3.65c1.15-.18,2.49,0,3.21.93.39.5.53,1.14.62,1.77.43,3.18-.51,6.44-2.19,9.17-1.62,2.64-3.99,4.89-6.88,6.02s-6.32,1.01-8.95-.62"
    />
  </svg>
);
