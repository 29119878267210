import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { userCourse } from "./interface";
import { getMyCourses, getMyCoursesCount } from "../../../api";

const userCoursesAdapter = createEntityAdapter<userCourse>({
  selectId: (item) => item.id,
});

const initialState = {
  userCourses: userCoursesAdapter.getInitialState({ isLoaded: false }),
  count: null as [] | null,
};

const getMyCorsesList = createAsyncThunk(
  "corses/getMyCorsesList",
  async () => (await getMyCourses()) as any
);

const getMyCoursesCountThunkAction = createAsyncThunk(
  "courses/getMyCoursesCount",
  async () => (await getMyCoursesCount()) as any
);

const myCoursesSlice = createSlice({
  name: "myCoursesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyCorsesList.pending, (state, action) => {
        state.userCourses.isLoaded = false;
      })
      .addCase(getMyCorsesList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload.data)) {
          userCoursesAdapter.setAll(state.userCourses, action.payload.data);
          state.userCourses.isLoaded = true;
        }
      })
      .addCase(getMyCorsesList.rejected, (state, action) => {
        state.userCourses.isLoaded = false;
        userCoursesAdapter.removeAll(state.userCourses);
      })
      .addCase(getMyCoursesCountThunkAction.fulfilled, (state, action) => {
        state.count = action.payload.data;
      })
      .addCase(getMyCoursesCountThunkAction.rejected, (state, action) => {
        state.count = [];
      });
  },
});

export const userCourseSelectors = userCoursesAdapter.getSelectors(
  (state: RootState) => state.myCourses.userCourses
);

export { getMyCorsesList, getMyCoursesCountThunkAction };
export default myCoursesSlice.reducer;
