import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveMeetingViewed } from "../../../../../../api";

export const postMeetingViewed = createAsyncThunk(
  "course-meeting/postMeetingViewed",
  async ({ meetingId, isConference }: { meetingId: number; isConference?: boolean }) => {
    await saveMeetingViewed(meetingId, !!isConference);
    return meetingId;
  }
);
