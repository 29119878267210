import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KnownError } from "../profile-slice/interface";
import { RewardShopSlice } from "./interface";
import {
  ClubData,
  IOrderRequest,
  IProductsRequest,
  IProductsResponse,
  IShopProductFull,
  ProfileCoinsData,
  ProfileCoinsDetailedData,
  ProfileTransactionsResponse,
  ShopSettingsResponse,
} from "./interface/rewards-shop-slice.interface";
import {
  createOrder,
  getClub,
  getProfileCoins,
  getShopAvailable,
  getShopProduct,
  getShopSettings,
  getTransactions,
  setProductViews,
} from "../../../../api";
import { SearchParamsOption } from "ky";
import { getProfileCoinsDetails } from "../../../../api";
import { getShopProducts } from "../../../../api/shop/get-shop-products.api";

const initialState: RewardShopSlice = {
  coins: {
    total_coins: 0,
    shop_coins: 0,
  },
  lmsSettings: {
    keywords: false,
    competences: false,
    firstLoginCheck: true,
  },
  transactions: {
    transactions: [],
    limit: 40,
    offset: 0,
    total_count: 0,
  },
  products: {
    data: [],
    limit: 20,
    offset: 0,
    totalCount: 0,
  },
  coinsDetails: {
    total_coins: 0,
    shop_coins: 0,
    club_points: [],
  },
  productsIsLoading: true,
  coinsIsLoading: true,
  search: {},
};

const getShopSettingsThunkAction = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "shop/getShopSettings",
  async (_, { rejectWithValue }) => {
    try {
      const request = await getShopSettings();
      const response = (await request.json()) as any;

      return {
        data: response.data,
        status: request.status,
      };
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getShopAvailableThunkAction = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "shop/getShopAvailable",
  async (_, { rejectWithValue }) => {
    try {
      return await getShopAvailable();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getProfileTransactions = createAsyncThunk<
  any,
  { offset?: number; limit?: number } | undefined,
  {
    rejectValue: KnownError;
  }
>("profile/getProfileTransactions", async (params, { rejectWithValue, dispatch }) => {
  try {
    const request = await getTransactions({ params: params as SearchParamsOption });
    const response = await request.json();

    return {
      //@ts-ignore
      ...response,
      status: request.status,
      offset: params?.offset || 0,
      limit: params?.limit || 40,
    };
  } catch (error: any) {
    console.log(error);
    return rejectWithValue({
      status: error.response.status,
      code: error.response.status,
      details: error.response.statusText,
    });
  }
});

const getProfileCoinsThunkAction = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileCoins",
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileCoins();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getProfileCoinsDetailsThunkAction = createAsyncThunk<any, void, { rejectValue: KnownError }>(
  "profile/getProfileCoinsDetails",
  async (arg, { rejectWithValue }) => {
    try {
      return await getProfileCoinsDetails();
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getShopProductsThunkAction = createAsyncThunk(
  "shop/getShopProducts",
  async (arg: IProductsRequest & { add?: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const { add, ...request } = arg;
      request.limit = request.limit ?? 20;
      request.offset = request.offset ?? 0;

      const shopProducts = (await getShopProducts(request)) as any;

      return { response: shopProducts, limit: request.limit, offset: request.offset, add };
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const getShopProductThunkAction = createAsyncThunk(
  "shop/getShopProduct",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await getShopProduct(id)) as any;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const putProductViews = createAsyncThunk(
  "shop/putProductViews",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return await setProductViews(id);
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.status,
        details: error.response.statusText,
      });
    }
  }
);

const postOrder = createAsyncThunk(
  "shop/postOrder",
  async (request: IOrderRequest, { rejectWithValue, dispatch }) => {
    try {
      return await createOrder(request);
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const getClubThunkAction = createAsyncThunk(
  "shop/getClub",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      //@ts-ignore
      return (await getClub(id))?.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error?.response?.status,
        code: error?.response?.data?.code,
        details: error?.response?.data?.details,
      });
    }
  }
);

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<string | undefined>) {
      state.search.input = action.payload;
    },
    setSearchType(state, action: PayloadAction<string | undefined>) {
      state.search.type = action.payload;
    },
    setSearchMinPrice(state, action: PayloadAction<number | undefined>) {
      state.search.min_price = action.payload;
    },
    setSearchMaxPrice(state, action: PayloadAction<number | undefined>) {
      state.search.max_price = action.payload;
    },
    setSearchAccessibility(state, action: PayloadAction<boolean | undefined>) {
      state.search.accessibility = action.payload;
    },
    resetPagination(state) {
      state.products.offset = 0;
      state.products.data = [];
      state.productsIsLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getShopSettingsThunkAction.fulfilled,
        (state, action: PayloadAction<{ data: ShopSettingsResponse; status: number } | any>) => {
          const result = action.payload;
          const shopData = result.data?.shop;

          state.shopSettings = shopData || { enable: false };

          state.lmsSettings = result.data?.lms || {
            keywords: false,
            firstLoginCheck: false,
          };
        }
      )
      .addCase(getShopSettingsThunkAction.rejected, (state) => {
        state.shopSettings = { enable: false };
      })
      .addCase(getShopAvailableThunkAction.rejected, (state) => {
        state.shopSettings = { enable: false };
      })
      .addCase(getProfileCoinsThunkAction.pending, (state) => {
        state.coinsIsLoading = true;
      })
      .addCase(
        getProfileCoinsThunkAction.fulfilled,
        (state, action: PayloadAction<ProfileCoinsData | any>) => {
          state.coins = action.payload;
          state.coinsIsLoading = false;
        }
      )
      .addCase(getProfileCoinsThunkAction.rejected, (state) => {
        state.coinsIsLoading = false;
      })
      .addCase(
        getProfileCoinsDetailsThunkAction.fulfilled,
        (state, action: PayloadAction<ProfileCoinsDetailedData>) => {
          state.coinsDetails = action.payload;
        }
      )
      .addCase(getProfileTransactions.pending, (state) => {
        state.transactions.isLoading = true;
      })
      .addCase(getProfileTransactions.rejected, (state) => {
        state.transactions.isLoading = false;
      })
      .addCase(
        getProfileTransactions.fulfilled,
        (state, action: PayloadAction<ProfileTransactionsResponse>) => {
          const { transactions, offset } = action.payload;

          state.transactions = {
            ...action.payload,
            transactions:
              offset > 0 ? [...state.transactions.transactions, ...transactions] : transactions,
          };
        }
      )
      .addCase(getShopProductsThunkAction.pending, (state) => {
        state.productsIsLoading = true;
      })
      .addCase(getShopProductsThunkAction.rejected, (state) => {
        state.productsIsLoading = false;
      })
      .addCase(
        getShopProductsThunkAction.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: IProductsResponse;
            limit: number;
            offset: number;
            add?: boolean;
          }>
        ) => {
          if (action.payload.add && state.products.data) {
            state.products.data = [...state.products.data, ...action.payload.response.items];
          } else {
            state.products.data = action.payload.response.items;
          }
          state.products.limit = action.payload.limit;
          state.products.offset = action.payload.offset;
          state.products.totalCount = action.payload.response.totalCount;

          state.productsIsLoading = false;
        }
      )
      .addCase(
        getShopProductThunkAction.fulfilled,
        (state, action: PayloadAction<IShopProductFull | any>) => {
          state.currentProduct = action.payload;
        }
      )
      .addCase(getClubThunkAction.fulfilled, (state, action: PayloadAction<ClubData>) => {
        if (!state.clubs) {
          state.clubs = [action.payload];
          return;
        }

        const clubIndex = state.clubs.findIndex((x) => x.id === action.payload.id);
        if (clubIndex === -1) {
          state.clubs.push(action.payload);
        } else {
          state.clubs[clubIndex] = { ...action.payload };
        }
      });
  },
});
export const {
  setSearchInput,
  setSearchType,
  setSearchMinPrice,
  setSearchMaxPrice,
  setSearchAccessibility,
  resetPagination,
} = shopSlice.actions;
export {
  getClubThunkAction,
  getProfileCoinsThunkAction,
  getProfileCoinsDetailsThunkAction,
  getProfileTransactions,
  getShopAvailableThunkAction,
  getShopProductThunkAction,
  getShopProductsThunkAction,
  getShopSettingsThunkAction,
  postOrder,
  putProductViews,
};
export default shopSlice.reducer;
