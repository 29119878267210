import { HTMLProps } from "react";
import classNames from "classnames";
import styles from "./Link.module.scss";

export const Link = ({ children, className = "", ...otherProps }: HTMLProps<HTMLAnchorElement>) => (
  <a
    className={classNames(styles["link"], className, {
      [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
    })}
    {...otherProps}
  >
    {children}
  </a>
);
