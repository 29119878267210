import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  TransformedCompetence,
  findRootNode,
  getAllParentIds,
  transformCompetence,
} from "./functions";
import styles from "./CompetencyTree.module.scss";
import classNames from "classnames";
import { CourseCompetence } from "pages/Courses/redux/interface";

export const CompetencyTree = ({
  data = [],
  parentId = 0,
  competenceId = 0,
}: {
  data?: CourseCompetence[];
  competenceId?: number;
  parentId?: number;
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [svgSize, setSvgSize] = useState<{ width: number; height: number }>();

  const createTree = (data: TransformedCompetence, allParentIds: number[]) => {
    // Создание иерархической структуры
    const hierarchy = d3.hierarchy(data);

    const maxDepth = (d3.max(hierarchy, (d) => d.depth) ?? 0) + 1;

    const svgWidth = maxDepth * 280;

    const svgHeight = maxDepth * 100;

    setSvgSize({ width: svgWidth, height: svgHeight });

    const treeLayout = d3
      .tree<TransformedCompetence>()
      .size([svgWidth, svgHeight])
      .separation((a, b) => (a.parent === b.parent ? 12 : 14));

    const treeNodes = treeLayout(hierarchy);

    // Создание SVG контейнера
    const svg = d3.select(svgRef.current);

    // Создание связей между узлами
    svg
      .selectAll(".link")
      .data(treeNodes.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("fill", "none")
      .attr("stroke-width", "3")
      .attr(
        "d",
        //@ts-ignore
        d3
          .linkVertical()
          //@ts-ignore
          .x((d) => d.x)
          //@ts-ignore
          .y((d) => d.y)
          //@ts-ignore
          .source((d) => ({
            //@ts-ignore
            x: d.source.x,
            //@ts-ignore
            y: d.source.y + 20,
          }))
          //@ts-ignore
          .target((d) => ({
            //@ts-ignore
            x: d.target.x,
            //@ts-ignore
            y: d.target.y - 20,
          }))
      )

      .attr("stroke", (d) =>
        allParentIds.includes(d.target.data.attributes.id) ? "rgb(var(--primary-color))" : "#CFD3DE"
      );
    // Создание узлов дерева

    const nodes = svg
      .selectAll(".node")
      .data(treeNodes.descendants())
      .enter()
      .append("g")
      .attr("class", "node");
    nodes
      .append("circle")
      .attr("r", 20)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => {
        if (d.parent === null) {
          return d.y - 5;
        }

        if (!d.children) {
          return d.y - 5;
        }

        return d.y;
      })
      .attr("r", (d) => {
        if (d.parent === null) {
          return "25";
        }

        if (!d.children) {
          return "15.5";
        }
        return "20";
      })
      .attr("opacity", (d) =>
        (allParentIds.includes(d.data?.attributes?.id) &&
          d.data?.attributes?.id !== allParentIds.at(-1)) ||
        d.depth === 0
          ? "0.2"
          : null
      )
      .attr("fill", (d) =>
        allParentIds.includes(d.data?.attributes?.id) || d.depth === 0
          ? "rgb(var(--primary-color))"
          : "#E8EAEF"
      )
      .attr("stroke", (d) =>
        allParentIds.includes(d.data?.attributes?.id) &&
        d.data?.attributes?.id === allParentIds.at(-1)
          ? "#191229"
          : null
      )

      .attr("stroke-width", (d) =>
        allParentIds.includes(d.data?.attributes?.id) &&
        d.data?.attributes?.id === allParentIds.at(-1)
          ? "3"
          : null
      );

    nodes
      .append("svg")
      .attr("fill", "none")
      .attr("x", (d) => d.x - 12)
      .attr("y", (d) => {
        if (d.parent === null) {
          return d.y - 24;
        }

        if (!d.children) {
          return d.y - 18;
        }

        return d.y - 12;
      })
      .attr("opacity", (d) =>
        allParentIds.includes(d.data?.attributes?.id) || d.depth === 0 ? 0 : null
      )
      .append("path")
      .attr(
        "d",
        "M19 11H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2ZM7 11V7a5 5 0 1 1 10 0v4"
      )
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 2)
      .attr("stroke", "#CFD3DE");

    nodes
      .append("foreignObject")
      .attr("x", (d) => d.x + 30)
      .attr("y", (d) => {
        if (d.depth === 0 || !d.children) {
          return d.y - 30;
        }
        return d.y - 28;
      })
      .attr("width", 1)
      .attr("height", 1)
      .style("overflow", "visible")
      .append("xhtml:div")
      .attr("class", styles["text-container"])
      .append("xhtml:p")
      .attr("class", (d) => classNames(styles["text"], { [styles["small-text"]]: d.depth !== 0 }))
      .attr("title", (d) => d.data.name)
      .html((d) => d.data.name);
  };

  useEffect(() => {
    if (data?.length > 0) {
      const rootNode = findRootNode(data, parentId);
      const allParentIds = getAllParentIds(data, competenceId);
      if (rootNode?.id) {
        const tree: TransformedCompetence = {
          name: rootNode.name,
          attributes: { parentId: rootNode.parent_id, id: rootNode.id },
          children: transformCompetence(data, rootNode.id),
        };
        createTree(tree, [...allParentIds, competenceId]);
      }
    }
  }, [data, competenceId, parentId]);

  return (
    <svg
      id="tree-container"
      ref={svgRef}
      viewBox={`0 -30 ${svgSize?.width ?? 0} ${(svgSize?.height ?? 0) + 150}`}
    />
  );
};
