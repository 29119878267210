import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Trainer } from "./interfaces";
import {
  getScormDataById,
  getTrainingById,
  getTrainingResultsById,
  saveScormData,
  setTrainingEnd,
  setTrainingStart,
} from "../../../api";

const initialState: {
  visibleTrainer: boolean;
  trainer: Trainer | null;
  trainerCmiValue: { element: string; value: string }[];
  isLoadingTrackedData: boolean;
  results:
    | {
        progress: number;
        date: string;
      }[]
    | null;
} = {
  visibleTrainer: false,
  trainer: null,
  results: null,
  trainerCmiValue: [],
  isLoadingTrackedData: false,
};

const getCourseTraining = createAsyncThunk<Trainer, number>(
  "trainers/getCourseTraining",
  async (id: number) => (await getTrainingById(id)) as Trainer
);

const setCourseTrainingStart = createAsyncThunk<void, number>(
  "trainers/setCourseTrainingStart",
  async (id: number) => await setTrainingStart(id)
);

const setCourseTrainingEnd = createAsyncThunk<void, number>(
  "trainers/setCourseTrainingEnd",
  async (id) => await setTrainingEnd(id)
);

const setScormTrackedData = createAsyncThunk<
  void,
  { trainerId: number; data: { element: string; value: string }[] }
>(
  "trainers/setScormTrackedData",
  async ({ trainerId, data }) => await saveScormData({ id: trainerId, scormData: data })
);

const getScormTrackedData = createAsyncThunk<{ element: string; value: string }[], number>(
  "trainers/getScormTrackedData",
  async (id) => await getScormDataById(id)
);

// TODO: Remove Any
const getCourseTrainingResults = createAsyncThunk<any, number>(
  "trainers/getCourseTrainingResults",
  async (id, thunkAPI) => await getTrainingResultsById(id)
);

const courseTrainersSlice = createSlice({
  name: "trainers",
  initialState,
  reducers: {
    setTrainerCmiValue(state, action) {
      const { payload } = action;
      const currentValue = state.trainerCmiValue;
      state.trainerCmiValue = [
        ...currentValue.filter(({ element }) => element !== payload.element),
        payload,
      ];
    },
    clearTrainerCmiValue(state) {
      state.trainerCmiValue = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCourseTraining.fulfilled, (state, action: any) => {
        const trainer = action.payload.data;
        return { ...state, trainer, visibleTrainer: false };
      })
      .addCase(getScormTrackedData.pending, (state) => {
        state.isLoadingTrackedData = true;
        state.trainerCmiValue = [];
      })
      .addCase(getScormTrackedData.fulfilled, (state, action) => {
        state.isLoadingTrackedData = false;
        state.trainerCmiValue = [
          ...action.payload.map(({ element, value }) => {
            if (typeof value === "number") {
              return { element, value: `${value}` };
            }
            return { element, value };
          }),
        ];
      })
      .addCase(getScormTrackedData.rejected, (state) => {
        state.isLoadingTrackedData = false;
        state.trainerCmiValue = [];
      })
      .addCase(getCourseTraining.pending, () => {})
      .addCase(getCourseTraining.rejected, () => {})
      .addCase(setCourseTrainingStart.fulfilled, (state) => {
        return { ...state, visibleTrainer: true };
      })
      .addCase(setCourseTrainingStart.rejected, (state) => {
        if (state.trainer?.attempt_status === "started") {
          state.visibleTrainer = true;
        }
        return state;
      })
      .addCase(setCourseTrainingEnd.fulfilled, (state) => {
        return { ...state, visibleTrainer: false };
      })
      .addCase(getCourseTrainingResults.fulfilled, (state, action) => {
        const { data: results } = action.payload;

        return {
          ...state,
          results: results?.attempts?.every((item: any) => item.score === 0)
            ? results
            : results?.attempts?.sort(
                (a: { score: string }, b: { score: string }) => +b.score - +a.score
              ),
        };
      }),
});

export {
  getCourseTraining,
  setCourseTrainingStart,
  setCourseTrainingEnd,
  getCourseTrainingResults,
  setScormTrackedData,
  getScormTrackedData,
};

export const { setTrainerCmiValue, clearTrainerCmiValue } = courseTrainersSlice.actions;
export default courseTrainersSlice.reducer;
