import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const CourseResultBackgroundSvgImage = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "rostechnadzor") {
    return (
      <svg
        width="220"
        height="221"
        viewBox="0 0 220 221"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          opacity="0.1"
          d="M93.9036 -68.753C116.842 -5.03532 111.725 30.2678 124.052 84.1098C127.091 97.3108 125.013 102.299 112.565 92.2393C97.8112 80.1862 89.9408 62.4194 69.6499 35.2453C60.1612 22.5126 41.1083 6.13255 34.3883 20.3988C26.7857 36.9891 53.5269 63.695 45.0002 103.389C32.2698 162.797 32.4977 130.902 66.3592 160.64C74.9762 168.182 88.7005 182.173 92.4471 192.012C100.234 212.461 87.2469 229.998 66.5988 237.86C37.0005 248.911 -23.1153 232.042 -14.6375 245.602C-9.03465 254.513 59.7585 295.909 120.666 267.856C157.147 251.092 153.211 227.41 177.039 217.452C203.495 206.273 234.703 266.179 255.565 208.976C262.598 189.731 271.002 203.097 273.69 223.501C276.148 241.561 275.091 242.847 304.311 238.347C327.495 234.82 335.015 255.15 361.985 288.838C361.286 289.324 334.001 308.771 331.961 311.536C317.202 322.679 323.671 321.099 311.921 310.552C279.998 281.844 274.44 277.112 261.945 272.151C251.508 267.953 233.198 259.905 240.624 251.555C241.681 250.269 254.391 240.127 248.225 239.603C236.547 238.528 237.74 245.142 204.914 229.148C163.488 209.138 178.401 248.3 129.954 274.259C77.3619 302.68 13.7101 279.427 -39.9806 252.16C-53.6697 245.224 -64.7855 235.761 -51.2862 228.853C-34.0743 220.09 9.19132 231.005 35.8929 230.336C68.273 229.492 87.5735 208.226 64.7641 184.882C43.8746 163.679 31.2951 166.04 20.0039 160.179C2.82375 151.037 28.8586 132.95 33.9009 102.093C41.5285 56.5567 1.65528 37.7231 0.266933 1.58377C29.2797 -20.288 22.8009 -15.8328 25.6494 -16.4758C44.4128 -19.4239 58.4769 -5.12015 68.1245 -4.15523C79.3433 -3.12594 71.4487 -35.4624 67.3073 -46.918C66.3798 -48.7736 61.2791 -44.1806 93.9036 -68.753Z"
          fill="#ECF2F7"
        />
      </svg>
    );
  }

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={220}
        height={211}
        viewBox="0 0 220 211"
        fill="none"
        {...props}
      >
        <path
          stroke="#0094D9"
          strokeWidth={3}
          d="M112 246c76.5-90.5 118.424-73.5 80.924-55.5-9.086 4.361-174.578 55.281-208.424-66-12-43 13.424-97.703 78.924-112.203 39-8.634 0 11.5-4.5 20.5-12.316 24.633 91-35.5 134-30.5s51 46.5 53 55"
          opacity={0.5}
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 541 642" {...props}>
      <path fill={`url(#${id})`} d="M0 0h541v642H0V0Z" />
      <path
        fill="#F0EEFF"
        d="M204.262-165.131c52.393 145.539 40.706 226.175 68.863 349.156 6.94 30.153 2.194 41.546-26.238 18.569-33.699-27.53-51.676-68.112-98.023-130.18C127.191 43.33 83.672 5.916 68.323 38.502c-17.366 37.895 43.714 98.894 24.238 189.56-29.077 135.694-28.557 62.842 48.787 130.766 19.682 17.228 51.03 49.186 59.587 71.658 17.787 46.709-11.877 86.764-59.04 104.723-67.606 25.24-204.917-13.289-185.553 17.682 12.798 20.354 169.929 114.908 309.048 50.832 83.328-38.291 74.337-92.385 128.762-115.128 60.428-25.534 131.712 111.297 179.362-19.361 16.066-43.958 35.262-13.427 41.401 33.176 5.615 41.252 3.201 44.19 69.942 33.911 52.953-8.056 70.131 38.38 131.732 115.326-1.595 1.112-63.916 45.53-68.577 51.846-33.712 25.45-18.936 21.842-45.773-2.247-72.916-65.574-85.611-76.381-114.151-87.712-23.839-9.59-65.663-27.973-48.701-47.046 2.415-2.937 31.445-26.101 17.363-27.298-26.674-2.456-23.949 12.652-98.928-23.882-94.622-45.704-60.559 43.747-171.218 103.04-120.125 64.917-265.513 11.804-388.148-50.477-31.268-15.844-56.658-37.457-25.824-53.235 39.314-20.016 138.138 4.915 199.127 3.386 73.96-1.928 118.045-50.501 65.945-103.82-47.714-48.43-76.447-43.039-102.237-56.427-39.241-20.879 20.225-62.192 31.742-132.673C84.632 121.091-6.443 78.073-9.614-4.473c66.268-49.958 51.47-39.782 57.976-41.25 42.858-6.734 74.982 25.937 97.018 28.141 25.625 2.351 7.593-71.509-1.867-97.675-2.118-4.238-13.769 6.253 60.749-49.874Z"
        opacity={0.1}
      />
      <defs>
        <linearGradient
          id={id}
          x1={27.948}
          x2={523.399}
          y1={140.437}
          y2={162.428}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#43375D" />
          <stop offset={1} stopColor="#592761" />
        </linearGradient>
      </defs>
    </svg>
  );
};
