import styles from "./circleIconButton.module.scss";
import classNames from "classnames";
import { useRipple } from "../../../hooks";
import { MouseEvent } from "react";

interface IconButtonProps {
  className?: string;
  children?: JSX.Element;
  hasCircle?: boolean;
  size?: "big" | "medium" | "small";
  color?: "base" | "accent" | "primary" | "bordered" | "transparent" | "ligth-gray";
  type?: "button" | "submit";
  hasRipple?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const CircleIconButton = ({
  className = "",
  children,
  size = "big",
  type = "button",
  hasRipple = true,
  color = "base",
  onClick = () => {},
}: IconButtonProps) => {
  const addRipple = useRipple();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (hasRipple) {
      addRipple(event);
    }
    onClick(event);
  };

  const iconButtonClassNames = classNames(
    styles["circle-button"],
    {
      [styles[color]]: !!color?.length,
      [styles[size]]: !!size?.length,
      [styles["rostechnadzor-theme"]]: process.env.REACT_APP_THEME === "rostechnadzor",
    },
    className
  );

  return (
    <button type={type} className={iconButtonClassNames} onClick={handleClick}>
      <span className={styles["icon"]}>{children}</span>
    </button>
  );
};
