import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    popup: {
      opened: false,
    },
  },
};

const courseCatalogSlice = createSlice({
  name: "courseCatalog",
  initialState,
  reducers: {
    changeCourseCatalogFilterPopupOpened(state, action) {
      state.filter.popup.opened = action.payload;
    },
  },
});

export const { changeCourseCatalogFilterPopupOpened } =
  courseCatalogSlice.actions;
export default courseCatalogSlice.reducer;
