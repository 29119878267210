import { avatarsApi } from "../avatars.api";

const changeAvatarApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    changeAvatar: build.mutation<void, { imageId: number }>({
      query: ({ imageId }) => ({
        url: "profile/avatar",
        method: "PUT",
        body: { image_id: imageId },
      }),
      invalidatesTags: ["updateAvatar"],
    }),
  }),
  overrideExisting: false,
});

export const { useChangeAvatarMutation } = changeAvatarApi;
