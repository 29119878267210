import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEdit: false,
};

const profileSlice = createSlice({
  name: "uiprofile",
  initialState,
  reducers: {
    changeProfileIsEdit(state, action) {
      state.isEdit = action.payload;
    }
  }
});



export const { changeProfileIsEdit } = profileSlice.actions;

export default profileSlice.reducer;
