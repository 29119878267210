import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

export function useMutationObservable(
  targetEl: HTMLElement | null,
  cb: () => void,
  useCb = false,
  options = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState<MutationObserver | null>(null);
  const [observerIsSet, setObserverIsSet] = useState<boolean>(false);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, options, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) return;

    const { config } = options;
    observer.observe(targetEl, config);
    setObserverIsSet(true);

    if (useCb) cb();

    return () => {
      if (observer) {
        observer.disconnect();
        setObserverIsSet(false);
      }
    };
  }, [observer, targetEl, options, useCb, cb, setObserverIsSet]);

  return observerIsSet;
}
